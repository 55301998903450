import { Box, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import plansInfo from "../Js/plans";

const DiscountCartBanner = (props) => {

    const { allCompaniesLength, currentPlan } = props;
    const [t] = useTranslation("global");

    const getDiscountByPlan = () => {
        return plansInfo
            .find((item) => item.planId === currentPlan.value)
            .discounts.find((item) => allCompaniesLength >= item.min && allCompaniesLength <= item.max);
    };

    return (
        <Box
            my={2}
            sx={{
                backgroundColor: "#FFF",
                px: 5,
                py: 2.5,
                borderRadius: "8px",
                width: "100%",
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="column" spacing={0.5}>
                    <Typography>
                        {t("subscription.discountMsg1")}
                    </Typography>
                    <Typography fontWeight={600}>
                        {t("subscription.discountMsg2")}
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Stack direction="column" spacing={0}>
                        <Typography textAlign="end" fontSize={18} color="primary">
                            {allCompaniesLength} {t("subscription.discountMsg3")}
                        </Typography>
                        <Typography textAlign="end" color="primary">
                            {getDiscountByPlan().min} - {getDiscountByPlan().max} {t(("management.companies"))}
                        </Typography>
                    </Stack>
                    <Typography fontSize={24} color="#ff5e77" fontWeight={600}>
                        (-{getDiscountByPlan().discount * 100}%)
                    </Typography>
                </Stack>
            </Stack>
        </Box>
    );
};

export default DiscountCartBanner;
