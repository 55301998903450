import {
    Box,
    Button,
    Divider,
    Grid,
    Link,
    Stack,
    styled,
    Switch,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import Visa from "../../../assets/visa.svg";
import Mastercard from "../../../assets/mastercard.svg";
import Amex from "../../../assets/amex.svg";
import Discover from "../../../assets/discover.svg";
import Diners from "../../../assets/diners.svg";
import JCB from "../../../assets/jcb.svg";
import UnionPay from "../../../assets/unionpay.svg";
import WarningIcon from "@mui/icons-material/Warning";
import AddIcon from "@mui/icons-material/Add";

import LightTooltip from "../../ui/LightTooltip";
import AddPaymentDialog from "../../payments/AddPaymentDialog";
import { useState, useEffect } from "react";
import payService from "../../../services/payment_methods";
import subscriptionService from "../../../services/subscription";

/* Estilos */
import BaseSnackbar from "../../ui/BaseSnackbar";
import SimpleBackdrop from "../../ui/SimpleBackdrop";
import { useSelector } from "react-redux";
import ChangePayDialog from "./ChangePayDialog";
import SimpleDialog from "../../ui/SimpleDialog";
import { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import moment from "moment";
import plansInfo from "../../Js/plans";
import { formatAmount, getPlanInfo, getPlanLevelInfo, updateCartPlan } from "../../../utils/utils";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckIcon from "../../../assets/CheckIcon.svg";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: 10,
        width: "50%",
    },
}));

const ShoppingSummaryReg = (props) => {
    const {
        setPaymentType,
        paymentType,
        currentLevel,
        priceMonthly,
        setPriceMonthly,
        priceMonthlyOG,
        currentPlan,
        payments,
        setReload,
        setActiveStep,
        userEmail,
        showStep3,
        origin,
        allCompanies,
        smbInfo,
        finalF,
        activePlan,
        setShowCart,
        subsType
    } = props;

    const [t, i18n] = useTranslation("global");

    const defaultCard = payments.find((item) => item.default_method);

    const todos = useSelector((state) => state.value);
    const [showAddPay, setShowAddPay] = useState(false);
    const [show, setShow] = useState(false);
    const [message, setMessage] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [isLoading, setIsLoading] = useState(false);
    const [showChangePayment, setShowChangePayment] = useState(false);
    const [payStatus, setPayStatus] = useState(false);
    const [termsLink, setTermsLink] = useState(
        sessionStorage.getItem("lng") === "es"
            ? "https://kiiper.app/es/terms-and-conditions"
            : "https://kiiper.app/terms-and-conditions/"
    );
    const [openModal, setOpenModal] = useState(false);
    const [isRetry, setIsRetry] = useState(false);
    const [monthDays, setMonthDays] = useState("month");

    useEffect(() => {
        if (i18n.language) {
            setTermsLink(
                i18n.language === "es"
                    ? "https://kiiper.app/es/terms-and-conditions"
                    : "https://kiiper.app/terms-and-conditions/"
            );
        }
    }, [i18n.language]);

    const handleCloseModalPay = () => {
        setShowChangePayment(false);
    };

    const confirmDowngrade = async () => {
        setIsLoading(true);

        let params = {
            subscription_id: smbInfo.subscription._id,
            type: 2, // Downgrade
            changed_plan: currentLevel,
            franchise_id: todos.franchiseID,
            company_id: smbInfo._id,
        };

        // console.log("params", params);

        await subscriptionService
            .changeSubscription(params)
            .then(async () => {
                setShow(true);
                setAlertType("success");
                setMessage(t("subscription.downgradePlanSucess"));

                const params = {
                    franchise_id: todos.franchiseID,
                    company_id: todos.companyID,
                    cart_lines: [],
                };

                await subscriptionService
                    .updateCart(params)
                    .then()
                    .catch((error) => {
                        console.log(error);
                    });

                setShowCart(false);
            })
            .catch((error) => {
                console.log(error);
                setMessage("Error");
                setAlertType("error");
            });

        setIsLoading(false);
    };

    const handlePayment = async () => {
        if (defaultCard) {

            const params = {
                total_amount: getTotal().toFixed(2).toString().replace(".", "") ?? 0,
                franchise_id: smbInfo.franchise_id._id,
                company_id: smbInfo._id,
                customer_id: defaultCard.customer_id,
                payment_id: defaultCard.payment_id,
                payment_method_id: defaultCard._id,
                origin: origin,
                planId: currentLevel,
            };

            // console.log("params", params);

            setIsLoading(true);

            await payService
                .chargeSavedCard(params)
                .then(() => {
                    setPayStatus(true);
                })
                .catch((err) => {
                    console.log(err.response.data.message);
                    setPayStatus(false);
                    if (openModal) {
                        setIsRetry(true);
                    }
                    // Se envía un correo de pago manual fallido
                    payService.sendEmailFailedPayment(params);
                });
            setOpenModal(true);
            setIsLoading(false);
        }
    };

    const handleAddPaymentM = () => {
        setShowAddPay(true);
        setShowChangePayment(false);
    };

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            if (defaultCard) {
                setShowChangePayment(true);
            }
            setReload((prevState) => !prevState);
        }
    };

    /** Component functions */
    const handleCloseSnack = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const changePayment = () => {
        setShowChangePayment(true);
        setOpenModal(false);
        setIsRetry(false);
    };

    const handleChangeSwitch = async (event) => {
        if (smbInfo?.last_invoice?.line_items[0]?.line_qty === 365) {
            // Si la compra anterior fue anual, solo puede hacer upgrade de manera anual también
        } else {
            let paymentType = "monthly";
            let newPrice = priceMonthlyOG;
            if (event.target.checked) {
                paymentType = "annual";
                //newPrice = priceMonthlyOG - priceMonthlyOG * 0.15;
            }
            setPaymentType(paymentType);
            setPriceMonthly(newPrice);
            setMonthDays("month");

            await updateCartPlan(
                origin === "register" || origin === "addCompany" ? 1 : activePlan > currentLevel ? 3 : 4,
                currentLevel,
                paymentType,
                smbInfo,
                setIsLoading,
                allCompanies.length,
                "month"
            );
        }
    };

    const handleCloseModalCredits = () => {
        setIsRetry(false);
        setOpenModal(false);

        if (payStatus) {
            finalF();
            //setActiveStep((prevActiveStep) => prevActiveStep + 1);
            //showStep3();
        }
    };

    const startFreeTrial = () => {
        setOpenModal(false);
        setIsRetry(false);
        showStep3();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleAlignment = async (event, newValue) => {
        if (newValue !== null) {
            setMonthDays(newValue);

            await updateCartPlan(
                origin === "register" || origin === "addCompany" ? 1 : activePlan > currentLevel ? 3 : 4,
                currentLevel,
                paymentType,
                smbInfo,
                setIsLoading,
                allCompanies.length,
                newValue
            );
        }
    };

    const getDiscountByPlan = () => {
        return plansInfo
            .find((item) => item.planId === currentPlan.value)
            .discounts.find((item) => allCompanies.length >= item.min && allCompanies.length <= item.max).discount;
    };

    const remainingDays = moment().endOf("month").diff(moment(), "days") + 1;

    const getTotal = () => {
        let totalWithoutDisc;
        let totalDisc = 0;

        if (paymentType === "annual") {
            totalWithoutDisc = priceMonthlyOG * 12;
        } else {
            totalWithoutDisc = monthDays === "month" ? priceMonthlyOG : (priceMonthlyOG / 30) * remainingDays;
        }

        if (paymentType === "annual") {
            // Descuento del 15% por comprar plan anual
            totalDisc += totalWithoutDisc * 0.15;
        }

        if (allCompanies.length >= 11 && subsType === 1) {
            // Descuento variable por tener igual o más de 11 emrpesas
            totalDisc += totalWithoutDisc * getDiscountByPlan();
        }

        if (!isRegister && getLastInvoicePlan !== null) {
            // Descuento de monto fijo por ya tener un plan activo
            totalDisc += smbInfo?.last_invoice?.line_items[0]?.line_amount ?? 0;
        }

        return totalWithoutDisc - totalDisc;
    };

    const confirmCancel = async () => {
        setIsLoading(true);

        const params = {
            subscription_id: smbInfo.subscription._id,
        };

        await subscriptionService
            .cancelSubscription(params)
            .then(async () => {
                const params = {
                    franchise_id: smbInfo.franchise_id,
                    company_id: smbInfo._id,
                    cart_lines: [],
                };

                await subscriptionService
                    .updateCart(params)
                    .then()
                    .catch((error) => {
                        console.log(error);
                    });
                    finalF();
            })
            .catch((error) => {
                console.log(error);
                setMessage("Error");
                setAlertType("error");
                setShow(true);
            });
        setIsLoading(false);
    };

    const isActivation = origin === "addCompany" || origin === "register";

    const isChangePlan = origin === "editCompany" || origin === "updateSusbcription";

    const isCancelPlan = currentPlan.type === "cancel";

    const isDowngrade = isChangePlan && activePlan > currentLevel

    const isRegister = origin === "register";
    
    const isCustomActivation = currentPlan?.custom === true;

    const getCurrentPlan = getPlanInfo(currentLevel);
    const getCurrentLevel = getPlanLevelInfo(currentLevel);

    const getActivePlan = getPlanInfo(activePlan) ?? null;
    const getActiveLevel = getPlanLevelInfo(activePlan) ?? null;

    const existsLastInvoicePlan = smbInfo?.last_invoice?.line_items[0]?.subscription_plan >= 0;

    const getLastInvoicePlan =
        isChangePlan && existsLastInvoicePlan
            ? getPlanInfo(smbInfo.last_invoice.line_items[0].subscription_plan)
            : null;
    const getLastInvoiceLevel =
        isChangePlan && existsLastInvoicePlan
            ? getPlanLevelInfo(smbInfo.last_invoice.line_items[0].subscription_plan)
            : null;

    const creditsContent = payStatus ? (
        <Stack direction="row" spacing={4} alignItems="center" px={2}>
            <Box
                component="img"
                // sx={{
                //     height: 80,
                //     width: 80,
                //     maxHeight: { xs: 80, md: 80 },
                //     maxWidth: { xs: 80, md: 80 },
                // }}
                src={CheckIcon}
            />
            <Box>
                <Typography fontSize={20} fontWeight={700} gutterBottom>
                    {payStatus ? t("dialog.successPay") : t("dialog.errorPay")}
                </Typography>
                <Typography fontSize={16} sx={{ lineHeight: "normal" }}>
                    {t("plans.successText")}
                </Typography>
            </Box>
        </Stack>
    ) : (
        <Box px={2}>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="center">
                <WarningIcon sx={{ color: "#ED1A1A" }} />
                <Typography fontSize={20} fontWeight={700}>
                    {t("dialog.errorPay")}
                </Typography>
            </Stack>
            <Typography fontSize={16} gutterBottom py={2}>
                {t("dialog.errorPayText")}
            </Typography>
            <Stack direction="row" spacing={2} alignItems="center" justifyContent="space-around">
                <Button onClick={isRetry ? startFreeTrial : handleCloseModalCredits}>
                    {isRetry ? t("register.startFreeTrial") : t("miscellaneous.goBack")}
                </Button>
                <Button variant="contained" disableElevation onClick={isRetry ? changePayment : handlePayment}>
                    {isRetry ? t("credits.editOrChangePay") : t("payment.retryPay")}
                </Button>
            </Stack>
        </Box>
    );

    const getFinalPages = () => {
        let pagesWithoutDisc = 0;
        let pagesDisc = 0;
        if (paymentType === "annual" || monthDays === "month") {
            pagesWithoutDisc = isCustomActivation ? smbInfo.request.final_pages : getCurrentLevel.pages;
        } else {
            pagesWithoutDisc = Math.trunc(getCurrentLevel.pages / 30) * remainingDays;
        }

        if (getLastInvoicePlan !== null) {
            const quantity = smbInfo.last_invoice.line_items[0].line_qty;

            let discPages = 0;
            if (quantity === 30) {
                // Mensual
                discPages = getLastInvoiceLevel.pages;
            } else if (quantity === 365) {
                // Anual
                discPages = getLastInvoiceLevel.pages;
            } else {
                // Por día
                discPages = (getLastInvoiceLevel.pages / 30) * quantity;
            }
            pagesDisc = discPages;
        }

        return pagesWithoutDisc - pagesDisc;
    };

    const ItemInfo = ({ title, description, amount, isDiscount, format = true }) => (
        <Grid item xs={12}>
            {description && (
                <Typography variant="h2" m={0}>
                    {title}
                </Typography>
            )}
            <Stack direction="row" spacing={1} alignItems="center" mb={0.5} justifyContent="space-between" width="100%">
                {description ? (
                    <Typography fontWeight={600} fontSize={12}>
                        {description}
                    </Typography>
                ) : (
                    <Typography variant="h2" m={0}>
                        {title}
                    </Typography>
                )}
                <Typography fontSize={14} color={isDiscount ? "#ff5e77" : "#131f3e"}>
                    {isDiscount && "-"} US$ {format ? formatAmount(amount, todos.amountFormat) : amount}
                </Typography>
            </Stack>
        </Grid>
    );

    const DataInfo = ({ title, description, text, format = false, isAmount = false }) => (
        <>
            {description && (
                <Typography variant="h2" mb={0.5}>
                    {title}
                </Typography>
            )}
            <Stack direction="row" spacing={1} alignItems="center" mb={1} justifyContent="space-between" width="100%">
                {description ? (
                    <Typography fontWeight={600} fontSize={12}>
                        {description}
                    </Typography>
                ) : (
                    <Typography variant="h2" m={0}>
                        {title}
                    </Typography>
                )}
                <Typography fontSize={14}>
                    {isAmount && "US$ "} {format ? formatAmount(text, todos.amountFormat) : text}
                </Typography>
            </Stack>
        </>
    );

    return (
        <>
            {!isCancelPlan && !isDowngrade ? (
                <Grid component="label" container alignItems="center" spacing={1} px={2} pb={0.5}>
                    <Grid item>
                        <Typography variant="h2">{t("plans.monthlyPlan")}</Typography>
                    </Grid>
                    <Grid item>
                        <Switch
                            id="isAnnual"
                            checked={paymentType === "annual"}
                            onChange={handleChangeSwitch}
                            inputProps={{ "aria-label": "controlled" }}
                        />
                    </Grid>
                    <Grid item>
                        <Typography variant="h2">{t("plans.annualPlan")}</Typography>
                    </Grid>
                </Grid>
            ) : ""}
            {origin === "addCompany" && getLastInvoicePlan !== null && paymentType === "monthly" && !isCancelPlan && (
                <Box sx={{ width: "100%", p: 0, m: 0 }}>
                    <StyledToggleButtonGroup
                        value={monthDays}
                        exclusive
                        onChange={handleAlignment}
                        aria-label="monthDays"
                        sx={{ width: "100%" }}
                    >
                        <ToggleButton
                            value="month"
                            aria-label="month"
                            sx={{
                                width: "50%",
                                backgroundColor: monthDays === "month" ? "#c1b4f0 !important" : "#e5e0f9",
                                "&:hover": {
                                    backgroundColor: monthDays === "month" ? "#af9eef !important" : "lightgray",
                                },
                            }}
                        >
                            {t("plans.fullMonth")}
                        </ToggleButton>
                        <ToggleButton
                            value="days"
                            aria-label="days"
                            sx={{
                                width: "50%",
                                backgroundColor: monthDays === "days" ? "#c1b4f0 !important" : "#e5e0f9",
                                "&:hover": {
                                    backgroundColor: monthDays === "days" ? "#af9eef !important" : "lightgray",
                                },
                            }}
                        >
                            {t("plans.remainingDays")}
                        </ToggleButton>
                    </StyledToggleButtonGroup>
                </Box>
            )}
            <Box sx={{ p: 0 }}>
                <>
                    <Grid container justifyContent="space-between" spacing={1} sx={{ p: "0 !important", mt: isCancelPlan? 0 : 2 }}>
                        <Grid item xs={12}>
                            <DataInfo
                                title={
                                    isActivation
                                        ? t("subscription.activeSubs")
                                        : isDowngrade ?
                                            t("subscription.planChange")
                                            : isCancelPlan
                                             ? t("subscription.cancelSubs"):
                                                isChangePlan 
                                                ? t("subscription.subsChange")
                                                : ""
                                }
                                description={
                                    <>
                                        {isChangePlan && !isCancelPlan && activePlan !== null && (
                                            <>
                                                Plan {getActivePlan.planTitle}
                                                {" - "}
                                                <Typography component="span" fontWeight={400} fontSize={12}>
                                                    {monthDays === "month"
                                                        ? getActiveLevel.pages
                                                        : Math.trunc(getActiveLevel.pages / 30)}{" "}
                                                    {monthDays === "month"
                                                        ? t("register.pagesMonthly")
                                                        : t("register.pagesPerDay")}
                                                </Typography>
                                                <ArrowForwardIcon
                                                    color="primary"
                                                    fontSize="small"
                                                    sx={{ mb: "-8px", mx: 0.5 }}
                                                />
                                            </>
                                        )}
                                        Plan {getCurrentPlan.planTitle}
                                        {" - "}
                                        <Typography component="span" fontWeight={400} fontSize={12}>
                                            {monthDays === "month" ? isCustomActivation ? smbInfo.request.final_pages : getCurrentLevel.pages : Math.trunc(getCurrentLevel.pages / 30)}{" "}
                                            {monthDays === "month"
                                                ? t("register.pagesMonthly")
                                                : t("register.pagesPerDay")}
                                        </Typography>
                                    </>
                                }
                                text={isDowngrade ? 0 : monthDays === "month" ? priceMonthlyOG : priceMonthlyOG / 30}
                                format={isDowngrade ? false : true}
                                isAmount={true}
                            />
                            {paymentType === "annual" && <DataInfo title={t("plans.numMonth")} text="x12" />}
                            {monthDays === "days" && <DataInfo title={t("plans.numDays")} text={`x${remainingDays}`} />}
                            {isDowngrade && (
                                <>
                                    <Typography sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                                        <InfoIcon fontSize="xs" color="primary" sx={{ mr: 0.5 }} />
                                        <Typography fontSize={12}>
                                            {t("subscription.sinceOf")}{" "}
                                            <Typography component="span" color="primary" fontWeight={600}>{moment.utc(currentPlan.endDate ?? new Date()).format(todos.dateFormat)}{" "}</Typography>
                                            {t("subscription.startBill")}{" US$ "}
                                            {paymentType === "monthly" ? monthDays === "month" ? priceMonthlyOG : (priceMonthlyOG / 30) : priceMonthlyOG * 12}
                                            {" "}
                                            {paymentType === "monthly" ? t("subscription.monthPlan1") : t("subscription.annualPlan")}{" "}<b>Plan {getCurrentPlan.planTitle}</b>
                                            {" - "}
                                            <Typography component="span" fontWeight={400} fontSize={12}>
                                                {monthDays === "month" ? isCustomActivation ? smbInfo.request.final_pages : getCurrentLevel.pages : Math.trunc(getCurrentLevel.pages / 30)}{" "}
                                                {monthDays === "month"
                                                    ? t("register.pagesMonthly")
                                                    : t("register.pagesPerDay")}
                                            </Typography>
                                        </Typography>
                                    </Typography>
                                </>
                            )}
                        </Grid>
                    </Grid>
                    {paymentType === "annual" && !isCancelPlan && !isDowngrade && (
                        <>
                            <Divider />
                            <Grid container spacing={1} sx={{ my: 0.5 }}>
                                <ItemInfo title={"Subtotal"} amount={priceMonthlyOG * 12} isDiscount={false} />
                            </Grid>
                            <Divider />
                            <Grid container spacing={1} sx={{ my: 0.5 }}>
                                <ItemInfo
                                    title={t("plans.discAnnual")}
                                    amount={priceMonthlyOG * 12 * 0.15}
                                    isDiscount={true}
                                />
                                {!isRegister && (allCompanies.length >= 11 && subsType === 1) && (
                                    <ItemInfo
                                        title={t("plans.discountWithAPlan")}
                                        description={`${allCompanies.length} ${t("plans.companiesCreated")} (${getDiscountByPlan() * 100
                                            } %)`}
                                        amount={priceMonthlyOG * 12 * getDiscountByPlan()}
                                        isDiscount={true}
                                    />
                                )}
                                {!isRegister && getLastInvoicePlan !== null && (
                                    <ItemInfo
                                        title={t("management.actualAmountPlan")}
                                        description={
                                            <>
                                                Plan {getLastInvoicePlan.planTitle}
                                                {" - "}
                                                <Typography component="span" fontWeight={400} fontSize={12}>
                                                    {getLastInvoiceLevel.pages} {t("register.pagesMonthly")}
                                                </Typography>
                                            </>
                                        }
                                        amount={smbInfo.last_invoice.line_items[0].line_amount}
                                        isDiscount={true}
                                    />
                                )}
                            </Grid>
                        </>
                    )}
                    {paymentType === "monthly" && !isCancelPlan && !isDowngrade && (
                        <>
                            <Divider />
                            <Grid container spacing={1} sx={{ my: 0.5 }}>
                                <ItemInfo
                                    title={"Subtotal"}
                                    amount={
                                        monthDays === "month" ? priceMonthlyOG : (priceMonthlyOG / 30) * remainingDays
                                    }
                                    isDiscount={false}
                                />
                            </Grid>
                            {!isRegister && ((allCompanies.length >= 11 && subsType === 1) || getLastInvoicePlan !== null) && (
                                <>
                                    <Divider />
                                    <Grid container spacing={1} sx={{ my: 0.5 }}>
                                        {allCompanies.length >= 11 && subsType === 1 && (
                                            <ItemInfo
                                                title={t("plans.discountWithAPlan")}
                                                description={`${allCompanies.length} ${t("plans.companiesCreated")} (${getDiscountByPlan() * 100
                                                    } %)`}
                                                amount={
                                                    monthDays === "month"
                                                        ? priceMonthlyOG * getDiscountByPlan()
                                                        : (priceMonthlyOG / 30) * remainingDays * getDiscountByPlan()
                                                }
                                                isDiscount={true}
                                            />
                                        )}
                                        {getLastInvoicePlan !== null && (
                                            <ItemInfo
                                                title={t("management.actualAmountPlan")}
                                                description={
                                                    <>
                                                        Plan {getLastInvoicePlan.planTitle}
                                                        {" - "}
                                                        <Typography component="span" fontWeight={400} fontSize={12}>
                                                            {getLastInvoiceLevel.pages} {t("register.pagesMonthly")}
                                                        </Typography>
                                                    </>
                                                }
                                                amount={smbInfo.last_invoice.line_items[0].line_amount}
                                                isDiscount={true}
                                            />
                                        )}
                                    </Grid>
                                </>
                            )}
                        </>
                    )}
                    <Divider />
                    <Grid container spacing={1} sx={{ my: 0.5 }}>
                        <ItemInfo title={"Total"} amount={isDowngrade || isCancelPlan ? 0 : getTotal()} isDiscount={false} />
                    </Grid>
                    {!isDowngrade && <>
                        <Grid container spacing={1} sx={{ mb: 0.5 }}>
                            <Grid item xs={12} sx={{ pt: "0 !important" }}>
                                {isCancelPlan ? (
                                    <Typography>
                                        {t("subscription.sinceOf")}{" "}
                                        <Typography component="span" color="primary" fontWeight={600}>{moment.utc(currentPlan.endDate ?? new Date()).format(todos.dateFormat)}{" "}</Typography>
                                        {t("plans.subsCanceled")}
                                    </Typography>
                                ) : (
                                    <Typography fontSize={12}>
                                        {t("subscription.purchaseRec")}{" "}
                                        <Typography color="primary" component="span" fontSize={12} fontWeight={600}>
                                            {getFinalPages()} {t("subscription.newCred")}
                                        </Typography>
                                    </Typography>
                                )}
                            </Grid>
                        </Grid>
                        <Divider sx={{ pt: 1 }} />
                    </>}
                    <Grid container justifyContent="center" spacing={2} sx={{ my: 0.5 }}>
                        {!isCancelPlan && !isDowngrade && (
                            <>
                                {" "}
                                <Grid item xs={12}>
                                    <Typography variant="h2">{t("payment.paymentMethod")}</Typography>
                                </Grid>
                                {priceMonthly > 0 && (
                                    <>
                                        {!defaultCard ? (
                                            <Grid item xs={12}>
                                                <Button
                                                    color="primary"
                                                    size="small"
                                                    onClick={handleAddPaymentM}
                                                    endIcon={<AddIcon />}
                                                >
                                                    {t("subscription.addPayMethod")}
                                                </Button>
                                            </Grid>
                                        ) : (
                                            <>
                                                <Grid item xs={12}>
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                        <Box
                                                            component="img"
                                                            src={
                                                                defaultCard.brand === "visa"
                                                                    ? Visa
                                                                    : defaultCard.brand === "mastercard"
                                                                        ? Mastercard
                                                                        : defaultCard.brand === "amex"
                                                                            ? Amex
                                                                            : defaultCard.brand === "discover"
                                                                                ? Discover
                                                                                : defaultCard.brand === "diners"
                                                                                    ? Diners
                                                                                    : defaultCard.brand === "unionpay"
                                                                                        ? UnionPay
                                                                                        : JCB
                                                            }
                                                            sx={{ width: 45 }}
                                                        />
                                                        <Typography variant="h3">
                                                            {defaultCard.brand.charAt(0).toUpperCase() +
                                                                defaultCard.brand.slice(1)}{" "}
                                                            {t("credits.endingIn")} *
                                                            {defaultCard.card_number.split("-").pop()}
                                                        </Typography>
                                                        <Button color="primary" size="small" onClick={changePayment}>
                                                            {t("subscription.editOrAdd")}
                                                        </Button>
                                                    </Stack>
                                                </Grid>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                        <Grid item xs={12} sm={4}>
                            <Button
                                variant="contained"
                                fullWidth
                                disabled={!defaultCard}
                                onClick={isCancelPlan ? confirmCancel : isDowngrade ? confirmDowngrade : handlePayment}
                            >
                                {isCancelPlan || isDowngrade ? t("subscription.confirm") : t("credits.pay")}
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={1} alignItems="center" justifyContent="center">
                                <LightTooltip
                                    title={
                                        <>
                                            <Typography fontSize={12}>
                                                <span
                                                    style={{
                                                        color: "#0FF",
                                                    }}
                                                >
                                                    {t("credits.extraCred")}
                                                </span>{" "}
                                                {t("credits.extraCredText")}
                                            </Typography>
                                            <br />
                                            <Typography fontSize={12}>
                                                {t("credits.toolWant")}{" "}
                                                <span
                                                    style={{
                                                        color: "#0FF",
                                                    }}
                                                >
                                                    {t("credits.increasePlan")}
                                                </span>{" "}
                                                {t("credits.increasePlanText")}
                                            </Typography>
                                            <br />
                                            <Typography fontSize={12}>
                                                {t("credits.toolWant")}{" "}
                                                <span
                                                    style={{
                                                        color: "#0FF",
                                                    }}
                                                >
                                                    {t("credits.reducePlan")}
                                                </span>{" "}
                                                {t("credits.reducePlanText")}
                                            </Typography>
                                        </>
                                    }
                                >
                                    <InfoIcon fontSize="small" color="primary" />
                                </LightTooltip>
                                <Typography fontSize={12}>
                                    {t("subscription.termsAccept")}{" "}
                                    <Typography
                                        color="primary"
                                        fontSize={12}
                                        component={Link}
                                        sx={{
                                            cursor: "pointer",
                                            textDecoration: "none",
                                        }}
                                        href={termsLink}
                                        target="_blank"
                                    >
                                        {t("subscription.termsAndCon")}
                                    </Typography>
                                </Typography>
                            </Stack>
                        </Grid>
                    </Grid>
                </>
            </Box>
            <SimpleDialog
                open={openModal}
                handleClose={handleCloseModalCredits}
                maxWidth="sm"
                content={creditsContent}
            />
            {showAddPay && (
                <AddPaymentDialog
                    open={showAddPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setMessage}
                    setAlertType={setAlertType}
                    setIsLoading={setIsLoading}
                    userEmail={userEmail}
                />
            )}
            <BaseSnackbar type={alertType} show={show} message={message} onClose={handleCloseSnack} />
            <SimpleBackdrop open={isLoading} />
            {showChangePayment && (
                <ChangePayDialog
                    open={showChangePayment}
                    handleClose={handleCloseModalPay}
                    payments={payments}
                    setIsLoading={setIsLoading}
                    defaultCard={defaultCard}
                    handleAddPaymentM={handleAddPaymentM}
                />
            )}
        </>
    );
};

export default ShoppingSummaryReg;
