import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import introJs from "intro.js";

import {
    Box,
    Button,
    CircularProgress,
    Grid,
    IconButton,
    Link,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import BaseSelect from "../../ui/BaseSelect";
import UploadFile from "../../ui/UploadFile";
import BaseLinearProgress from "../../ui/BaseLinearProgress";
import LightTooltip from "../../ui/LightTooltip";

import classes from "../MainConverter.module.css";

import CachedIcon from "@mui/icons-material/Cached";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TuneIcon from "@mui/icons-material/Tune";
import HelpIcon from "@mui/icons-material/Help";
import InfoIconMUI from "@mui/icons-material/Info";
import ErrorIconConverter from "../../../assets/error-icon-converter.svg";
import WarningIcon from "@mui/icons-material/Warning";
import SearchIcon from "@mui/icons-material/Search";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

import Step1 from "../../../assets/Step1.svg";
import Step2 from "../../../assets/Step2.svg";
import Step3 from "../../../assets/Step3.svg";

import accountsService from "../../../services/accounts";
import { useLocation, useNavigate } from "react-router-dom";
import conversionFormats from "../../Js/conversionFormats";
import conversionService from "../../../services/conversion";
import subscriptionService from "../../../services/subscription";
import paymentMethodsService from "../../../services/payment_methods";
import caseService from "../../../services/case";
import AddCreditPacksDialog from "../../credits/AddCreditPacksDialog";
import AddPaymentDialog from "../../payments/AddPaymentDialog";
import AlertDialog from "../../ui/AlertDialog";
import { useDispatch, useSelector } from "react-redux";
import { addCredits, addTutorial, dontShowAgainTutorial } from "../../../redux/userSlice";
import ChangePayDialog from "../../subscription/shoppingCart/ChangePayDialog";
import BackgroundDialog from "../BackgroundDialog";
import SimpleDialog from "../../ui/SimpleDialog";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { read, utils } from "xlsx";
import { fetchFileFromS3, getFileExtension, getCompanyCredits, zeroPad } from "../../../utils/utils";
import moment from "moment/moment";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "& td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const MainConverter = (props) => {
    const {
        setIsLoading,
        accounts,
        setTexto,
        setAlertType,
        setShow,
        setShowSkeleton,
        account,
        setAccount,
        filters,
        setFilters,
        setAccountType,
        setAccountNum,
        setBankInst,
        setCorrelative,
        setConversionId,
        setFileNameSaved,
        setConvertDoneNew,
        setShowPrev,
        setShowModalCredits,
        showModalCredits,
        finalQBOBanks,
        resetConversionMain,
        setResetConversionMain,
        handleGetConversions,
        setAccountDetConv,
        handleClickHelp,
        setFileData,
        setHTML,
        setConversionData,
        setFormats,
        formats,
        setEndPoint,
        setAccountDet,
        accountDet,
        setConvertDone,
        convertDone,
        setConvertRev,
        convertRev,
        setConvertInvalid,
        convertInvalid,
        setStatus,
        status,
        setConvFormat,
        setFileNameFull,
        firstAccount,
        setGroupedData,
        acceptedFiles,
        setAcceptedFiles,
        setS3FileName,
        setOriginalFileName,
        originalFileName,
        s3FileName,
        progress,
        setProgress,
        setEndConversion,
        setRows,
        rows,
        setOpenModalGroupedHist,
        setOpenModalGrouped,
        setConvInfo,
        convInfo,
        setGroupedPeriods,
        setModalType,
        setRowsModal,
        rowsModal,
        showRepeatModal,
        setShowRepeatModal,
        showRepeatVal,
        setShowRepeatVal,
        setBasePreviewData,
        setOpenPreview,
        setIntegration,
        companyDetails,
        activePlan,
        setActivePlan,
        allCompanies,
        paymentType
    } = props;

    const [t] = useTranslation("global");
    /* React redux */
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const location = useLocation();

    const columns = [
        { name: "documentName", title: t("converter.documentName") },
        { name: "convNum", title: t("converter.convertNumber") },
    ];

    const [showAddPay, setShowAddPay] = useState(false);
    const [dialogCancelConvert, setDialogCancelConvert] = useState(false);
    const [showChangePay, setShowChangePay] = useState(false); // Modal para cambiar tarjeta
    const [defaultPayMethod, setDefaultPayMethod] = useState({});
    const [paymentMethods, setPaymentMethods] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [isLoadingConv, setIsLoadingConv] = useState(false);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        let timeout;

        if (isLoadingConv) {
            let finalTime = 0;
            let ticker = 0;
            let finalLength = rows.length;

            finalTime = finalLength * 3;
            ticker = 100 / finalTime;

            if (timer < finalTime) {
                timeout = setTimeout(() => {
                    setProgress((prev) => prev + ticker);
                    setTimer((prev) => prev + 1);
                }, 1000);
            }
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [isLoadingConv, rows, setProgress, timer]);

    const handleCloseModalBack = async () => {
        setOpenModal(false);
        setIsLoading(true);
        await handleGetConversions(null, true);
        setIsLoading(false);
        resetConversion();
    };

    const navigate = useNavigate();

    const isDisabled = account === "" || acceptedFiles.length === 0;

    const handleCloseChangePay = () => {
        setShowChangePay(false);
        setShowModalCredits(true);
    };

    useEffect(() => {
        (async () => {
            if (location.state?.account_id || firstAccount) {
                setShowSkeleton(true);

                await accountsService
                    .getAccountDetails(location?.state?.account_id || firstAccount)
                    .then((response) => {
                        const formats = response.bank.file_format.map((item) => {
                            return { id: item, name: item };
                        });
                        setAccountNum(response.account_number_qb ?? "");
                        setAccountType(response.account_type_qb ?? "");
                        setBankInst(finalQBOBanks.find((item) => item.value === response.account_bid_qb) ?? null);
                        setEndPoint(response.bank.endpoint);
                        setFormats(formats);
                        setAccountDet(response);
                        setAccount(location?.state?.account_id || firstAccount);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
                setShowSkeleton(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, setAccountNum, setAccountType, setBankInst, setShowSkeleton, firstAccount]);

    useEffect(() => {
        let isDone = false;
        if (todos.steps.step6 && !todos.dontShowAgain) {
            setTimeout(() => {
                introJs()
                    .setOptions({
                        disableInteraction: true,
                        exitOnOverlayClick: false,
                        keyboardNavigation: false,
                        nextLabel: t("dialog.continue"),
                        prevLabel: t("miscellaneous.goBack"),
                        doneLabel: t("miscellaneous.accept"),
                        steps: [
                            {
                                title: `${t("miscellaneous.step")} 6`,
                                element: "#account4-tutorial",
                                intro: t("miscellaneous.tutorialConv1"),
                            },
                        ],
                    })
                    .oncomplete(() => {
                        isDone = true;
                        dispatch(addTutorial({ ...todos.steps, step6: false, step7: true }));
                    })
                    .onexit(() => {
                        if (!isDone) {
                            dispatch(addTutorial({ ...todos.steps, step6: false }));
                        }
                    })
                    .start();
            }, 1500);
        }
        if (status && (convertDone || convertRev || convertInvalid) && todos.steps.step7 && !todos.dontShowAgain) {
            let textTut = "";
            let element = "";

            if (status === 1) {
                element = "#account7-tutorial";
                textTut = t("miscellaneous.tutorialConv4");
            }
            if (status === 2) {
                element = "#account6-tutorial";
                textTut = t("miscellaneous.tutorialConv3");
            }
            if (status === 3) {
                element = "#account5-tutorial";
                textTut = t("miscellaneous.tutorialConv2");
            }

            introJs()
                .setOptions({
                    disableInteraction: true,
                    exitOnOverlayClick: false,
                    keyboardNavigation: false,
                    nextLabel: t("dialog.continue"),
                    prevLabel: t("miscellaneous.goBack"),
                    doneLabel: t("miscellaneous.accept"),
                    steps: [
                        {
                            title: `${t("miscellaneous.step")} 7`,
                            element: element,
                            intro: textTut,
                        },
                    ],
                })
                .oncomplete(() => {
                    isDone = true;
                    if (status === 3) {
                        dispatch(addTutorial({ ...todos.steps, step7: false, step8: true }));
                    } else {
                        dispatch(addTutorial({ ...todos.steps, step7: false }));
                    }
                })
                .onexit(() => {
                    if (!isDone) {
                        dispatch(addTutorial({ ...todos.steps, step7: false }));
                    }
                })
                .start();
        }
    }, [dispatch, t, todos.steps, status, convertDone, convertRev, convertInvalid, todos.dontShowAgain]);

    const handleChange = async (event) => {
        setIsLoading(true);

        setAccount(event.target.value);
        await accountsService
            .getAccountDetails(event.target.value)
            .then((response) => {
                const formats = response.bank.file_format.map((item) => {
                    return { id: item, name: item };
                });
                setIntegration(response.system_integration);
                setAccountNum(response.account_number_qb ?? "");
                setAccountType(response.account_type_qb ?? "");
                setBankInst(finalQBOBanks.find((item) => item.value === response.account_bid_qb) ?? null);
                setEndPoint(response.bank.endpoint);
                setFormats(formats);
                setAccountDet(response);
            })
            .catch((err) => {
                console.log(err);
            });

        if (filters.filtersArray.filter((item) => item.type !== "").length > 0) {
            let currentFilters = filters.filtersArray;
            currentFilters = currentFilters.filter((e) => e.type !== 1);

            currentFilters.push({
                type: 1,
                value: event.target.value,
                since: "",
                until: "",
            });

            setFilters({
                ...filters,
                filtersArray: currentFilters,
            });
        } else {
            setFilters({
                filtersArray: [
                    {
                        type: 1,
                        value: event.target.value,
                        since: "",
                        until: "",
                    },
                ],
            });
        }
        await handleGetConversions(event.target.value, false);
        resetConversion();
        setIsLoading(false);
    };

    const handleCloseRepeModal = () => setShowRepeatModal(false);

    const handleCloseModal = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowChangePay(true);
            getPaymentMethods(true);
        }
    };

    const handleCloseModal2 = (event, reason) => {
        if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
            setShowAddPay(false);
            setShowChangePay(false);
            getPaymentMethods(true);
            setShowModalCredits(true);
        }
    };

    const handleAddPaymentM = () => {
        setShowAddPay(true);
        setShowChangePay(false);
    };

    const resetConversion = () => {
        setProgress(0);
        setConvertDone(false);
        setFileData(null);
        setConvFormat(conversionFormats);
        setAcceptedFiles([]);
        setEndConversion(false);
        setConversionId(null);
        setConvertInvalid(false);
        setConvertRev(false);
        setStatus(0);
        setCorrelative(null);
        setConvertDoneNew(false);
        setConvInfo({});
        setRows([]);
        setRowsModal([]);
    };

    useEffect(() => {
        if (resetConversionMain) {
            resetConversion();
            setResetConversionMain(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetConversionMain]);

    const showConversions = () => {
        setModalType("bankStatement");
        setOpenModalGroupedHist(false);
        setOpenModalGrouped(true);
        setRowsModal([...rows]);
    };

    const handleClose = () => {
        setShowModalCredits(false);
    };

    const handleSetShowModalCredits = async () => {
        setShowModalCredits(true);
    };

    const handleCloseDialogCancel = () => {
        setDialogCancelConvert(false);
    };

    const handleCancelConvert = async () => {
        handleCloseDialogCancel();
        setProgress(0);
        setStatus(0);
        setEndConversion(false);
        setConvertInvalid(false);
        setConvertRev(false);
        setConvertDone(false);
        setConvertDoneNew(false);
        setConversionId(null);
    };

    const handleShowPrevDownload = async (e) => {
        e.preventDefault();
        setIsLoadingConv(true);
        setAccountDetConv(accountDet);

        const conversionID = rows[rows.length - 1].id;
        const groupID = convInfo.groupedConversionId ?? null;
        const params = {};

        if (groupID) {
            params.grouped_conversion_id = groupID;
        } else {
            params.conversion_id = conversionID;
        }

        await conversionService
            .getBankStatementsByConversion(params)
            .then(async () => {
                await handleGetConversions(null, true);
            })
            .catch((err) => {
                console.log(err);
            });

        // Conversiones múltiples
        if (groupID) {
            await conversionService
                .getGroupedConversionDetails(params)
                .then((response) => {
                    setGroupedData(response);
                    setFileNameSaved("GROUP-" + zeroPad(response.correlative ?? 0, 5));

                    const filteredConv = response.conversions.filter(
                        (item) => item.id_status === 2 || item.id_status === 3
                    );

                    setGroupedPeriods(filteredConv);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await conversionService
                .getTempConversionDetails({ conversion_id: conversionID })
                .then((response) => {
                    setConversionData(response);
                })
                .catch((err) => console.log(err));

            setFileNameSaved(rows[0].file_name.replace(/\.[^/.]+$/, ""));
            setConversionId(conversionID);
            setCorrelative(rows[0].number);
            switch (status) {
                case 2:
                    // Peticion para obtener el case number
                    await caseService
                        .getCaseInfo({ conversion_id: conversionID })
                        .then((response) => {
                            setTexto(
                                `${t("inbox.dialogCreatedCase")} ${response.caseInfo.case_number}. ${t(
                                    "inbox.dialogCreatedCase2"
                                )}`
                            );
                            setAlertType("success");
                            setShow(true);
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                    break;
                default:
                    break;
            }
        }

        if (status === 1) {
            let newFileData;

            const dataFile = await fetchFileFromS3(convInfo.statusByFile[0].filename);

            if (!dataFile) {
                setTexto(t("converter.dnldError"));
                setAlertType("error");
                setShow(true);
            } else {
                setFileData(dataFile);
                newFileData = dataFile;
            }

            setFileNameFull(convInfo.statusByFile[0].original_filename);
            if (
                getFileExtension(convInfo.statusByFile[0].filename) === "xlsx" ||
                getFileExtension(convInfo.statusByFile[0].filename) === "xls"
            ) {
                const f = await newFileData.arrayBuffer();

                /* parse workbook */
                const workbook = read(f, { type: "binary" });
                /* get first worksheet */
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                /* generate and display HTML */
                const table = utils.sheet_to_html(worksheet);
                setHTML(table);
            }

            setBasePreviewData({
                id: convInfo.statusByFile[0].conversionId,
                type: "bankStatement",
            });
        }

        const credits = await getCompanyCredits(todos.companyID);
        dispatch(addCredits(credits));

        setStatus(0);
        setProgress(0);
        setTimer(0);
        setIsLoadingConv(false);

        if (status === 1) {
            setAcceptedFiles([]);
            setS3FileName([]);
            setOriginalFileName([]);
            setRows([]);
            setRowsModal([]);
            setConvInfo({});
            setShowPrev(true);
        }
    };

    const getPaymentMethods = async (reload) => {
        setIsLoading(true);

        const params = {
            franchise_id: todos.franchiseID,
            companyID: todos.companyID,
        };

        try {
            if (todos.companyID || todos.franchiseID) {
                const result = await paymentMethodsService.getPaymentMethods(params);
                const defaultPaymentMethod = result.find((card) => card.default_method);
                setDefaultPayMethod(defaultPaymentMethod);
                setPaymentMethods(result);
            }
        } catch (error) {
            console.log(error);
        }

        if (!reload) {
            await subscriptionService
                .getSubscription({
                    company_id: todos.companyID,
                })
                .then((response) => {
                    if (response) {
                        setActivePlan(response.subscription_plan);
                    } else {
                        setActivePlan(null);
                    }
                })
                .catch((err) => console.log(err));
        }

        setIsLoading(false);
    };

    const bodyMess = (
        <>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                    mb: 3,
                    mt: 2,
                }}
                textAlign="justify"
            >
                {t("converter.textCancelConvert")}
            </Typography>
        </>
    );

    const handleNavigation = () => {
        navigate("/accounts");
    };

    const openTutorial = () => {

        dispatch(dontShowAgainTutorial(false))

        const defaultTut = {
            step1: false,
            step2: false,
            step4: false,
            step5: false,
            step6: false,
            step7: false,
            step8: false,
            step9: false,
        };

        let isDone = false;

        dispatch(addTutorial(defaultTut));

        introJs()
            .setOptions({
                disableInteraction: true,
                exitOnOverlayClick: false,
                keyboardNavigation: false,
                nextLabel: t("dialog.continue"),
                prevLabel: t("miscellaneous.goBack"),
                doneLabel: t("miscellaneous.accept"),
                steps: [
                    {
                        title: `${t("miscellaneous.step")} 6`,
                        element: "#account4-tutorial",
                        intro: t("miscellaneous.tutorialConv1"),
                    },
                ],
            })
            .oncomplete(() => {
                isDone = true;
                dispatch(addTutorial({ ...todos.steps, step6: false, step7: true }));
            })
            .onexit(() => {
                if (!isDone) {
                    dispatch(addTutorial({ ...todos.steps, step6: false }));
                }
            })
            .start();
    };

    // Al menos 1 conversión es exitosa
    const successConv = () => {
        return rows.length > 0 && rows.filter((item) => item.status === 200).length > 0;
    };

    // Todas las conversiones están en revisión
    const revConv = () => {
        return (
            rows.length > 0 &&
            rows.filter((item) => item.status === 400).length > 0 &&
            rows.filter((item) => item.status === 400).length === rows.length
        );
    };

    // Error
    const errorConv = () => {
        return (
            rows.length > 0 &&
            rows.filter((item) => item.status !== 400 && item.status !== 200).length > 0 &&
            rows.filter((item) => item.status !== 400 && item.status !== 200).length === rows.length
        );
    };

    const totalPriceConv = () => {
        return rows.reduce(function (sum, item) {
            return sum + item.price;
        }, 0);
    };

    const previewDoc = async (e) => {
        e.stopPropagation();

        setIsLoading(true)
        let dataFile = await fetchFileFromS3(rows[0].file_name_s3);
        setIsLoading(false)
        
        if (!dataFile) {
            setTexto(t("converter.dnldError"));
            setAlertType("error");
            setShow(true);
        } else {
            setFileData(dataFile);
            if (getFileExtension(rows[0].file_name_s3) === "xlsx" || getFileExtension(rows[0].file_name_s3) === "xls") {
                const f = await acceptedFiles[0].arrayBuffer();
    
                /* parse workbook */
                const workbook = read(f, { type: "binary" });
                /* get first worksheet */
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                /* generate and display HTML */
                const table = utils.sheet_to_html(worksheet);
                setHTML(table);
            }
        }

        setFileNameFull(rows[0].file_name_s3);

        setBasePreviewData({
            type: "bankStatement",
            id: convInfo.statusByFile[0].conversionId,
        });

        // Open modal
        setOpenPreview(true);
    };

    // Manejar clic de botón de "X" en Intro.js
    useEffect(() => {
        const handleButtonClick = (event) => {
            if (event.target.classList.contains('introjs-skipbutton')) {
                dispatch(dontShowAgainTutorial(true))
            }
        };

        document.addEventListener('click', handleButtonClick);

        // Limpiar event listener al desmontar el componente
        return () => {
            document.removeEventListener('click', handleButtonClick);
        };
    }, [dispatch]);

    return (
        <Grid container direction="column" sx={{ mb: 2 }}>
            <Grid item>
                <Box
                    className={
                        revConv()
                            ? classes.backYellowConv
                            : successConv()
                            ? classes.backGreenConv
                            : errorConv()
                            ? classes.backRed
                            : classes.backGray
                    }
                    id="account4-tutorial"
                >
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography variant="h1" sx={{ mb: 1.5 }}>
                                {t("converter.importEECC")}
                            </Typography>
                            <LightTooltip title={t("miscellaneous.help")}>
                                <IconButton onClick={openTutorial}>
                                    <HelpIcon fontSize="small" color="primary" />
                                </IconButton>
                            </LightTooltip>
                        </Stack>
                        <Button onClick={() => handleClickHelp("generalConv", "", "")}>{t("accounts.needHelp")}</Button>
                    </Stack>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item xs={12} md={2.5}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <BaseSelect
                                    value={account}
                                    values={accounts}
                                    onChange={handleChange}
                                    label={t("converter.selectBank")}
                                    color="white"
                                    type="bank"
                                    hasTooltip={true}
                                />
                                <LightTooltip title={t("converter.goManageAcc")}>
                                    <IconButton size="small" color="primary" onClick={handleNavigation}>
                                        <TuneIcon sx={{ transform: "rotate(90deg)" }} fontSize="small" />
                                    </IconButton>
                                </LightTooltip>
                            </Stack>
                        </Grid>
                        <Grid item xs={12} md={rows.length > 1 ? 4 : 4.5}>
                            <UploadFile
                                acceptedFiles={acceptedFiles}
                                setAcceptedFiles={setAcceptedFiles}
                                formats={formats}
                                disabled={!account}
                                setTexto={setTexto}
                                setAlertType={setAlertType}
                                setShow={setShow}
                                setS3FileName={setS3FileName}
                                setOriginalFileName={setOriginalFileName}
                                s3FileName={s3FileName}
                                setConvFormat={setConvFormat}
                                conversionFormats={conversionFormats}
                                convertDone={convertDone}
                                convertRev={convertRev}
                                convertInvalid={convertInvalid}
                                originalFileName={originalFileName}
                                accountId={account}
                                setProgress={setProgress}
                                progress={progress}
                                convInfo={convInfo}
                                setConvInfo={setConvInfo}
                                setStatus={setStatus}
                                setShowRepeatModal={setShowRepeatModal}
                                setShowRepeatVal={setShowRepeatVal}
                                rows={rows}
                                setRows={setRows}
                                setOpenModal={setOpenModal}
                                accountDet={accountDet}
                                resetConversion={resetConversion}
                                setCorrelative={setCorrelative}
                                type="bankStatement"
                                setRowsModal={setRowsModal}
                                rowsModal={rowsModal}
                                reload={handleGetConversions}
                                previewDoc={previewDoc}
                            />
                        </Grid>
                        <Grid item xs={12} md={rows.length > 1 ? 2.5 : 3.5}>
                            {(successConv() || revConv()) && (
                                <Stack spacing={1} alignItems="flex-start">
                                    <Stack direction="row" spacing={1} alignItems="center">
                                        <Typography variant="h3" sx={{ color: revConv() ? "#FFB23F" : "#34bd6a" }}>
                                            {rows.filter((item) => item.status === 200).length > 1
                                                ? "#" +
                                                  zeroPad(
                                                      rows.filter((item) => item.status === 200)[0].number ?? 0,
                                                      5
                                                  ) +
                                                  " - " +
                                                  "#" +
                                                  zeroPad(
                                                      rows.filter((item) => item.status === 200)[
                                                          rows.filter((item) => item.status === 200).length - 1
                                                      ].number ?? 0,
                                                      5
                                                  )
                                                : ""}
                                        </Typography>
                                        <Typography variant="h3">
                                            {revConv() ? t("converter.conversionRev") : t("converter.readyConv")}
                                        </Typography>
                                        {revConv() ? (
                                            <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="small" />
                                        ) : (
                                            <CheckIcon sx={{ color: "#00943b" }} fontSize="small" />
                                        )}
                                    </Stack>
                                    {rows.length === 1 && successConv() && (
                                        <Typography>
                                            {`${t("converter.since")} ${moment
                                                .utc(convInfo.statusByFile[0].initialDate)
                                                .format(todos.dateFormat)} ${t("converter.until")} ${moment
                                                .utc(convInfo.statusByFile[0].endDate)
                                                .format(todos.dateFormat)}`}
                                        </Typography>
                                    )}
                                    <Box className={classes.backYellow}>
                                        {revConv() ? (
                                            <Typography variant="h3">{t("converter.convRev")}</Typography>
                                        ) : (
                                            <Typography>
                                                <b>{t("converter.price")}</b>:{" "}
                                                {`${totalPriceConv()} ${
                                                    totalPriceConv() === 1
                                                        ? t("converter.page")
                                                        : t("converter.pages")
                                                }`}
                                            </Typography>
                                        )}
                                    </Box>
                                    {revConv() && (
                                        <Typography variant="h3" fontWeight={500} sx={{ pl: 1 }}>
                                            {t("converter.conversionRevMsg2")}
                                        </Typography>
                                    )}
                                    {status === 1 && todos.credits - totalPriceConv() > 0 && (
                                        <Typography className={classes.creditsText}>
                                            <b>{t("converter.rest")}:</b>{" "}
                                            <span
                                                style={{
                                                    display: "inline",
                                                    color: todos.credits - totalPriceConv() < 0 ? "#FF5E77" : "auto",
                                                }}
                                            >
                                                {todos.credits - totalPriceConv()}
                                            </span>{" "}
                                            {t("register.conversions")}
                                        </Typography>
                                    )}
                                </Stack>
                            )}
                            {errorConv() && (
                                <Grid container spacing={1} justifyContent="center">
                                    <Grid item xs={12} md={10}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Typography variant="h3" sx={{ color: "#FF5E77" }}>
                                                {"#" + zeroPad(rows[0].number ?? 0, 5)}
                                            </Typography>
                                            <Typography variant="h3">{t("converter.invalidLec")}</Typography>
                                            <CloseIcon sx={{ color: "#FF5E77" }} fontSize="small" />
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Typography>{t("converter.kiiperInvalid")}</Typography>
                                    </Grid>
                                    <Grid item xs={12} md={10}>
                                        <Stack spacing={1}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Box component="img" src={Step1} sx={{ width: 25 }} />
                                                <Typography>{t("converter.step1")}</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Box component="img" src={Step2} sx={{ width: 25 }} />
                                                <Typography>{t("converter.step2")}</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Box component="img" src={Step3} sx={{ width: 25 }} />
                                                <Typography>{t("converter.step3")}</Typography>
                                            </Stack>
                                        </Stack>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                        {rows.length > 1 && (
                            <Grid item xs={12} md={1.5}>
                                <Button
                                    variant="outlined"
                                    endIcon={<SearchIcon style={{ width: "27px", height: "27px" }} />}
                                    onClick={showConversions}
                                    sx={{
                                        py: 1.4,
                                        width: { xs: "100%", md: "80%" },
                                        justifyContent: "space-between",
                                        backgroundColor: "white",
                                        "&:hover": {
                                            backgroundColor: "#E5E5E5",
                                        },
                                        "&:disabled": {
                                            color: "#FFF",
                                            backgroundColor: "rgba(189, 189, 189, 1)",
                                        },
                                    }}
                                >
                                    {t("converter.seeDetails")}
                                </Button>
                            </Grid>
                        )}
                        <Grid item xs={12} md={1.5}>
                            {progress > 0 ? (
                                <>
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                        <BaseLinearProgress progress={progress} />
                                        {progress === 100 && isLoadingConv && <CircularProgress color="secondary" />}
                                    </Stack>
                                    <Typography variant="h2" sx={{ pl: 3, pt: 1 }}>
                                        {isLoadingConv ? t("converter.calculate") : t("converter.uploadingCheck")}
                                    </Typography>
                                </>
                            ) : (
                                <Button
                                    variant={errorConv() || revConv() ? "outlined" : "contained"}
                                    endIcon={<CachedIcon style={{ width: "27px", height: "27px" }} />}
                                    disabled={
                                        isDisabled ||
                                        todos.credits - totalPriceConv() < 0 ||
                                        (rows.length === 0 && status !== 2)
                                    }
                                    onClick={
                                        errorConv() || revConv()
                                            ? () => resetConversion()
                                            : (e) => handleShowPrevDownload(e)
                                    }
                                    sx={{
                                        py: 1.4,
                                        width: { xs: "100%", md: "100%" },
                                        justifyContent: "space-between",
                                        backgroundColor: errorConv() || revConv() ? "white" : "#4a22d4",
                                        "&:hover": {
                                            backgroundColor: errorConv() || revConv() ? "#E5E5E5" : "rgb(51, 23, 148)",
                                        },
                                        "&:disabled": {
                                            color: "#FFF",
                                            backgroundColor: "rgba(189, 189, 189, 1)",
                                        },
                                    }}
                                >
                                    {errorConv() || revConv() ? t("converter.newConv") : t("converter.convert")}
                                </Button>
                            )}
                        </Grid>
                    </Grid>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 2 }}>
                            <Typography>
                                {t("converter.discountConv")} <b>{t("converter.whatisBankState")}</b>
                            </Typography>
                            <LightTooltip title={t("converter.questionEECCTooltip")}>
                                <InfoIconMUI fontSize="small" color="primary" />
                            </LightTooltip>
                        </Stack>
                        {todos.credits - totalPriceConv() < 0 && (
                            <Stack direction="row" alignItems="center" spacing={2} className={classes.backMiddle}>
                                <Box component="img" src={ErrorIconConverter} sx={{ width: 25 }} />
                                <Box>
                                    <Typography className={classes.textBlue4}>
                                        {t("converter.noCreditsLeft")}
                                    </Typography>
                                    <Link
                                        sx={{ textDecoration: "none", fontWeight: "bold", cursor: "pointer" }}
                                        onClick={handleSetShowModalCredits}
                                    >
                                        {t("converter.rechargeCredits")}
                                    </Link>
                                </Box>
                            </Stack>
                        )}
                    </Stack>
                </Box>
            </Grid>
            {showModalCredits && (
                <AddCreditPacksDialog
                    open={showModalCredits}
                    onClose={handleClose}
                    setShowAddPay={setShowAddPay}
                    setShowChangePay={setShowChangePay}
                    getPaymentMethods={() => getPaymentMethods(false)}
                    activePlan={activePlan}
                    paymentMethod={defaultPayMethod}
                    setIsLoading={setIsLoading}
                    companyDetails={companyDetails}
                    allCompanies={allCompanies}
                    paymentType={paymentType}
                />
            )}
            {showAddPay && (
                <AddPaymentDialog
                    open={showAddPay}
                    handleClose={handleCloseModal}
                    maxWidth="sm"
                    setShow={setShow}
                    setMessage={setTexto}
                    setAlertType={setAlertType}
                    setIsLoading={setIsLoading}
                    handleClose2={handleCloseModal2}
                    converter={true}
                />
            )}
            {dialogCancelConvert && (
                <AlertDialog
                    type="warning"
                    open={dialogCancelConvert}
                    onClose={handleCloseDialogCancel}
                    title={t("converter.alertNewConvert")}
                    message={bodyMess}
                    disagreeBtnLabel={t("dialog.cancel1")}
                    agreeBtnLabel={t("dialog.continue1")}
                    maxWidth="sm"
                    agreeAction={handleCancelConvert}
                    origin="converter"
                />
            )}
            {showChangePay && (
                <ChangePayDialog
                    open={showChangePay}
                    handleClose={handleCloseChangePay}
                    payments={paymentMethods}
                    setIsLoading={setIsLoading}
                    reloadData={() => getPaymentMethods(true)}
                    defaultCard={defaultPayMethod}
                    handleAddPaymentM={handleAddPaymentM}
                />
            )}
            <SimpleDialog
                open={showRepeatModal}
                handleClose={handleCloseRepeModal}
                maxWidth="md"
                content={
                    <Box sx={{ px: 4, maxWidth: 800 }}>
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ pb: 2 }} spacing={2}>
                            <WarningIcon size="small" sx={{ color: "#FFB23F" }} />
                            <Typography variant="h1" sx={{ color: "#FFB23F" }}>
                                {t("converter.dupliConv")}
                            </Typography>
                        </Stack>
                        {showRepeatVal.length === 1 && (
                            <Typography gutterBottom>
                                {`${t("converter.mainText")} #${zeroPad(showRepeatVal[0].correlative ?? 0, 5)}${t(
                                    "converter.mainText2"
                                )}`}
                            </Typography>
                        )}
                        {showRepeatVal.length > 1 && (
                            <>
                                {rows.length > 1 ? (
                                    <Typography gutterBottom>{t("converter.mainText3")}</Typography>
                                ) : (
                                    <Typography sx={{ pb: 2 }}>{t("converter.secondText")}</Typography>
                                )}
                                <TableContainer sx={{ maxHeight: 500 }}>
                                    <Table
                                        aria-label="simple table"
                                        sx={{ borderCollapse: "separate", borderSpacing: "0 4px" }}
                                    >
                                        <TableHead>
                                            <StyledTableRow>
                                                {columns.map((item) => (
                                                    <StyledTableCell key={item.name}>{item.title}</StyledTableCell>
                                                ))}
                                            </StyledTableRow>
                                        </TableHead>
                                        <TableBody>
                                            {showRepeatVal.map((row) => (
                                                <StyledTableRow
                                                    key={row.correlative}
                                                    sx={{
                                                        "& td:first-of-type": {
                                                            border: 0,
                                                            borderTopLeftRadius: 20,
                                                            borderBottomLeftRadius: 20,
                                                        },
                                                        "& td:last-child": {
                                                            borderTopRightRadius: 20,
                                                            borderBottomRightRadius: 20,
                                                        },
                                                        backgroundColor: "#F8F8F9",
                                                    }}
                                                >
                                                    <StyledTableCell>{row.uploaded_file}</StyledTableCell>
                                                    <StyledTableCell>
                                                        {`${t("subscription.conversion")} #${zeroPad(
                                                            row.correlative ?? 0,
                                                            5
                                                        )}`}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </>
                        )}
                        <Button onClick={handleCloseRepeModal} sx={{ ml: 2 }}>
                            {t("register.back")}
                        </Button>
                    </Box>
                }
            />
            <BackgroundDialog
                open={openModal}
                handleClose={handleCloseModalBack}
                convNum={zeroPad(convInfo?.statusByFile?.[0]?.correlative, 5)}
            />
        </Grid>
    );
};

export default MainConverter;
