/** React imports */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/** MUI imports */
import { Dialog, DialogContent, DialogTitle, Box, Stepper, Step, StepButton, Typography, Stack } from "@mui/material";
import ExclamationIcon from "../../assets/exclamation-icon.svg";

/** Js imports **/
import companyService from "../../services/company";
import userCompanyService from "../../services/user_company";
import BasicInfoStep from "./AddCompanyStep1";
import IntegrationStep from "./AddCompanyStep2";
import ButtonsStep from "./AddCompanyButtons";
import authService from "../../services/auth";
import { useLocation, useSearchParams } from "react-router-dom";
import { addIsGrouped, addCompanyID, addAllComData } from "../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";

const PREFIX = "add-company-dialog";

const classes = {
    title: `${PREFIX}-title`,
    content: `${PREFIX}-content`,
    actions: `${PREFIX}-actions`,
    paper: `${PREFIX}-paper`,
};

const systemsList = ["Xero", "QuickBooks desktop", "QuickBooks online", "Otro"];

const AddCompanyDialog = (props) => {
    const {
        open,
        onClose,
        setReload,
        setIsLoading,
        franchiseID,
        setShow,
        setTexto,
        setAlertType,
        addNew,
        franchiseInfo,
        softwares,
        companyRedirect,
        setCompanyRedirect,
    } = props;

    const [t] = useTranslation("global");

    const [steps, setSteps] = useState([]);
    const [activeStep, setActiveStep] = useState(0);

    const [integ, setInteg] = useState(3);
    const [isError, setIsError] = useState(false);

    const [smbInfo, setSmbInfo] = useState();

    const [searchParams, setSearchParams] = useSearchParams();

    const location = useLocation();

    const dispatch = useDispatch();

    /** Navigate */
    const navigate = useNavigate();

    const [isErrorName, setIsErrorName] = useState(false);

    const todos = useSelector((state) => state.value);

    useEffect(() => {
        setSmbInfo({
            id: companyRedirect ? companyRedirect.info._id : null,
            companyType: companyRedirect ? companyRedirect.info.client_type : 1, // 2: SMB | 1: Firma
            companyName: companyRedirect ? companyRedirect.info.company_name : "",
            country: companyRedirect ? companyRedirect.info.country_name : franchiseInfo.country_name,
            systems: companyRedirect ? companyRedirect.info.file_formats : softwares ? softwares : [],
            ecActivity: companyRedirect ? companyRedirect.info.business_activity : "",
            system_subscription_name: companyRedirect ? companyRedirect.info.system_subscription_name : "",
        });

        if (companyRedirect) {
            setActiveStep(1);
            setInteg(companyRedirect.integ);
        } else {
            setActiveStep(0);
            setInteg(3);
        }
    }, [companyRedirect, franchiseInfo, softwares]);

    useEffect(() => {
        let item1 = t("dialog.basicInfo");
        let item2 = t("dialog.integration");
        setSteps([item1, item2]);
    }, [t]);

    const companyTypeList = [
        { id: 2, name: t("dialog.smb") },
        { id: 1, name: t("dialog.firm") },
    ];

    const handleNext = async () => {
        setIsLoading(true);
        if (activeStep === 0) {
            if (
                smbInfo.companyType &&
                smbInfo.companyName.trim() &&
                smbInfo.country &&
                smbInfo.systems.length > 0 &&
                smbInfo.ecActivity &&
                isErrorName === false
            ) {
                // Agregar SMB
                if (smbInfo.id) {
                    // Update
                    const params = {
                        company_id: smbInfo.id,
                        company_name: smbInfo.companyName,
                        country_name: smbInfo.country,
                        client_type: smbInfo.companyType,
                        business_activity: smbInfo.ecActivity,
                        franchise_id: smbInfo.franchise,
                    };

                    await companyService
                        .updateCompany(params)
                        .then(() => {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                        })
                        .catch((err) => {
                            console.log(err);
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        });
                } else {
                    // Add
                    const params = {
                        company_name: smbInfo.companyName,
                        country_name: smbInfo.country,
                        client_type: 1,
                        business_activity: smbInfo.ecActivity,
                        file_formats: smbInfo.systems,
                        franchise_id: franchiseID,
                    };

                    await companyService
                        .addNewCompany(params)
                        .then((response) => {
                            setActiveStep((prevActiveStep) => prevActiveStep + 1);
                            setSmbInfo({ ...smbInfo, id: response._id });
                        })
                        .catch((err) => {
                            console.log(err);
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        });
                }
            } else {
                setIsError(true);
            }
        }
        if (activeStep === 1) {
            if (integ) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleInteg = async (index) => {
        if (index === 1 && integ !== 1) {
            await authService
                .signUpXero({
                    company_id: smbInfo.id,
                    type: 1,
                    location: location.pathname.replace("/", ""),
                })
                .then((response) => {
                    window.location.href = response.redirectUrl;
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (index === 2 && integ !== 2) {
            await authService
                .signUpQB({
                    company_id: smbInfo.id,
                    type: 1,
                    location: location.pathname.replace("/", ""),
                })
                .then((response) => {
                    window.location.href = response.redirectUrl;
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (index === 1 && integ === 1) {
            // Desconectar
            await authService
                .logoutXero({
                    company_id: smbInfo.id,
                })
                .then(() => {
                    setInteg(3);

                    setAlertType("success");
                    setTexto(t("dialog.disconnectionEstablished"));
                    setShow(true);

                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (index === 2 && integ === 2) {
            // Desconectar
            await authService
                .logoutQB({
                    company_id: smbInfo.id,
                })
                .then(() => {
                    setInteg(3);

                    setAlertType("success");
                    setTexto(t("dialog.disconnectionEstablished"));
                    setShow(true);

                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setInteg(index);
        }
    };

    const handleChange = (event) => {
        setSmbInfo({
            ...smbInfo,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeCompanyName = async (event) => {
        setSmbInfo({
            ...smbInfo,
            [event.target.name]: event.target.value,
        });

        const params = {
            franchise_id: franchiseID,
            company_name: event.target.value,
            company_id: smbInfo.id ? smbInfo.id : undefined,
        };

        await companyService
            .validateCompanyName(params)
            .then((data) => {
                if (data === true) {
                    setIsErrorName(true);
                } else {
                    setIsErrorName(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });
    };

    const createCompany = async () => {
        setIsLoading(true);

        if (
            smbInfo.companyType &&
            smbInfo.companyName.trim() &&
            smbInfo.country &&
            smbInfo.systems.length &&
            smbInfo.ecActivity &&
            integ
        ) {
            const params = {
                company_id: smbInfo.id,
                company_name: smbInfo.companyName,
                country_name: smbInfo.country,
                client_type: 1,
                business_activity: smbInfo.ecActivity,
                file_formats: smbInfo.systems,
                system_integration: integ,
                franchise_id: franchiseID,
            };

            await companyService
                .updateCompany(params)
                .then(async () => {
                    const result = await userCompanyService.getCompanies();

                    handleClose(true);
                    setAlertType("success");
                    setTexto(t("dialog.successAddSmb"));
                    setShow(true);
                    setReload((prevState) => !prevState);
                    dispatch(addAllComData(result.records));
                    dispatch(addIsGrouped(false));
                    dispatch(addCompanyID(smbInfo.id));
                    if (todos.selected === "ddlSalesInv") {
                        navigate(`/convert/invoices`);
                    } else if (todos.selected === "ddlPurchaseInv") {
                        navigate(`/convert/bills`);
                    } else {
                        navigate("/dashboard");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setTexto("Error");
                    setAlertType("error");
                    setShow(true);
                });
        } else {
            setIsError(true);
        }

        setIsLoading(false);
    };

    const handleSkip = () => {
        if (activeStep === 1) {
            setActiveStep(0);
        } else {
            handleClose(false);
        }
    };

    const handleClose = async (isCreated) => {
        searchParams.delete("connected");
        searchParams.delete("connect");
        searchParams.delete("company_id");
        searchParams.delete("redirectType");
        setSearchParams(searchParams);

        if (smbInfo.id && !isCreated) {
            setIsLoading(true);

            await companyService
                .deleteNewCompany({ company_id: smbInfo.id })
                .then(() => setReload((prevState) => !prevState))
                .catch((err) => {
                    console.log(err.message);
                    setTexto("Error");
                    setAlertType("error");
                    setShow(true);
                });

            setIsLoading(false);
        }

        onClose();
        setActiveStep(0);
        setInteg(3);
        setIsError(false);
        setSmbInfo({
            id: null,
            companyType: 2, // 2: SMB | 1: Firma
            companyName: "",
            country: "",
            systems: [],
            ecActivity: "",
        });
        let item1 = t("dialog.basicInfo");
        let item2 = t("dialog.integration");
        setSteps([item1, item2]);
        setCompanyRedirect(null);
        setIsErrorName(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => handleClose(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                sx: {
                    borderRadius: "50px",
                    p: { xs: 3, md: 4 },
                    maxWidth: 980,
                },
            }}
            fullWidth
        >
            <DialogTitle id="alert-dialog-title" className={classes.title}>
                <Stack direction="row" justifyContent="start" alignItems="center">
                    {addNew && (
                        <Box
                            component="img"
                            sx={{
                                height: 28,
                                width: 28,
                                maxHeight: { xs: 28, md: 28 },
                                maxWidth: { xs: 28, md: 28 },
                                zIndex: 999,
                                mr: 0.8,
                            }}
                            src={ExclamationIcon}
                        />
                    )}
                    <Typography fontSize={25} fontWeight={600}>
                        {t("dialog.addCompanyFranchise")}{" "}
                        <Box
                            component="span"
                            sx={{
                                color: "#4A22D4",
                            }}
                        >
                            {franchiseInfo.franchise_name}
                        </Box>
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent className={classes.content}>
                <Box sx={{ width: "100%" }}>
                    <Stepper
                        activeStep={activeStep}
                        sx={{ px: { xs: 0, sm: 8, md: 25 }, py: 2, justifyContent: "center" }}
                        orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            return (
                                <Step
                                    key={label}
                                    {...stepProps}
                                    sx={{
                                        "& .MuiStepLabel-root .Mui-completed": {
                                            color: "#031851", // circle color (COMPLETED)
                                            borderRadius: "50%",
                                        },
                                        "& .MuiStepLabel-root .Mui-active": {
                                            color: "#031851", // circle color (ACTIVE)
                                            borderRadius: "50%",
                                        },
                                        ".MuiSvgIcon-root": {
                                            color: "rgba(0, 0, 0, 0.38)",
                                            borderRadius: "50%",
                                        },
                                    }}
                                    /*MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiStepIcon-root css-jl6sxv-MuiSvgIcon-root-MuiStepIcon-root*/
                                >
                                    <StepButton color="inherit" onClick={handleStep(index)}>
                                        <Typography
                                            fontStyle="normal"
                                            fontSize={18}
                                            fontWeight={600}
                                            sx={{ color: "#131F3E", mx: 1 }}
                                        >
                                            {label}
                                        </Typography>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <>
                        {activeStep === 0 && (
                            <BasicInfoStep
                                isError={isError}
                                smbInfo={smbInfo}
                                companyTypeList={companyTypeList}
                                handleChange={handleChange}
                                systemsList={systemsList}
                                handleChangeCompanyName={handleChangeCompanyName}
                                isErrorName={isErrorName}
                            />
                        )}
                        {activeStep === 1 && (
                            <IntegrationStep integ={integ} handleInteg={handleInteg} smbInfo={smbInfo} />
                        )}
                        <ButtonsStep
                            activeStep={activeStep}
                            steps={steps}
                            onClose={handleSkip}
                            handleNext={handleNext}
                            createCompany={createCompany}
                            isErrorName={isErrorName}
                            smbInfo={smbInfo}
                        />
                    </>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default AddCompanyDialog;
