/** React imports */
import { useTranslation } from "react-i18next";

/** MUI imports */
import {
    Grid,
    Card,
    CardContent,
    Box,
    Button,
    Typography,
    Stack,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

/** Images **/
import XeroLogo from "../../assets/xero.svg";
import QuickBooksLogo from "../../assets/quickbooks.svg";
import { useEffect, useState } from "react";
import LightTooltip from "../ui/LightTooltip";
import { useLocation } from "react-router-dom";
import authService from "../../services/auth";

const CustomCard = styled(Card)(({ theme }) => ({
    display: "flex",
    minHeight: 90,
    justifyContent: "space-between",
    alignItems: "center",
    transition: "transform 0.2s", // Add a transition for a smooth effect
    borderRadius: 8,
    paddingLeft: 20,
    paddingRight: 20,
}));

const ColorButton2 = styled(Button)(({ theme }) => ({
    letterSpacing: "-0.22px",
    backgroundColor: "#B3B3B3",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: "550",
    border: "1px solid #B3B3B3",
    borderRadius: "6px",
    fontSize: "12px",
    paddingLeft: "45px",
    paddingRight: "45px",
    // paddingRight: "22px",
    paddingTop: "7px",
    paddingBottom: "7px",
    whiteSpace: "nowrap",
    "&:disabled": {
        backgroundColor: "#B3B3B3",
        border: "1px solid #B3B3B3",
        color: "#FFF",
    },
    
}));

const Integrations = (props) => {
    const [t] = useTranslation("global");

    const { smbInfo, setTexto, setShow, setAlertType, integ, setInteg, origin } = props;

    const [textButton1, setTextButton1] = useState(t("dialog.connect"));
    const [textButton2, setTextButton2] = useState(t("dialog.connect"));

    useEffect(() => {
        setInteg(smbInfo.system_integration ?? 3)
    }, [setInteg, smbInfo.system_integration])

    const location = useLocation();

    const handleInteg = async (index) => {
        if (index === 1 && integ !== 1) {
            await authService
                .signUpXero({
                    company_id: smbInfo._id,
                    type: origin === "company" ? 1 : 3,
                    location: location.pathname.replace("/", ""),
                    search: location.search
                })
                .then((response) => {
                    window.location.href = response.redirectUrl;
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (index === 2 && integ !== 2) {
            await authService
                .signUpQB({
                    company_id: smbInfo._id,
                    type: origin === "company" ? 1 : 3,
                    location: location.pathname.replace("/", ""),
                })
                .then((response) => {
                    window.location.href = response.redirectUrl;
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (index === 1 && integ === 1) {
            // Desconectar
            await authService
                .logoutXero({
                    company_id: smbInfo._id,
                })
                .then(() => {
                    setInteg(3);

                    setAlertType("success");
                    setTexto(t("dialog.disconnectionEstablished"));
                    setShow(true);

                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else if (index === 2 && integ === 2) {
            // Desconectar
            await authService
                .logoutQB({
                    company_id: smbInfo._id,
                })
                .then(() => {
                    setInteg(3);

                    setAlertType("success");
                    setTexto(t("dialog.disconnectionEstablished"));
                    setShow(true);

                    setTimeout(() => {
                        setShow(false);
                    }, 3000);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            setInteg(index);
        }
    };

    useEffect(() => {
        (async () => {
            if (integ === 1) {
                setTextButton1(t("dialog.connected"));
                setTextButton2(t("dialog.connect"));
            } else if (integ === 2) {
                setTextButton2(t("dialog.connected"));
                setTextButton1(t("dialog.connect"));
            }
        })();
    }, [integ, t]);

    const handleOver = (index) => {
        if (index === 1) {
            if (integ === 1) {
                setTextButton1(t("dialog.disconnect"));
            } else {
                setTextButton1(t("dialog.connect"));
            }
        } else if (index === 2) {
            if (integ === 2) {
                setTextButton2(t("dialog.disconnect"));
            } else {
                setTextButton2(t("dialog.connect"));
            }
        }
    };

    const handleOut = (index) => {
        if (index === 1) {
            if (integ === 1) {
                setTextButton1(t("dialog.connected"));
            } else {
                setTextButton1(t("dialog.connect"));
            }
        } else if (index === 2) {
            if (integ === 2) {
                setTextButton2(t("dialog.connected"));
            } else {
                setTextButton2(t("dialog.connect"));
            }
        }
    };

    return (
        <Box>
            <Typography fontWeight={600} fontSize={16} my={2}>
                {origin === "company" ? t("register.integFirstCompany").replace("first ", "").replace("primera ", "") : t("register.integFirstCompany")}
            </Typography>
            <Typography
                sx={{ py: 1 }}
                dangerouslySetInnerHTML={{
                    __html: t("register.integAccSystem"),
                }}
            ></Typography>
            <Grid container justifyContent="center" sx={{ mt: 1 }} spacing={3}>
                <Grid item xs={12} md={6}>
                    <CustomCard
                        elevation={0}
                        sx={{
                            transform:
                                integ && integ === 1
                                    ? "scale(1.06)"
                                    : "scale(1.0)", // Scale the card on hover
                            backgroundColor: "rgba(26, 180, 215, 0.1)",
                            "&:hover": {
                                transform: "scale(1.05)", // Scale the card on hover
                                backgroundColor: "rgba(26, 180, 215, 0.2)",
                            },
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="start"
                            spacing={2}
                            alignItems="center"
                        >
                            <Typography>Sincroniza tus cuentas en</Typography>
                            <Box
                                component="img"
                                sx={{
                                    height: 36,
                                    width: 36,
                                    maxHeight: { xs: 36, md: 36 },
                                    maxWidth: { xs: 36, md: 36 },
                                }}
                                src={XeroLogo}
                            />
                        </Stack>
                        <Box>
                            <CardContent sx={{ flex: "1 0 auto", px: 0, mx: 0 }}>
                                <ColorButton2
                                    onClick={() => handleInteg(1)}
                                    disableElevation
                                    endIcon={
                                        integ && integ === 1 ? (
                                            <CheckIcon sx={{ width: "14px" }} />
                                        ) : (
                                            <></>
                                        )
                                    }
                                    variant="contained"
                                    size="large"
                                    // disabled={subscription === "freeTrial" || integ === 2}
                                    onMouseOver={() => handleOver(1)}
                                    onMouseOut={() => handleOut(1)}
                                    sx={{
                                        backgroundColor:
                                            integ && integ === 1
                                                ? "#131F3E"
                                                : "#4A22D4",
                                        border:
                                            integ && integ === 1
                                                ? "1px solid #131F3E"
                                                : "1px solid #4A22D4",
                                        color:
                                            integ && integ === 1
                                                ? "#00FFFF"
                                                : "#FFF",
                                        "&:hover": {
                                            backgroundColor:
                                                integ && integ === 1
                                                    ? "#131F3E"
                                                    : "#3C1BAD",
                                            border:
                                                integ && integ === 1
                                                    ? "1px solid #131F3E"
                                                    : "1px solid #3C1BAD",
                                            color:
                                                integ && integ === 1
                                                    ? "#00FFFF"
                                                    : "#FFF",
                                        },
                                    }}
                                >
                                    {textButton1}
                                </ColorButton2>
                                {integ === 1 &&
                                    smbInfo.system_subscription_name && (
                                        <LightTooltip
                                            title={
                                                smbInfo.system_subscription_name
                                                    .length <= 50
                                                    ? ""
                                                    : smbInfo.system_subscription_name
                                            }
                                        >
                                            <Typography
                                                sx={{
                                                    paddingTop: 1.5,
                                                    width: 120,
                                                    fontSize: 12,
                                                }}
                                                variant="h4"
                                            >
                                                {smbInfo
                                                    .system_subscription_name
                                                    .length <= 50
                                                    ? smbInfo.system_subscription_name
                                                    : `${smbInfo.system_subscription_name.substring(
                                                          0,
                                                          50
                                                      )}...`}
                                            </Typography>
                                        </LightTooltip>
                                    )}
                            </CardContent>
                        </Box>
                    </CustomCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomCard
                        elevation={0}
                        sx={{
                            transform:
                                integ && integ === 2
                                    ? "scale(1.06)"
                                    : "scale(1.0)", // Scale the card on hover
                            backgroundColor: "rgba(44, 159, 28, 0.1)",
                            "&:hover": {
                                transform: "scale(1.05)", // Scale the card on hover
                                backgroundColor: "rgba(44, 159, 28, 0.2)",
                            },
                        }}
                    >
                        <Stack
                            direction="row"
                            justifyContent="start"
                            spacing={1}
                        >
                            <Typography>Sincroniza tus cuentas con</Typography>
                            <Box
                                component="img"
                                sx={{
                                    height: 21,
                                    width: 108,
                                    maxHeight: { xs: 21, md: 21 },
                                    maxWidth: { xs: 108, md: 108 },
                                }}
                                src={QuickBooksLogo}
                            />
                        </Stack>
                        <Box>
                            <CardContent sx={{ flex: "1 0 auto", px: 0, mx: 0 }}>
                                <ColorButton2
                                    onClick={() => handleInteg(2)}
                                    endIcon={
                                        integ && integ === 2 ? (
                                            <CheckIcon sx={{ width: "14px" }} />
                                        ) : (
                                            <></>
                                        )
                                    }
                                    disableElevation
                                    variant="contained"
                                    size="large"
                                    // disabled={subscription === "freeTrial" || integ === 1}
                                    onMouseOver={() => handleOver(2)}
                                    onMouseOut={() => handleOut(2)}
                                    sx={{
                                        backgroundColor:
                                            integ && integ === 2
                                                ? "#131F3E"
                                                : "#4A22D4",
                                        border:
                                            integ && integ === 2
                                                ? "1px solid #131F3E"
                                                : "1px solid #4A22D4",
                                        color:
                                            integ && integ === 2
                                                ? "#00FFFF"
                                                : "#FFF",
                                        "&:hover": {
                                            backgroundColor:
                                                integ && integ === 2
                                                    ? "#131F3E"
                                                    : "#3C1BAD",
                                            border:
                                                integ && integ === 2
                                                    ? "1px solid #131F3E"
                                                    : "1px solid #3C1BAD",
                                            color:
                                                integ && integ === 2
                                                    ? "#00FFFF"
                                                    : "#FFF",
                                        },
                                    }}
                                >
                                    {textButton2}
                                </ColorButton2>
                                {integ === 2 &&
                                    smbInfo.system_subscription_name && (
                                        <LightTooltip
                                            title={
                                                smbInfo.system_subscription_name
                                                    .length <= 50
                                                    ? ""
                                                    : smbInfo.system_subscription_name
                                            }
                                        >
                                            <Typography
                                                sx={{
                                                    paddingTop: 1.5,
                                                    width: 120,
                                                    fontSize: 12,
                                                }}
                                                variant="h4"
                                            >
                                                {smbInfo
                                                    .system_subscription_name
                                                    .length <= 50
                                                    ? smbInfo.system_subscription_name
                                                    : `${smbInfo.system_subscription_name.substring(
                                                          0,
                                                          50
                                                      )}...`}
                                            </Typography>
                                        </LightTooltip>
                                    )}
                            </CardContent>
                        </Box>
                    </CustomCard>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Integrations;
