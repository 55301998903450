/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import introJs from "intro.js";

/** MUI icons */
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Loading from "../../../assets/loading.png";
import DownloadIcon from "@mui/icons-material/Download";
import SyncIcon from "@mui/icons-material/Sync";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import checksService from "../../../services/checks";

/** MUI imports */
import { Box, Stack, Typography, Skeleton, IconButton, Button, Tabs, Tab } from "@mui/material";
import LightTooltip from "../../ui/LightTooltip";
import GridUI from "@mui/material/Grid";
import FiltersButtonChecks from "../FiltersButtonChecks";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import ChatIcon from "../../../assets/chat-icon.svg";
import BaseSnackbar from "../../ui/BaseSnackbar";
import SimpleBackdrop from "../../ui/SimpleBackdrop";
import { useDispatch, useSelector } from "react-redux";
import conversionService from "../../../services/conversion";
import casesService from "../../../services/case";
import { initiateSocket, newUserResponse, subscribeToChat } from "../../../services/Socket";
import ChatModal from "../../ui/Chat";
import { addTutorial } from "../../../redux/userSlice";
import SimpleDialog from "../../ui/SimpleDialog";
import BaseTable from "../BaseTable";
import { fetchFileFromS3, zeroPad } from "../../../utils/utils";

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        number: <Skeleton variant="text" animation="wave" />,
        account: <Skeleton variant="text" animation="wave" />,
        uploadDate: <Skeleton variant="text" animation="wave" />,
        check: <Skeleton variant="text" animation="wave" />,
        contact: <Skeleton variant="text" animation="wave" />,
        concept: <Skeleton variant="text" animation="wave" />,
        startDate: <Skeleton variant="text" animation="wave" />,
        balance: <Skeleton variant="text" animation="wave" />,
        user: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        chat: <Skeleton variant="text" animation="wave" />,
    });
}

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const TabProps = {
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 300,
    mr: 3,
    backgroundColor: "#f6f4fd",
    borderRadius: 3,
    minHeight: 35,
    height: 35,
    color: "#131F3E !important",
    "&.Mui-selected": {
        backgroundColor: "#c9bdf2",
        fontWeight: 600,
        borderRadius: 3,
    },
};

const ChecksTable = (props) => {
    const {
        checkConversions,
        accounts,
        usersFilters,
        company_id,
        setCheckConversions,
        franchise_id,
        grupal,
        showSkeleton,
        setShowSkeleton,
        setIsLoading,
        setFilters,
        filters,
        tutorial,
        setConvertDoneNew,
        setCorrelative,
        setIsLargeConv,
        setFormat,
        setConversionId,
        setFileNameSaved,
        setAccount,
        setShowPrev,
        setFileData,
        setFormatConv,
        setFileNameFull,
        setConversionData,
        setHTML,
        download,
        setDownload,
        setConvFormat,
        setAccountNum,
        setAccountType,
        setBankInst,
        setEndPoint,
        setAccountDet,
        finalQBOBanks,
        setGroupConversions,
        groupConversions,
        setOpenModalGroupedHist,
        setGroupedData,
        setGroupedPeriods,
        setOpenModalGrouped,
        setValue,
        value,
        handleShowPrevDownload,
        setModalType,
        integration,
        reloadChecks,
        setShowSync,
        columns,
        setColumns,
        tableColumnExtensions,
        setTableColumnExtensions,
        columns1,
        columns2,
        tableColumnExtensions1,
        tableColumnExtensions2,
        setRowsModal,
        setOpenPreview,
        setBasePreviewData,
        setRepeatedConv
    } = props;

    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch();
    const todos = useSelector((state) => state.value);

    const [searchValue, setSearchState] = useState("");
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [openBackdrop, setOpenBackdrop] = useState(false);
    const [rows, setRows] = useState([]);
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [status, setStatus] = useState();
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));
    const [caseInfo, setCaseInfo] = useState({});
    const [selectionAcc, setSelectionAcc] = useState([]);
    const [showModalReg, setShowModalReg] = useState(false);
    const controllerRef = useRef(null);

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const synChecks = async () => {
        const arraySync = [];

        selectionAcc.forEach((selectedRow) => {
            arraySync.push(rows[selectedRow]);
        });

        if (arraySync[0].grouped) {
            const groupedChecks = arraySync.map((item) => item.id)
            let params2 = { grouped_check_id: groupedChecks };
            await checksService
                .getGroupedCheckDetails(params2)
                .then(async (data) => {
                    const newData = data.groupedCheck.flatMap((item) => item.checks);
                    setConversionData({ checks: newData });

                    setShowSync(true);
                    setShowPrev(true);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            const singleChecks = arraySync.map((item) => item.id)
            let params2 = { check_id: singleChecks };
            await checksService
                .getCheckDetails(params2)
                .then(async (data) => {
                    setConversionData({ checks: data.check });

                    setShowSync(true);
                    setShowPrev(true);
                })
                .catch((err) => {
                    console.log(err);
                });

        }
    };

    const handleCloseRegModal = () => {
        setShowModalReg(false);
    };

    const handleRegister = async () => {
        setIsLoading(true);

        const arrayValidation = [];

        selectionAcc.forEach((selectedRow) => {
            arrayValidation.push(rows[selectedRow].id);
        });
        const params = {
            checks_id: arrayValidation,
        };

        await conversionService
            .markCheckAsRegistered(params)
            .then(async () => {
                setSelectionAcc([]);
                await reloadChecks();
            })
            .catch((err) => {
                console.log(err);
            });

        setShowModalReg(false);
        setIsLoading(false);
    };

    const markRegister = () => {
        setShowModalReg(true);
    };

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    useEffect(() => {
        if ((todos.steps.step8 || todos.steps.step9) && tutorial && download && !todos.dontShowAgain) {
            let number = "";

            if (todos.steps.step8) {
                number = "8";
            } else {
                number = "9";
            }
            setTimeout(() => {
                introJs()
                    .setOptions({
                        disableInteraction: true,
                        exitOnOverlayClick: false,
                        keyboardNavigation: false,
                        nextLabel: t("dialog.continue"),
                        prevLabel: t("miscellaneous.goBack"),
                        doneLabel: t("miscellaneous.accept"),
                        steps: [
                            {
                                title: `${t("miscellaneous.step")} ${number}`,
                                element: "#account8-tutorial",
                                intro: t("miscellaneous.tutorialConv5"),
                            },
                        ],
                    })
                    .onexit(() => {
                        if (todos.steps.step8) {
                            dispatch(addTutorial({ ...todos.steps, step8: false }));
                        } else {
                            dispatch(addTutorial({ ...todos.steps, step9: false }));
                        }
                        setDownload(false);
                    })
                    .start();
            }, 1000);
        }
    }, [dispatch, download, setDownload, t, todos.dontShowAgain, todos.steps, tutorial]);

    useEffect(() => {
        const handleShowPrev = async (convID, AccID, isGrouped) => {
            setOpenBackdrop(true);
            let converInfo = {};

            if (isGrouped) {
                let params2 = { grouped_check_id: convID };
                await checksService
                    .getGroupedCheckDetails(params2)
                    .then(async (data) => {
                        const newData = data.groupedCheck[0];
                        let statusSucc = 0;
                        let statusRev = 0;
                        let statusConv = 0;
                        let statusHeavy = 0;
                        let finalStatus = 0;

                        for (const value of newData.checks) {
                            if (value.id_status === 9) {
                                statusSucc++;
                            } else if (value.id_status === 2) {
                                statusRev++;
                            } else if (value.id_status === 1) {
                                statusHeavy++;
                            } else if (value.id_status === 3) {
                                statusConv++;
                            }
                        }

                        if (statusSucc > 0) {
                            finalStatus = 9;
                        } else if (statusRev > 0) {
                            finalStatus = 2;
                        } else if (statusHeavy > 0) {
                            finalStatus = 1;
                        } else if (statusConv > 0) {
                            finalStatus = 3;
                        } else {
                            finalStatus = 10;
                        }
                        newData.id_status = finalStatus;

                        setConversionData(newData);
                        setGroupedData(newData);
                        converInfo = newData;
                        setRepeatedConv(data.repeatedConversion)
                        setFileNameSaved("GROUP-" + zeroPad(newData.correlative ?? 0, 5));
                        const filteredConv = newData.checks.filter(
                            (item) => item.id_status === 3 || item.id_status === 4 || item.id_status === 9
                        );

                        // Ordena el arreglo por la fecha
                        const sortedFilteredConv = filteredConv.sort((a, b) => {
                            const dateA = moment.utc(a.formatted_date);
                            const dateB = moment.utc(b.formatted_date);
                            return dateA - dateB; // Orden ascendente (para descendente usa `dateB - dateA`)
                        });
                        setGroupedPeriods(sortedFilteredConv);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                setGroupedData(null);
                let params2 = { check_id: convID };
                await checksService
                    .getCheckDetails(params2)
                    .then(async (data) => {
                        setConversionData(data.check[0]);
                        setRepeatedConv(data.repeatedConversion)
                        converInfo = data.check[0];
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }

            if (!isGrouped) {
                setCorrelative(converInfo.correlative);
                setConversionId(converInfo._id);
                setFileNameSaved(converInfo.download_file_name.replace(/\.[^/.]+$/, ""));

                const data = await fetchFileFromS3(converInfo.uploaded_file_s3);

                if (!data) {
                    setTexto(t("converter.dnldError"));
                    setAlertType("error");
                    setShow(true);
                } else {
                    setFileData(data);
                }

                setFileNameFull(converInfo.download_file_name);

                setBasePreviewData({
                    id: convID,
                    type: "check"
                })

            } else {
                setCorrelative(converInfo.correlative);

                const data = await fetchFileFromS3(converInfo.checks[0].uploaded_file_s3);

                if (!data) {
                    setTexto(t("converter.dnldError"));
                    setAlertType("error");
                    setShow(true);
                } else {
                    setFileData(data);
                }

                setFileNameFull(converInfo.checks[0].download_file_name);

                setBasePreviewData({
                    id: converInfo.checks[0]._id,
                    type: "check"
                })
            }

            setAccount(AccID);
            setShowPrev(true);
            setOpenBackdrop(false);
        };

        const connectSocket = async (case_id, correlative, status) => {
            setRoom(case_id);
            initiateSocket(case_id, todos.userInfo, "check", correlative);
            subscribeToChat((err, data) => {
                if (err) return;
                setChat((oldChats) => [...oldChats, data]);
            });
            newUserResponse((err, data) => {
                if (err) return;
                setUsers(data);
            });
            setOpen(true);
        };

        if (showSkeleton) {
            setRows(rowsSkeleton);
        } else {
            const handleClickChat = async (statusConv, conversionID, correlative) => {
                setIsLoading(true);

                let params = {
                    company_id: todos.companyID,
                    franchise_id: todos.franchiseID,
                    user_email: todos.userInfo.email,
                    type: "conversion",
                    conversion_id: conversionID,
                    conversion_type: "check"
                };

                let caseInfo = null;

                await casesService
                    .getCaseInfo(params)
                    .then(async (response) => {
                        if (response) {
                            setCaseInfo(response.caseInfo);
                            caseInfo = response.caseInfo;
                            setStatus("");
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });

                if (!caseInfo) {
                    await casesService
                        .createNewCase(params)
                        .then(async () => {
                            await casesService
                                .getCaseInfo(params)
                                .then(async (data) => {
                                    setCaseInfo(data.caseInfo);
                                    caseInfo = data.caseInfo;
                                    setStatus(1);
                                })
                                .catch((err) => {
                                    console.log(err);
                                });
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                } else {
                    setStatus(caseInfo.case_reason);
                }

                await connectSocket(caseInfo._id, correlative, statusConv);

                setIsLoading(false);
            };

            const previewCheck = async (filename, id) => {

                setIsLoading(true);
                const data = await fetchFileFromS3(filename);
                setIsLoading(false);

                if (!data) {
                    setTexto(t("converter.dnldError"));
                    setAlertType("error");
                    setShow(true);
                } else {
                    setFileData(data);
                    setFileNameFull(filename);
                }

                setBasePreviewData({
                    id: id,
                    type: "check",
                })

                setOpenPreview(true);
            };

            const showConversions = (index) => {
                setModalType("check")
                setOpenModalGroupedHist(true);
                setOpenModalGrouped(true);
                let newRows = groupConversions[index].conversions.map((item) => ({
                    id: item._id,
                    check: item.check_number ?? "-",
                    contact: item.check_pay_to ?? "-",
                    date: item.formatted_date ? moment.utc(item.formatted_date).format(todos.dateFormat) : "-",
                    amount: item.formatted_amount
                        ? parseFloat(item.formatted_amount).toLocaleString(todos.amountFormat, {
                            minimumFractionDigits: 2,
                        })
                        : "-",
                    number: item.correlative,
                    file_name: item.uploaded_file,
                    file_name_s3: item.uploaded_file_s3,
                    period: "",
                    status: item.id_status,
                }));
                setRowsModal(newRows)
            };

            const successConv = checkConversions.filter((item) => item.id_status === 1).map((item) => item._id);
            const lastSuccess = successConv.length > 0 ? successConv[0] : null;

            if (checkConversions.length > 0) {
                // 1: En proceso
                // 2: En revisión
                // 3: Por sincronizar
                // 4: Por descargar
                // 5: Sincronizando
                // 6: Sincronizado
                // 7: Registro manual
                // 8: Sincronizado manual
                // 9: Descargado
                // 10: Inválida

                setRows(
                    checkConversions.map((item) => ({
                        id: item._id,
                        number:
                            item.correlative != null && item.correlative !== undefined
                                ? "#" + zeroPad(item.correlative, 5)
                                : "",
                        account: item.account_id.account_name,
                        uploadDate: moment.utc(item.current_date).format(todos.dateFormat),
                        check: item.check_number,
                        checkComponent: <Button onClick={() => previewCheck(item.uploaded_file_s3, item._id)}>{item.check_number}</Button>,
                        contact: item.check_pay_to,
                        concept: item.check_description,
                        balance: item.formatted_amount
                            ? parseFloat(item.formatted_amount).toLocaleString(todos.amountFormat, {
                                minimumFractionDigits: 2,
                            })
                            : "-",
                        startDate: item.formatted_date ? moment.utc(item.formatted_date).format(todos.dateFormat) : "-",
                        formatted_amount: item.formatted_amount,
                        formatted_date: item.formatted_date,
                        uploaded_file_s3: item.uploaded_file_s3,
                        user: item.user_name,
                        original_status: item.id_status,
                        grouped: false,
                        status: (
                            <Stack direction="row" alignItems="center">
                                {(item.id_status === 1 || item.id_status === 5) && (
                                    <img src={Loading} alt="loading" width={20} />
                                )}
                                {item.id_status === 2 && (
                                    <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                )}
                                {item.id_status === 3 && (
                                    <UploadFileIcon sx={{ color: "#4a22d4" }} fontSize="inherit" />
                                )}
                                {item.id_status === 4 && <DownloadIcon sx={{ color: "#4a22d4" }} fontSize="inherit" />}
                                {(item.id_status === 6 || item.id_status === 7) && (
                                    <UploadFileIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />
                                )}
                                {item.id_status === 8 && (
                                    <UploadFileIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                )}
                                {item.id_status === 9 && <DownloadIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />}
                                {item.id_status === 10 && <CloseIcon sx={{ color: "#DA0069" }} fontSize="inherit" />}
                                &nbsp;
                                <Typography>
                                    {item.id_status === 1
                                        ? t("converter.inProc")
                                        : item.id_status === 2
                                            ? t("converter.inReview")
                                            : item.id_status === 3
                                                ? t("converter.toBeSynch")
                                                : item.id_status === 4
                                                    ? t("converter.convertible")
                                                    : item.id_status === 5
                                                        ? t("converter.synchronizing")
                                                        : item.id_status === 6
                                                            ? t("converter.synchronized")
                                                            : item.id_status === 7
                                                                ? t("converter.manualReg")
                                                                : item.id_status === 8
                                                                    ? t("converter.partialSync")
                                                                    : item.id_status === 9
                                                                        ? t("converter.downloaded")
                                                                        : t("converter.invalid2")}
                                </Typography>
                            </Stack>
                        ),
                        chat:
                            item.id_status === 1 || item.id_status === 2 || item.id_status >= 5 ? (
                                <Stack direction="row" alignItems="center" spacing={1}>
                                    <LightTooltip title={t("converter.tooltipChat")} position="top">
                                        <IconButton
                                            onClick={() =>
                                                handleClickChat(
                                                    item.id_status,
                                                    item._id,
                                                    `#${zeroPad(item.correlative, 5)}`
                                                )
                                            }
                                            size="small"
                                            color="primary"
                                        >
                                            <Box component="img" src={ChatIcon} sx={{ width: 17 }} />
                                        </IconButton>
                                    </LightTooltip>
                                    {item.id_status >= 5 && item.id_status <= 9 && (
                                        <IconButton
                                            onClick={() => handleShowPrev(item._id, item.account_id._id, false)}
                                            size="small"
                                            color="primary"
                                            id={
                                                lastSuccess !== null && lastSuccess === item._id
                                                    ? "account8-tutorial"
                                                    : ""
                                            }
                                        >
                                            <DownloadIcon fontSize="small" />
                                        </IconButton>
                                    )}
                                </Stack>
                            ) : item.id_status === 3 || item.id_status === 4 ? (
                                <LightTooltip title={t("converter.checkDetail")}>
                                    <IconButton
                                        size="small"
                                        onClick={() => handleShowPrev(item._id, item.account_id._id, false)}
                                        color="primary"
                                    >
                                        <VisibilityIcon color="primary" sx={{ fontSize: 20 }} />
                                    </IconButton>
                                </LightTooltip>
                            ) : (
                                ""
                            ),
                    }))
                );
            } else if (groupConversions.length > 0) {
                setRows(
                    groupConversions.map((item, index) => {
                        const groupedPeriods = item.conversions.filter(
                            (item) => item.id_status === 3 || item.id_status === 4 || item.id_status === 9
                        );

                        // Ordena el arreglo por la fecha
                        const sortedFilteredConv = groupedPeriods.sort((a, b) => {
                            const dateA = moment.utc(a.formatted_date);
                            const dateB = moment.utc(b.formatted_date);
                            return dateA - dateB; // Orden ascendente (para descendente usa `dateB - dateA`)
                        });

                        // 1: En proceso
                        // 2: En revisión
                        // 3: Por sincronizar
                        // 4: Por descargar
                        // 5: Sincronizando
                        // 6: Sincronizado
                        // 7: Registro manual
                        // 8: Sincronizado parcial
                        // 9: Descargado
                        // 10: Inválida

                        let finalStatus = 0;
                        let partial = 0;
                        if (item.conversions.every((value) => value.id_status === 9 || value.id_status === 6 || value.id_status === 7)) { // Descargado, Sincronizado, Registro manual (Estatus final)
                            finalStatus = item.conversions[0].id_status;
                        } else if (item.conversions.every((value) => value.id_status === 10)) { // Inválida
                            finalStatus = 10
                        } else if (item.conversions.some((value) => value.id_status === 2)) {  // En revisión
                            finalStatus = 2
                            if (item.conversions.some((value) => value.id_status === 3 || value.id_status === 4 || value.id_status === 6 || value.id_status === 7 || value.id_status === 9)) {
                                partial = 1;
                            }
                        } else if (item.conversions.some((value) => value.id_status === 3)) { // Por sincronizar
                            if (item.conversions.some((value) => value.id_status === 6)) {
                                finalStatus = 8
                            } else {
                                finalStatus = 3
                            }
                        } else if (item.conversions.some((value) => value.id_status === 4)) { // Por descargar
                            finalStatus = 4
                        } else if (item.conversions.some((value) => value.id_status === 1)) { // En proceso
                            finalStatus = 1
                        } else if (item.conversions.some((value) => value.id_status === 5)) { // Sincronizando
                            finalStatus = 5
                        }

                        // Si el item completed_conversion es false, la conversión grupal se encuentra en proceso (alto volumen)
                        finalStatus = item?.completed_conversion ? finalStatus : 1;

                        return {
                            id: item._id,
                            number: (
                                <Button size="small" onClick={() => showConversions(index)}>
                                    {item.correlative != null && item.correlative !== undefined
                                        ? "GROUP-" + zeroPad(item.correlative, 5)
                                        : ""}
                                </Button>
                            ),
                            account: item.conversions[0].account_id.account_name,
                            uploadDate: moment.utc(item.current_date).format(todos.dateFormat),
                            startDate:
                                sortedFilteredConv.length === 0 || !sortedFilteredConv[0].formatted_date
                                    ? "-"
                                    : moment.utc(sortedFilteredConv[0].formatted_date).format(todos.dateFormat) +
                                    " - " +
                                    moment
                                        .utc(sortedFilteredConv[sortedFilteredConv.length - 1].formatted_date)
                                        .format(todos.dateFormat),
                            balance:
                                sortedFilteredConv.length === 0
                                    ? "-"
                                    : parseFloat(
                                        sortedFilteredConv.reduce((sum, item) => {
                                            return sum + item.formatted_amount;
                                        }, 0)
                                    ).toLocaleString(todos.amountFormat, {
                                        minimumFractionDigits: 2,
                                    }),
                            user: item.conversions[0].user_name,
                            original_status: finalStatus,
                            grouped: true,
                            status: (
                                <Stack direction="row" alignItems="center" justifyContent="center">
                                    {(finalStatus === 1 || finalStatus === 5) && (
                                        <img src={Loading} alt="loading" width={20} />
                                    )}
                                    {finalStatus === 2 && (
                                        <AccessTimeIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                    )}
                                    {finalStatus === 3 && (
                                        <UploadFileIcon sx={{ color: "#4a22d4" }} fontSize="inherit" />
                                    )}
                                    {finalStatus === 4 && <DownloadIcon sx={{ color: "#4a22d4" }} fontSize="inherit" />}
                                    {(finalStatus === 6 || finalStatus === 7) && (
                                        <UploadFileIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />
                                    )}
                                    {finalStatus === 8 && (
                                        <UploadFileIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                                    )}
                                    {finalStatus === 9 && <DownloadIcon sx={{ color: "#34bd6a" }} fontSize="inherit" />}
                                    {finalStatus === 10 && <CloseIcon sx={{ color: "#DA0069" }} fontSize="inherit" />}
                                    &nbsp;
                                    <Typography>
                                        {finalStatus === 1
                                            ? t("converter.inProc")
                                            : finalStatus === 2
                                                ? t("converter.inReview")
                                                : finalStatus === 3
                                                    ? t("converter.toBeSynch")
                                                    : finalStatus === 4
                                                        ? t("converter.convertible")
                                                        : finalStatus === 5
                                                            ? t("converter.synchronizing")
                                                            : finalStatus === 6
                                                                ? t("converter.synchronized")
                                                                : finalStatus === 7
                                                                    ? t("converter.manualReg")
                                                                    : finalStatus === 8
                                                                        ? t("converter.partialSync")
                                                                        : finalStatus === 9
                                                                            ? t("converter.downloaded")
                                                                            : t("converter.invalid2")}
                                    </Typography>
                                </Stack>
                            ),
                            chat:
                                finalStatus === 1 || finalStatus === 2 || finalStatus >= 5 ? (
                                    <Stack direction="row" alignItems="center" spacing={1}>
                                        {/* <LightTooltip title={t("converter.tooltipChat")} position="top">
                                            <IconButton
                                                onClick={() =>
                                                    handleClickChat(
                                                        finalStatus,
                                                        item._id,
                                                        `#${zeroPad(item.correlative, 5)}`
                                                    )
                                                }
                                                size="small"
                                                color="primary"
                                            >
                                                <Box component="img" src={ChatIcon} sx={{ width: 17 }} />
                                            </IconButton>
                                        </LightTooltip> */}
                                        {((finalStatus >= 5 && finalStatus <= 9) || partial === 1) && (
                                            <IconButton
                                                onClick={() =>
                                                    handleShowPrev(item._id, item.conversions[0].account_id._id, true)
                                                }
                                                size="small"
                                                color="primary"
                                                id={
                                                    lastSuccess !== null && lastSuccess === item._id
                                                        ? "account8-tutorial"
                                                        : ""
                                                }
                                            >
                                                <DownloadIcon fontSize="small" />
                                            </IconButton>
                                        )}
                                    </Stack>
                                ) : finalStatus === 3 || finalStatus === 4 ? (
                                    <IconButton
                                        onClick={() =>
                                            handleShowPrev(item._id, item.conversions[0].account_id._id, true)
                                        }
                                        size="small"
                                        color="primary"
                                    >
                                        <VisibilityIcon sx={{ fontSize: 20 }} />
                                    </IconButton>
                                ) : (
                                    ""
                                ),
                        };
                    })
                );
            } else {
                setRows([]);
            }
        }
    }, [
        checkConversions,
        finalQBOBanks,
        groupConversions,
        language,
        setAccount,
        setAccountDet,
        setAccountNum,
        setAccountType,
        setBankInst,
        setConvFormat,
        setConversionData,
        setConversionId,
        setConvertDoneNew,
        setCorrelative,
        setEndPoint,
        setFileData,
        setFileNameFull,
        setFileNameSaved,
        setFormat,
        setFormatConv,
        setGroupedData,
        setGroupedPeriods,
        setHTML,
        setIsLargeConv,
        setIsLoading,
        setOpenModalGrouped,
        setOpenModalGroupedHist,
        setShowPrev,
        showSkeleton,
        t,
        todos.amountFormat,
        todos.companyID,
        todos.dateFormat,
        todos.franchiseID,
        todos.userInfo,
        handleShowPrevDownload,
        setModalType,
        setRowsModal,
        setOpenPreview,
        setBasePreviewData,
        setRepeatedConv
    ]);

    const downloadReport = async () => {
        setIsLoading(true);

        let fileName = `CheckHistory-${moment().format("MMDDYYYY")}.csv`;

        let conversionIds = rows?.map((e) => e.id);

        if (selectionAcc?.length > 0) {
            let finalIds = [];
            conversionIds.forEach((e, i) => {
                if (selectionAcc.includes(i)) {
                    finalIds.push(e);
                }
            });

            conversionIds = finalIds;
        }

        conversionIds = conversionIds?.length > 0 ? conversionIds.join(",") : "";

        await checksService
            .downloadHistoryChecks({
                company_id: todos.companyID,
                file_name: fileName,
                conversionIds: conversionIds,
            })
            .then((response) => {
                // Crea un enlace temporal y haz clic en él para iniciar la descarga
                const link = document.createElement("a");
                link.href = response.url;
                document.body.appendChild(link);
                link.click();

                // Limpia el enlace temporal
                document.body.removeChild(link);
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
    };

    const attributes = [
        { id: 1, name: t("dashboard.bank"), query: "account_id_filter" },
        { id: 2, name: t("converter.uploadDate"), query: "uploaded" },
        //{ id: 3, name: t("converter.format"), query: "format" },
        //{ id: 4, name: t("converter.bankStatementDate"), query: "period" },
        { id: 5, name: t("converter.user"), query: "user_name" },
        { id: 6, name: t("converter.status"), query: "id_status" },
    ];

    const handleChangeTab = async (event, newValue) => {
        setShowSkeleton(true);
        if (newValue === 1) {
            setTableColumnExtensions(tableColumnExtensions2);
            setColumns(columns2);
        } else {
            setTableColumnExtensions(tableColumnExtensions1);
            setColumns(columns1);
        }
        setValue(newValue);
        let params = { company_id: todos.companyID /*account_id: account*/ };

        if (filters.filtersArray.filter((e) => e.type !== "")?.length > 0) {
            let array = [...filters.filtersArray];

            let objectFinal = {};

            array.forEach((item) => {
                if (item.value && item.type) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value);
                }
                if (item.type && item.since) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                        item.since.format("YYYY-MM-DD"));
                }
                if (item.type && item.until) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                        item.until.format("YYYY-MM-DD"));
                }
            });

            params = {
                ...params,
                ...objectFinal
            };

            if (params?.account_id) {
                params.account_id_filter = params.account_id;
                params.account_id = "";
            }
        }

        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        const controller = new AbortController();
        controllerRef.current = controller;

        const options = {
            signal: controllerRef.current.signal,
        };

        if (newValue === 1) {
            await conversionService
                .getGroupedCheckConversions(params, options)
                .then((data) => {
                    setCheckConversions([]);
                    setGroupConversions(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await conversionService
                .getCheckConversions(params, options)
                .then((response) => {
                    setGroupConversions([]);
                    setCheckConversions(response);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        controllerRef.current = null;
        setShowSkeleton(false);
    };

    return (
        <>
            <Box
                component="main"
                sx={{
                    mx: { xs: 2, md: 2 },
                    px: { xs: 2, md: 3 },
                    backgroundColor: "#F8F8F9",
                    pt: 3,
                    borderRadius: 2,
                }}
            >
                <GridUI container spacing={2} alignItems="center" justifyContent="space-between">
                    <GridUI item>
                        <Typography variant="h1">{t("converter.conversionHist")}</Typography>
                    </GridUI>
                    <GridUI item xs={12} md={6} lg={4}>
                        <GridUI container spacing={2} alignItems="center" justifyContent="flex-end">
                            <GridUI item xs={12} md={4} xl={3}>
                                <Button
                                    fullWidth
                                    onClick={markRegister}
                                    sx={{
                                        py: "12px",
                                    }}
                                    disabled={
                                        selectionAcc.length === 0 ||
                                        selectionAcc.some((item) => rows[item].original_status !== 3) ||
                                        (integration !== 1 && integration !== 2)
                                    }
                                >
                                    {t("converter.markRegister")}
                                </Button>
                            </GridUI>
                            <GridUI item xs={12} md={4} xl={3}>
                                <Button
                                    fullWidth
                                    onClick={downloadReport}
                                    disableElevation
                                    variant="outlined"
                                    endIcon={<DownloadIcon fontSize="inherit" />}
                                    sx={{
                                        py: "12px",
                                        backgroundColor: "white",
                                    }}
                                >
                                    {t("converter.download")}
                                </Button>
                            </GridUI>
                            <GridUI item xs={12} md={4} xl={3}>
                                <Button
                                    fullWidth
                                    onClick={synChecks}
                                    disableElevation
                                    variant="contained"
                                    endIcon={<SyncIcon fontSize="inherit" />}
                                    sx={{
                                        py: "12px",
                                    }}
                                    disabled={
                                        selectionAcc.length === 0 ||
                                        selectionAcc.some((item) => rows[item].original_status !== 3) ||
                                        (integration !== 1 && integration !== 2)
                                    }
                                >
                                    {t("converter.sync")}
                                </Button>
                            </GridUI>
                        </GridUI>
                    </GridUI>
                </GridUI>
                <GridUI container justifyContent="space-between" alignItems="end" spacing={2}>
                    <GridUI item sx={{ mb: { xs: 1, md: -8.3 }, zIndex: 1, mr: { xs: 0, md: 2 } }}>
                        <Tabs
                            orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                            aria-label="Horizontal tabs"
                            sx={{
                                p: 1,
                                mt: 2,
                            }}
                            TabIndicatorProps={{
                                style: { display: "none" },
                            }}
                            value={value}
                            onChange={handleChangeTab}
                        >
                            <Tab label={t("converter.singles")} {...a11yProps(0)} sx={TabProps} />
                            <Tab label={t("converter.groupedConvs")} {...a11yProps(1)} sx={TabProps} />
                        </Tabs>
                    </GridUI>
                    <GridUI item sx={{ mb: { xs: 1, md: -8.3 }, zIndex: 1, mr: { xs: 0, md: 37 } }}>
                        <FiltersButtonChecks
                            accounts={accounts}
                            setAccount={setAccount}
                            users={usersFilters}
                            company={company_id}
                            setConversions={setCheckConversions}
                            setShowSkeleton={setShowSkeleton}
                            franchise={franchise_id}
                            grupal={grupal}
                            setFilters={setFilters}
                            filters={filters}
                            setGroupConversions={setGroupConversions}
                            tabValue={value}
                        />
                    </GridUI>
                    <GridUI item xs={12}>
                        <BaseTable
                            rows={rows}
                            columns={columns}
                            selectionAcc={selectionAcc}
                            setSelectionAcc={setSelectionAcc}
                            searchValue={searchValue}
                            setSearchState={setSearchState}
                            tableColumnExtensions={tableColumnExtensions}
                        />
                    </GridUI>
                </GridUI>
            </Box>
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
            <SimpleBackdrop open={openBackdrop} />
            {open && <ChatModal
                room={room}
                setRoom={setRoom}
                open={open}
                setOpen={setOpen}
                setChat={setChat}
                chat={chat}
                type={status}
                users={users}
                franchiseName={
                    todos.allComData?.find(
                        (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                    )?.franchise_name
                }
                setCaseInfo={setCaseInfo}
                caseInfo={caseInfo}
            />}
            <SimpleDialog
                open={showModalReg}
                handleClose={handleCloseRegModal}
                maxWidth="sm"
                content={
                    <Box sx={{ px: 4 }}>
                        <Typography variant="h2" color="primary" sx={{ textAlign: "center", pb: 2 }}>
                            {t("converter.registerModalTitle")}
                        </Typography>
                        <Typography sx={{ pb: 2 }}>{t("converter.registerModalContent")}</Typography>

                        <Stack direction="row" justifyContent="space-evenly" alignItems="center" spacing={2}>
                            <Button onClick={handleCloseRegModal}>{t("register.back")}</Button>
                            <Button variant="contained" disableElevation onClick={handleRegister}>
                                {t("subscription.confirm")}
                            </Button>
                        </Stack>
                    </Box>
                }
            />
        </>
    );
};

export default ChecksTable;
