/* Componentes */
import { useEffect, useState } from "react";
import BackGeneral from "../components/layout/BackGeneral";
import HistoryList from "../components/team/HistoryList";
import InvitationsList from "../components/team/InvitationsList";
import TeamHeader from "../components/team/TeamHeader";
import TeamList from "../components/team/TeamList";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import BaseSnackbar from "../components/ui/BaseSnackbar";
import teamService from "../services/team";
import subscriptionService from "../services/subscription";
import { useSelector } from "react-redux";

const Team = () => {
    const todos = useSelector((state) => state.value);

    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [invitations, setInvitations] = useState([]);
    const [invitationsOG, setOGInvitations] = useState([]);
    const [historyInvitations, setHistoryInvitations] = useState([]);
    const [historyInvitationsOG, setHistoryInvitationsOG] = useState([]);
    const [rol, setRol] = useState(0);
    const [company, setCompany] = useState(null);
    const [franchise, setFranchise] = useState(null);
    const [reload, setReload] = useState(false);
    // const [checked, setChecked] = useState(true);
    // const [isDisabled, setIsDisabled] = useState(false);
    const [usersCounter, setUsersCounter] = useState(0); // Cantidad de usuarios que tienen invite_status en 1 y 2.

    const hasAccess = rol === 1 || rol === 2;
    const companyID = todos.companyID ?? null;
    const franchiseID = todos.franchiseID ?? null;
    const [subscription, setSubscription] = useState(null);

    /** Component functions */
    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    useEffect(() => {
        (async () => {
            setIsLoading(true);

            const params = {
                userID: todos.userInfo._id,
                franchiseID: franchiseID,
                companyID: companyID,
            };

            if (companyID) {
                const item = todos.allComData?.find((item) => item._id === companyID);

                setCompany(item);
            }

            const item = todos.allComData?.find((item) => item._id === franchiseID);
            setFranchise(item);

            await teamService
                .getInvitations(params)
                .then((data) => {
                    let counterUsers = 0;

                    if (!todos.companyID) {
                        counterUsers = data.invitations.filter(
                            (item) => item.invite_status === 1 || item.invite_status === 2
                        ).length;
                    } else {
                        counterUsers = data.invitations.filter(
                            (item) => item.invite_status === 1 || item.invite_status === 2 || item.invite_status === 4
                        ).length;
                    }

                    // console.log("data", data);

                    setInvitations(data.invitations);
                    setOGInvitations(data.invitations);
                    setHistoryInvitations(data.history_invitations);
                    setHistoryInvitationsOG(data.history_invitations);
                    setUsersCounter(counterUsers);
                })
                .catch((error) => {
                    console.log(error);
                });

            await teamService
                .getUserCompanyDetails(params)
                .then((data) => {
                    // console.log("data", data);
                    setRol(data.user_permission);
                })
                .catch((error) => {
                    console.log(error);
                });

            setIsLoading(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    useEffect(() => {
        (async () => {
            const params = {
                company_id: todos.companyID,
            };

            await subscriptionService
                .getSubscription(params)
                .then((response) => {
                    setSubscription(response);
                })
                .catch((error) => {
                    console.log(error);
                });
        })();
    }, [todos.companyID]);

    return (
        <BackGeneral colorBg="#f7f8f9">
            <TeamHeader
                franchiseID={todos?.franchiseID ?? ""}
                rol={rol}
                setIsLoading={setIsLoading}
                setShow={setShow}
                setTexto={setTexto}
                setAlertType={setAlertType}
                companyID={todos?.companyID ?? ""}
                franchise={franchise}
                company={company}
                setReload={setReload}
                setInvitations={setInvitations}
                allInvitations={invitationsOG}
                historyInvitationsOG={historyInvitationsOG}
                setHistoryInvitations={setHistoryInvitations}
                usersCounter={usersCounter}
                subscription={subscription}
                hasAccess={hasAccess}
                setCompany={setCompany}
            >
                <TeamList
                    franchiseID={franchiseID}
                    setIsLoading={setIsLoading}
                    setShow={setShow}
                    setTexto={setTexto}
                    setAlertType={setAlertType}
                    invitations={invitations}
                    hasAccess={hasAccess}
                    companyName={franchise?.franchise_name}
                    setReload={setReload}
                    subscription={subscription}
                />
                {hasAccess && (
                    <>
                        <InvitationsList
                            invitations={invitations}
                            setIsLoading={setIsLoading}
                            setReload={setReload}
                            setShow={setShow}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            usersCounter={usersCounter}
                            subscription={subscription}
                        />
                        <HistoryList
                            setIsLoading={setIsLoading}
                            setShow={setShow}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setReload={setReload}
                            invitations={historyInvitations}
                            usersCounter={usersCounter}
                            subscription={subscription}
                        />
                    </>
                )}
            </TeamHeader>
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleClose} />
            <SimpleBackdrop open={isLoading} />
        </BackGeneral>
    );
};

export default Team;
