import {
    Box,
    Button,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    Stack,
    Switch,
    Typography,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableBody,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";

import WarningIcon from "@mui/icons-material/Warning";
import DownloadIcon from "@mui/icons-material/Download";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import SyncIcon from "@mui/icons-material/Sync";
import InfoIcon from "@mui/icons-material/Info";
import SearchIcon from "@mui/icons-material/Search";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CloseIcon from "@mui/icons-material/Close";
import HelpIcon from "@mui/icons-material/Help";
import Loading from "../../../assets/loading-gray.png";

import { pdfjs } from "react-pdf";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import classes from "../MainConverter.module.css";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import BaseSelect from "../../ui/BaseSelect";
import LightTooltip from "../../ui/LightTooltip";
import SimpleDialog from "../../ui/SimpleDialog";
import ChecksSync from "./ChecksSync";
import moment from "moment";
import { fetchFileFromS3, zeroPad } from "../../../utils/utils";
import BaseActionsPreviewFile from "../BaseActionsPreviewFile";
import ChecksSyncDetails from "./ChecksSyncDetails";

// This line is important for the correct functioning of the library
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.03)",
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const ChecksPreview = (props) => {
    const {
        handleClickDownload,
        removeBanner,
        account,
        accounts,
        correlative,
        handleClickHelp,
        fileData,
        conversionData,
        checkedInvSigngs,
        setCheckedInvSigngs,
        checkedInvDay,
        setCheckedInvDay,
        isHistory,
        groupedData,
        setIsLoading,
        setOpenModalGrouped,
        setRowsModal,
        setOpenModalGroupedHist,
        setModalType,
        fileNameSaved,
        setShowSync,
        showSync,
        groupedPeriods,
        setFileData,
        setFileNameFull,
        setOpenPreview,
        showMatchBankModal,
        handleCloseMatchBankModal,
        matchBank,
        setShowMatchBankModal,
        reloadChecks,
        setRows,
        setBasePreviewData,
        basePreviewData,
        setShowSyncDetails,
        showSyncDetails,
        setOpenHeavy,
        repeatedConv,
        setRepeatedConv
    } = props;

    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);

    const [rowsConv, setRowsConv] = useState([]);
    const [accountPrev, setAccountPrev] = useState(0);
    const [conversionDataNew, setConversionDataNew] = useState(groupedData ?? conversionData);
    const [conversionFile, setConversionFile] = useState(fileData);
    const [actualDocIndex, setActualDocIndex] = useState(-1);
    const [finalDateFormat, setFinalDateFormat] = useState(todos.dateFormat);
    const [detailsHist, setDetailsHist] = useState([]);
    const [checksStatus, setChecksStatus] = useState({
        'success': 0,
        'failed': 0,
        'total': 0
    });

    // 1: En proceso, 2: En revisión, 3: Por sincronizar, 4: Por descargar, 5: Sincronizando, 6: Sincronizado, 7: Registro manual, 8: Sincronizado manual, 9: Descargado, 10: Inválida

    const success = groupedData?.checks.filter(
        (item) => item.id_status === 3 || item.id_status === 4 || item.id_status === 6 || item.id_status === 9
    ).length;

    const revision = groupedData?.checks.filter((item) => item.id_status === 2).length;

    const errConv = groupedData?.checks.filter((item) => item.id_status === 10).length;

    const handleGoBack = () => {
        removeBanner();
        setRows([]);
        setRowsModal([]);
        setRepeatedConv([]);
        setDetailsHist([]);
        setShowSyncDetails(false)
        setShowSync(false);
        setChecksStatus({
            'success': 0,
            'failed': 0,
            'total': 0
        })
        reloadChecks()
    };

    const handleSync = () => {
        if (groupedData) {
            setConversionDataNew(groupedData ?? conversionData);
        }
        setShowSync(true)
    };

    const handleChange = async (event) => {
        let newData = groupedData;
        let finalIndex;
        if (event.target.value !== 0) {
            finalIndex =  groupedData.checks.findIndex(item => item._id === event.target.value)
            newData = groupedData.checks.find((item) => item._id === event.target.value);
        } else {
            finalIndex = -1;
            newData = groupedData.checks[0];
        }

        setActualDocIndex(finalIndex);

        setIsLoading(true);

        await getFile(newData.uploaded_file_s3)

        setIsLoading(false);

        setBasePreviewData({
            id: newData._id,
            type: "check"
        })

        if (event.target.value !== 0) {
            setConversionDataNew(newData);
            const newRows = [newData].map((item) => {
                let amount;
                let dateFormat = todos.dateFormat;
    
                if (item.formatted_amount < 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                } else if (item.formatted_amount > 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                }
    
                if (checkedInvDay) {
                    dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                }
    
                return {
                    id: item._id,
                    check: item.check_number ? item.check_number : "-",
                    contact: item.check_pay_to ? item.check_pay_to : "-",
                    concept: item.check_description ? item.check_description : "-",
                    date: item.formatted_date ? moment.utc(item.formatted_date).format(dateFormat) : "-",
                    amount: amount
                        ? parseFloat(amount).toLocaleString(todos.amountFormat, {
                            minimumFractionDigits: 2,
                        })
                        : "-",
                };
            });
            setRowsConv(newRows);
        } else {
            setConversionDataNew(groupedData);
            const newRows = groupedData.checks.map((item) => {
                let amount;
                let dateFormat = todos.dateFormat;
    
                if (item.formatted_amount < 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                } else if (item.formatted_amount > 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                }
    
                if (checkedInvDay) {
                    dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                }
    
                return {
                    id: item._id,
                    check: item.check_number ? item.check_number : "-",
                    contact: item.check_pay_to ? item.check_pay_to : "-",
                    concept: item.check_description ? item.check_description : "-",
                    date: item.formatted_date ? moment.utc(item.formatted_date).format(dateFormat) : "-",
                    amount: amount
                        ? parseFloat(amount).toLocaleString(todos.amountFormat, {
                            minimumFractionDigits: 2,
                        })
                        : "-",
                };
            });
            setRowsConv(newRows);
        }
        
        setAccountPrev(event.target.value);
    };

    const accountsPrev = groupedData
    ? [{ id: 0, name: fileNameSaved }].concat(
        groupedData.checks.map((item) => ({
            id: item._id,
            name: "#" + zeroPad(item.correlative, 5) + " - " + (item?.check_number ?? "N/A"),
        }))
    )
    : [];

    const columns = [
        { name: "check", title: t("converter.check"), align: "left", minWidth: 160 },
        { name: "contact", title: t("converter.contact"), align: "left" },
        { name: "concept", title: t("payment.concept"), align: "left" },
        { name: "date", title: t("payment.date"), align: "center" },
        { name: "amount", title: t("credits.amount"), align: "right" },
    ];

    const handleChangeSwitchInv = (event) => {
        setCheckedInvSigngs(event.target.checked);
    };

    const handleChangeSwitchInvDay = (event) => {
        setCheckedInvDay(event.target.checked);
        setFinalDateFormat(finalDateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY");
    };

    useEffect(() => {
        if (conversionDataNew.checks) {
            let convRows = conversionDataNew.checks
            if (rowsConv.length > 0) {
                convRows = conversionDataNew.checks.filter((item) => rowsConv.map((row) => row.id).includes(item._id) )
            }
            const newRows = convRows.map((item) => {
                let amount;
                let dateFormat = todos.dateFormat;

                if (item.formatted_amount < 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                } else if (item.formatted_amount > 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                }

                if (checkedInvDay) {
                    dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                }

                return {
                    id: item._id,
                    check: item.check_number ? item.check_number : "-",
                    contact: item.check_pay_to ? item.check_pay_to : "-",
                    concept: item.check_description ? item.check_description : "-",
                    date: item.formatted_date ? moment.utc(item.formatted_date).format(dateFormat) : "-",
                    amount: amount
                        ? parseFloat(amount).toLocaleString(todos.amountFormat, {
                            minimumFractionDigits: 2,
                        })
                        : "-",
                };
            });
            setRowsConv(newRows);
        } else {
            let convRows = [conversionDataNew]
            const newRows = convRows.map((item) => {
                let amount;
                let dateFormat = todos.dateFormat;

                if (item.formatted_amount < 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                } else if (item.formatted_amount > 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                }

                if (checkedInvDay) {
                    dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                }

                return {
                    id: item._id,
                    check: item.check_number ? item.check_number : "-",
                    contact: item.check_pay_to ? item.check_pay_to : "-",
                    concept: item.check_description ? item.check_description : "-",
                    date: item.formatted_date ? moment.utc(item.formatted_date).format(dateFormat) : "-",
                    amount: amount
                        ? parseFloat(amount).toLocaleString(todos.amountFormat, {
                            minimumFractionDigits: 2,
                        })
                        : "-",
                };
            });
            setRowsConv(newRows);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkedInvDay, checkedInvSigngs, t, todos.dateFormat, todos.amountFormat, conversionDataNew]);
    

    useEffect(() => {
        setCheckedInvSigngs(conversionDataNew.switch_signs ?? false);
        setCheckedInvDay(conversionDataNew.switch_day_month ?? false);
    }, [conversionDataNew.switch_day_month, conversionDataNew.switch_signs, setCheckedInvDay, setCheckedInvSigngs]);

    const getFile = async (file_name) => {
        const data = await fetchFileFromS3(file_name);
        if (data) {
            setConversionFile(data);
        }
    }

    const handleNextDoc = async (direction) => {
        setIsLoading(true);
        let newData = {}
        let finalAcc;
        if (actualDocIndex + direction === -1) {
            newData = groupedData.checks[0];
            finalAcc = 0;
            setConversionDataNew(groupedData);
        } else {
            newData = groupedData.checks[actualDocIndex + direction];
            finalAcc = newData._id
            setConversionDataNew(newData);
        }
        
        setActualDocIndex(actualDocIndex + direction);

        await getFile(newData.uploaded_file_s3)

        setBasePreviewData({
            id: newData._id,
            type: "check"
        })

        if (actualDocIndex + direction !== -1) {
            setConversionDataNew(newData);
            const newRows = [newData].map((item) => {
                let amount;
                let dateFormat = todos.dateFormat;
    
                if (item.formatted_amount < 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                } else if (item.formatted_amount > 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                }
    
                if (checkedInvDay) {
                    dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                }
    
                return {
                    id: item._id,
                    check: item.check_number ? item.check_number : "-",
                    contact: item.check_pay_to ? item.check_pay_to : "-",
                    concept: item.check_description ? item.check_description : "-",
                    date: item.formatted_date ? moment.utc(item.formatted_date).format(dateFormat) : "-",
                    amount: amount
                        ? parseFloat(amount).toLocaleString(todos.amountFormat, {
                            minimumFractionDigits: 2,
                        })
                        : "-",
                };
            });
            setRowsConv(newRows);
        } else {
            setConversionDataNew(groupedData);
            const newRows = groupedData.checks.map((item) => {
                let amount;
                let dateFormat = todos.dateFormat;
    
                if (item.formatted_amount < 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                } else if (item.formatted_amount > 0) {
                    if (checkedInvSigngs) {
                        amount = -1 * item.formatted_amount;
                    } else {
                        amount = item.formatted_amount;
                    }
                }
    
                if (checkedInvDay) {
                    dateFormat = todos.dateFormat === "MM/DD/YYYY" ? "DD/MM/YYYY" : "MM/DD/YYYY";
                }
    
                return {
                    id: item._id,
                    check: item.check_number ? item.check_number : "-",
                    contact: item.check_pay_to ? item.check_pay_to : "-",
                    concept: item.check_description ? item.check_description : "-",
                    date: item.formatted_date ? moment.utc(item.formatted_date).format(dateFormat) : "-",
                    amount: amount
                        ? parseFloat(amount).toLocaleString(todos.amountFormat, {
                            minimumFractionDigits: 2,
                        })
                        : "-",
                };
            });
            setRowsConv(newRows);
        }
        setAccountPrev(finalAcc);

        setIsLoading(false);
    };

    const showConversions = () => {
        setModalType("check");
        setOpenModalGrouped(true);
        setOpenModalGroupedHist(true);
        const newRows = groupedData.checks.map((item) => ({
            id: item._id,
            check: item.check_number ?? "-",
            contact: item.check_pay_to ?? "-",
            date: item.formatted_date ? moment.utc(item.formatted_date).format(todos.dateFormat) : "-",
            amount: item.formatted_amount ? parseFloat(item.formatted_amount).toLocaleString(todos.amountFormat, {
                minimumFractionDigits: 2,
            }) : "-",
            number: item.correlative,
            file_name: item.uploaded_file,
            file_name_s3: item.uploaded_file_s3,
            period: "",
            status: item.id_status,
        }));
        setRowsModal(newRows);
    };

    useEffect(() => {
        if (matchBank === 2) {
            setShowMatchBankModal(true);
        }
    }, [matchBank, setShowMatchBankModal]);

    const getPeriods = () => {

        if (accountPrev === 0 && groupedData) {
            let periodsDateFilter = groupedPeriods.filter((item) => item.formatted_date !== null)
            if (periodsDateFilter.length > 1) {
                return `${moment
                    .utc(periodsDateFilter[0].formatted_date)
                    .format(finalDateFormat)} - ${moment
                        .utc(periodsDateFilter[periodsDateFilter.length - 1].formatted_date)
                        .format(finalDateFormat)}`
            } else if (periodsDateFilter.length === 1) {
                return `${moment
                    .utc(periodsDateFilter[0].formatted_date)
                    .format(finalDateFormat)} - ${moment
                        .utc(periodsDateFilter[0].formatted_date)
                        .format(finalDateFormat)}`
            } else {
                return "N/A"
            }
        } else {
            return `${moment
                .utc(conversionDataNew.formatted_date)
                .format(finalDateFormat)} - ${moment
                    .utc(conversionDataNew.formatted_date)
                    .format(finalDateFormat)}`
        }
    }

    const previewCheck = async (filename, id) => {
        setIsLoading(true);
        const data = await fetchFileFromS3(filename);
        setIsLoading(false);

        if (data) {
            setFileData(data);
            setFileNameFull(filename);
        }

        setBasePreviewData({
            id: id,
            type: "check",
        });
        setOpenPreview(true);
    };

    return (
        <Container maxWidth="xl" sx={{ backgroundColor: "#F7F8FA" }}>
            <Stack direction="row" justifyContent="space-between" pt={5}>
                <Button variant="outlined" onClick={handleGoBack}>
                    {isHistory ? t("inbox.goBackList") : t("converter.goBackConver")}
                </Button>
                {(!groupedData || (groupedData && accountPrev !== 0)) && (
                    <Typography gutterBottom>
                        <Button onClick={() => handleClickHelp("conversion", "check", conversionDataNew._id)}>{t("accounts.needHelp")}</Button>
                    </Typography>
                )}
            </Stack>
            {showSync ? (
                <ChecksSync
                    setIsLoading={setIsLoading}
                    checksData={conversionDataNew}
                    downloadChecks={handleClickDownload}
                    handleGoBack={handleGoBack}
                    setFileData={setFileData}
                    setFileNameFull={setFileNameFull}
                    setOpenPreview={setOpenPreview}
                    setBasePreviewData={setBasePreviewData}
                    setChecksStatus={setChecksStatus}
                    setDetailsHist={setDetailsHist}
                    setShowSyncDetails={setShowSyncDetails}
                    setShowSync={setShowSync}
                    previewCheck={previewCheck}
                    setOpenHeavy={setOpenHeavy}
                />
            ) : showSyncDetails ? (
                <ChecksSyncDetails
                    detailsHist={detailsHist}
                    checksStatus={checksStatus}
                    handleGoBack={handleGoBack}
                    previewCheck={previewCheck}
                />
            ) : (
                <>
                    <Typography variant="h1" pt={2} gutterBottom ml={0.5}>
                        {`${accounts.find((item) => item.id === account)?.name} > `}
                        <Typography variant="h1" component="span" color="primary" >
                            {groupedData 
                                ? "GROUP-" + zeroPad(groupedData.correlative ?? 0, 5)
                                : `${t("subscription.conversion")} #${zeroPad(correlative, 5)}`}
                        </Typography>
                    </Typography>
                    {groupedData && (
                        <Grid container spacing={3} mt={0.2}>
                            <Grid item xs={12} md={5}>
                                <Stack
                                    direction="row"
                                    spacing={2}
                                    justifyContent="space-between"
                                    alignItems="center"
                                    sx={{
                                        backgroundColor: "white",
                                        p: 2,
                                        borderRadius: 4,
                                        boxShadow: "4px 4px 10px rgba(74, 34, 212, 0.1)",
                                    }}
                                >
                                    <Box sx={{ width: 250 }}>
                                        <BaseSelect
                                            value={accountPrev}
                                            values={accountsPrev}
                                            onChange={handleChange}
                                            label={t("converter.check")}
                                        ></BaseSelect>
                                    </Box>
                                    <Stack justifyContent="end">
                                        <Typography variant="h2" textAlign="end">
                                            {groupedData.checks.length} {t("converter.checks")}
                                        </Typography>
                                        <Typography>
                                            #{zeroPad(Math.min(...groupedData.checks.map((item) => item.correlative)), 5)} - #
                                            {zeroPad(Math.max(...groupedData.checks.map((item) => item.correlative)), 5)}
                                        </Typography>
                                    </Stack>
                                </Stack>
                            </Grid>
                            <Grid item xs={12} md={5}>
                                <Box className={classes.backWhiteDet}>
                                    <Stack direction="row" spacing={2} justifyContent="space-between">
                                        <Box>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography variant="h2" py={revision > 0 || errConv > 0 ? 0 : 0.5}>
                                                    {t("converter.conversionStatus")}
                                                </Typography>
                                                {(revision > 0 || errConv > 0) && (
                                                    <LightTooltip title={t("miscellaneous.toolHelpConvDet")}>
                                                        <HelpIcon fontSize="small" color="primary" />
                                                    </LightTooltip>
                                                )}
                                            </Stack>
                                            <Grid container spacing={2} sx={{ pl: 2 }}>
                                                {success > 0 && (
                                                    <Grid item>
                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                            <Typography>{success}</Typography>
                                                            <CheckIcon fontSize="small" color="success" />
                                                        </Stack>
                                                    </Grid>
                                                )}
                                                {revision > 0 && (
                                                    <Grid item>
                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                            <Typography>{revision}</Typography>
                                                            <AccessTimeIcon fontSize="small" color="warning" />
                                                        </Stack>
                                                    </Grid>
                                                )}
                                                {errConv > 0 && (
                                                    <Grid item>
                                                        <Stack direction="row" alignItems="center" spacing={1}>
                                                            <Typography>{errConv}</Typography>
                                                            <CloseIcon fontSize="small" color="error" />
                                                        </Stack>
                                                    </Grid>
                                                )}
                                            </Grid>
                                        </Box>
                                        <Button
                                            variant="outlined"
                                            endIcon={<SearchIcon style={{ width: "27px", height: "27px" }} />}
                                            onClick={showConversions}
                                            sx={{
                                                py: 1.4,
                                                width: 180,
                                                justifyContent: "space-between",
                                                backgroundColor: "#F6F4FD",
                                                "&:hover": {
                                                    backgroundColor: "#E5E5E5",
                                                },
                                                "&:disabled": {
                                                    color: "#FFF",
                                                    backgroundColor: "rgba(189, 189, 189, 1)",
                                                },
                                            }}
                                        >
                                            {repeatedConv.length > 0 ? 
                                            <LightTooltip
                                                title={t("converter.repeatedConvCheckTooltip")}
                                            >
                                                <WarningIcon fontSize="16px" sx={{ color: "#FFB23F" }} />
                                            </LightTooltip> : ""} {t("converter.seeDetails")}
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    )}
                    <Grid container spacing={6} pb={3}>
                        <Grid item xs={12} md={5}>
                            {(conversionFile || groupedData) && (
                                <Box
                                    sx={{
                                        height: 650,
                                        overflow: "auto",
                                        "&::-webkit-scrollbar": {
                                            width: "10px",
                                            height: "10px",
                                        },
                                        "&::-webkit-scrollbar-track": {
                                            boxShadow: "inset 10px 10px 15px #c7c9d1",
                                            borderRadius: "10px",
                                        },
                                        "&::-webkit-scrollbar-thumb": {
                                            background: "#404963",
                                            borderRadius: "10px",
                                        },
                                        "&::-webkit-scrollbar-thumb:hover": {
                                            background: "#031851",
                                        },
                                    }}
                                >
                                    <Box
                                        className={classes.workbookContainer}
                                        sx={{ bgcolor: "#CCC" }}
                                        justifyContent="center"
                                        display="flex"
                                        alignItems="center"
                                    >
                                        <Box
                                            sx={{ width: "100%", height: "auto" }}
                                            component="img"
                                            src={conversionFile}
                                        ></Box>
                                    </Box>
                                    <Box sx={{ bgcolor: "#FFF", borderRadius: 2, display: "flex", justifyContent: "end", px: 2 }}>
                                        <BaseActionsPreviewFile basePreviewData={basePreviewData} />
                                    </Box>

                                    {groupedData && (
                                        <Stack direction="row" spacing={1} justifyContent="center">
                                            <Button
                                                endIcon={<ArrowBackIcon />}
                                                sx={{ color: actualDocIndex === -1 ? "rgba(0, 0, 0, 0.26)" : "#131f3e" }}
                                                disabled={actualDocIndex === -1}
                                                onClick={() => handleNextDoc(-1)}
                                            >
                                                {t("converter.previous")}
                                            </Button>
                                            <Button
                                                startIcon={<ArrowForwardIcon />}
                                                sx={{
                                                    color:
                                                        actualDocIndex === groupedData.checks.length - 1
                                                            ? "rgba(0, 0, 0, 0.26)"
                                                            : "#131f3e",
                                                }}
                                                disabled={actualDocIndex === groupedData.checks.length - 1}
                                                onClick={() => handleNextDoc(1)}
                                            >
                                                {t("miscellaneous.next")}
                                            </Button>
                                        </Stack>
                                    )}
                                </Box>
                            )}
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid container spacing={2} sx={{ pt: groupedData ? 2 : 0 }}>
                                <Grid item xs={12} md={6} justifyContent="center">
                                    <Typography variant="h1" color="primary" gutterBottom>
                                        {t("converter.resumeConv")}
                                    </Typography>
                                    {(!groupedData || (groupedData && accountPrev !== 0)) && (
                                        <Typography gutterBottom>
                                            <b>{t("converter.check")}:</b> {conversionDataNew.check_number ?? "-"} {repeatedConv.length > 0 ? 
                                            <LightTooltip
                                                title={t("converter.repeatedConvCheck1") + " " + repeatedConv.map((item) => "#" + zeroPad(item.correlative, 5)).join(", ") + ". " + t("converter.repeatedConvCheck2")}
                                            >
                                                <WarningIcon fontSize="16px" sx={{ color: "#FFB23F" }} />
                                            </LightTooltip>  : ""}
                                        </Typography>
                                    )}
                                    {(!groupedData || (groupedData && accountPrev !== 0)) && (
                                        <Typography gutterBottom>
                                            <b>{t("converter.contact")}:</b> {conversionDataNew.check_pay_to ?? "-"}
                                        </Typography>
                                    )}
                                    {(!groupedData || (groupedData && accountPrev !== 0)) && (
                                        <Typography gutterBottom>
                                            <b>{t("payment.concept")}:</b> {conversionDataNew.check_description ?? "-"}
                                        </Typography>
                                    )}
                                    {(!groupedData || (groupedData && accountPrev !== 0)) && (
                                        <Typography gutterBottom>
                                            <b>{t("payment.date")}:</b>{" "}
                                            {conversionDataNew.formatted_date
                                                ? moment.utc(conversionDataNew.formatted_date).format(finalDateFormat)
                                                : "-"}
                                        </Typography>
                                    )}
                                    {(!groupedData || (groupedData && accountPrev !== 0)) && (
                                        <Typography gutterBottom>
                                            <b>{t("credits.amount")}:</b>{" "}
                                            {conversionDataNew.formatted_amount
                                                ? checkedInvSigngs
                                                    ? parseFloat(-1 * conversionDataNew.formatted_amount).toLocaleString(
                                                        todos.amountFormat,
                                                        {
                                                            minimumFractionDigits: 2,
                                                        }
                                                    )
                                                    : parseFloat(conversionDataNew.formatted_amount).toLocaleString(
                                                        todos.amountFormat,
                                                        {
                                                            minimumFractionDigits: 2,
                                                        }
                                                    )
                                                : "-"}
                                        </Typography>
                                    )}
                                    {groupedData && accountPrev === 0 && (
                                        <Typography gutterBottom mt={2}>
                                            <b>{t("payment.period")}:</b>{" "}
                                            {getPeriods()}
                                        </Typography>
                                    )}
                                    {groupedData && accountPrev === 0 && (
                                        <Typography gutterBottom mt={1.5}>
                                            <b>{t("converter.totalAmount")}:</b>{" "}
                                            {accountPrev === 0 && groupedData
                                                ? checkedInvSigngs ?
                                                    parseFloat(
                                                        -1 * groupedPeriods.reduce((sum, item) => {
                                                            return sum + item.formatted_amount;
                                                        }, 0)
                                                    ).toLocaleString(todos.amountFormat, {
                                                        minimumFractionDigits: 2,
                                                    }) :
                                                    parseFloat(
                                                        groupedPeriods.reduce((sum, item) => {
                                                            return sum + item.formatted_amount;
                                                        }, 0)
                                                    ).toLocaleString(todos.amountFormat, {
                                                        minimumFractionDigits: 2,
                                                    })
                                                : checkedInvSigngs ?
                                                    parseFloat(-1 * conversionDataNew.formatted_amount).toLocaleString(
                                                        todos.amountFormat,
                                                        {
                                                            minimumFractionDigits: 2,
                                                        }
                                                    ) :
                                                    parseFloat(conversionDataNew.formatted_amount).toLocaleString(
                                                        todos.amountFormat,
                                                        {
                                                            minimumFractionDigits: 2,
                                                        }
                                                    )}
                                        </Typography>
                                    )}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <Typography variant="h1" color="primary" gutterBottom>
                                        {t("converter.transform")}
                                    </Typography>
                                    <LightTooltip
                                        title={groupedData && accountPrev !== 0 ? t("converter.tooltSwitch") : ""}
                                    >
                                        <FormGroup>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                justifyContent="left"
                                                alignItems="center"
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={checkedInvSigngs}
                                                            onChange={handleChangeSwitchInv}
                                                        />
                                                    }
                                                    label={t("converter.invertSings")}
                                                />
                                                <InfoIcon color="primary" fontSize="small" />
                                            </Stack>
                                            <Stack
                                                direction="row"
                                                spacing={1}
                                                justifyContent="left"
                                                alignItems="center"
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={checkedInvDay}
                                                            onChange={handleChangeSwitchInvDay}
                                                        />
                                                    }
                                                    label={t("converter.invertDayMonth")}
                                                />
                                                <InfoIcon color="primary" fontSize="small" />
                                            </Stack>
                                        </FormGroup>
                                    </LightTooltip>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="h1" color="primary" gutterBottom>
                                        {t("converter.preview")}
                                    </Typography>
                                    {conversionDataNew.large_conversion &&
                                        conversionDataNew.large_conversion === true ? (
                                        <Box mt={2}>{t("converter.largeConvMsg")}</Box>
                                    ) : groupedData ? (
                                        <>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <StyledTableRow>
                                                            {columns.map((item) => (
                                                                <StyledTableCell
                                                                    key={item.name}
                                                                    align={item.align ?? "left"}
                                                                    style={{ minWidth: item.minWidth }}
                                                                >
                                                                    {item.title}
                                                                </StyledTableCell>
                                                            ))}
                                                        </StyledTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {rowsConv.slice(0, 5).map((row) => (
                                                            <StyledTableRow
                                                                key={row.id}
                                                                sx={{
                                                                    "&:last-child td, &:last-child th": { border: 0 },
                                                                }}
                                                            >
                                                                <StyledTableCell align="left">
                                                                    {row.check}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    {row.contact}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="left">
                                                                    {row.concept}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="center">
                                                                    {row.date}
                                                                </StyledTableCell>
                                                                <StyledTableCell align="right">
                                                                    {row.amount}
                                                                </StyledTableCell>
                                                            </StyledTableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    ) : (
                                        <>
                                            <TableContainer>
                                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                                    <TableHead>
                                                        <StyledTableRow>
                                                            {columns.map((item) => (
                                                                <StyledTableCell
                                                                    key={item.name}
                                                                    align={item.align ?? "left"}
                                                                    style={{ minWidth: item.minWidth }}
                                                                >
                                                                    {item.title}
                                                                </StyledTableCell>
                                                            ))}
                                                        </StyledTableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        <StyledTableRow
                                                            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                                        >
                                                            <StyledTableCell align="left">
                                                                {conversionDataNew.check_number}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                {conversionDataNew.check_pay_to}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="left">
                                                                {conversionDataNew.check_description}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">
                                                                {moment
                                                                    .utc(conversionDataNew.formatted_date)
                                                                    .format(finalDateFormat)}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="right">
                                                                {checkedInvSigngs
                                                                    ? parseFloat(
                                                                        -1 * conversionDataNew.formatted_amount
                                                                    ).toLocaleString(todos.amountFormat, {
                                                                        minimumFractionDigits: 2,
                                                                    })
                                                                    : parseFloat(
                                                                        conversionDataNew.formatted_amount
                                                                    ).toLocaleString(todos.amountFormat, {
                                                                        minimumFractionDigits: 2,
                                                                    })}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={3} justifyContent="center">
                                        <Button
                                            size="large"
                                            variant="outlined"
                                            disableElevation
                                            endIcon={<DownloadIcon />}
                                            onClick={handleClickDownload}
                                            sx={{
                                                py: 1,
                                                px: 4.3,
                                            }}
                                        >
                                            {t("payment.download")}
                                        </Button>
                                        <Button
                                            size="large"
                                            variant="contained"
                                            disableElevation
                                            endIcon={
                                                groupedData ? (groupedData.checks.some((item) => item.id_status === 5) ? <img src={Loading} alt="loading" width={20} /> : <SyncIcon />) : conversionDataNew.id_status === 5 ? <img src={Loading} alt="loading" width={20} /> : <SyncIcon />
                                            }
                                            onClick={handleSync}
                                            sx={{
                                                py: 1,
                                                px: 3.5,
                                            }}
                                            disabled={(groupedData ? !groupedData.checks.some((item) => [3, 4, 9].includes(item.id_status)) || (groupedData?.account_id?.system_integration > 2) : ![3, 4, 9].includes(conversionDataNew.id_status)) || (conversionDataNew?.account_id?.system_integration > 2)}
                                        >
                                            {groupedData ? (groupedData.checks.some((item) => item.id_status === 5) ? t("converter.synchronizing") : t("converter.sync")) : conversionDataNew.id_status === 5 ? t("converter.synchronizing") : t("converter.sync")}
                                        </Button>
                                    </Stack>
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="row" spacing={1} justifyContent="center" alignItems="center">
                                        <InfoIcon color="primary" fontSize="small" />
                                        <Typography variant="subtitle1" color="#131f3e">
                                            {t("converter.syncInfoMsg")}
                                        </Typography>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </>
            )}
            <SimpleDialog
                open={showMatchBankModal}
                handleClose={handleCloseMatchBankModal}
                maxWidth="sm"
                content={
                    <Box sx={{ px: 4 }}>
                        <Stack direction="row" justifyContent="center" alignItems="center" sx={{ pb: 2 }} spacing={2}>
                            <WarningIcon size="small" sx={{ color: "#FFB23F" }} />
                            <Typography variant="h1" sx={{ color: "#FFB23F" }}>
                                {t("converter.matchBank")}
                            </Typography>
                        </Stack>
                        <Typography sx={{ pb: 2 }}>{t("converter.matchBankText")}</Typography>
                        <Button onClick={handleCloseMatchBankModal} sx={{ ml: 2 }}>
                            {t("register.back")}
                        </Button>
                    </Box>
                }
            />
        </Container>
    );
};

export default ChecksPreview;
