/** React imports */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import {
    Box,
    Button,
    FilledInput,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
    styled,
} from "@mui/material";

/** Js imports **/
import companyService from "../../../services/company";
import authService from "../../../services/auth";
import CustomFilledSelectCountries from "../../ui/CustomFilledSelectCountries";
import CustomFilledSelect from "../../ui/CustomFilledSelect";
import businessActivity from "../../Js/businessActivity";
import countriesProject from "../../Js/countries";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Flag from "react-world-flags";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const UpdateSubscription = (props) => {
    const { franchiseDetails, setTexto, setAlertType, setShow, setIsLoading } = props;

    const [isError, setIsError] = useState(false);
    const [existingEmail, setExistingEmail] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneCode, setPhoneCode] = useState("");
    const [countryCode, setCountryCode] = useState("");
    /** Countries */
    const allCountries = [...countriesProject];

    allCountries.sort((a, b) => {
        if (sessionStorage.getItem("lng") === "en") {
            return a.name_en.localeCompare(b.name_en);
        } else {
            return a.name_es.localeCompare(b.name_es);
        }
    });

    useEffect(() => {
        setPhoneCode(franchiseDetails.phone_code);
        setPhoneNumber(franchiseDetails.phone_number);

        let codeFilter = allCountries.find((country) => country.phone_code === franchiseDetails.phone_code);
        setCountryCode(codeFilter?.country_code);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [updatedFranchiseDetails, setUpdatedFranchiseDetails] = useState({
        franchiseName: franchiseDetails.franchise_name ?? "",
        country: franchiseDetails.country_name ?? "",
        ecActivity: franchiseDetails.business_activity ?? "",
        monthlyPages: franchiseDetails.monthly_conversions ?? "",
        address: franchiseDetails.franchise_address ?? "",
        email: franchiseDetails.franchise_email ?? "",
        website: franchiseDetails.franchise_web ?? "",
    });

    const [t] = useTranslation("global");

    const handleChange = (event) => {
        setUpdatedFranchiseDetails({
            ...updatedFranchiseDetails,
            [event.target.name]: event.target.value,
        });
    };

    let bussinessActFinal = [];

    for (let i = 0; i < businessActivity.length; i++) {
        bussinessActFinal.push({
            id: businessActivity[i].id,
            name: t("dialog." + businessActivity[i].translation),
        });
    }

    const handleChangeCurrentEmail = async (event) => {
        setUpdatedFranchiseDetails({
            ...updatedFranchiseDetails,
            email: event.target.value,
        });
        verifyEmail(event.target.value);
    };

    const verifyEmail = async (email) => {
        if (email.trim() && /.+@.+\..+/.test(email)) {
            await authService
                .verifyExistingUser(email)
                .then((response) => {
                    if (
                        response.status === 200 &&
                        email !== franchiseDetails.email
                    ) {
                        setAlertType("error");
                        setExistingEmail(true);
                        setErrorEmail(true);
                        setShow(true);
                        setTexto(t("register.existingEmail"));
                    } else {
                        setExistingEmail(false);
                        setErrorEmail(false);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        setExistingEmail(false);
                        setErrorEmail(false);
                    }
                });
        } else {
            setErrorEmail(true);
        }
    };

    const handleChangePhoneCode = (event) => {
        let codeFilter = allCountries.find(
            (country) => country.country_code === event.target.value
        );
        if (codeFilter) {
            setCountryCode(event.target.value);
            setPhoneCode(codeFilter.phone_code);
        }
    };

    const handleChangePhoneNumber = (event) => {
        if (!isNaN(event.target.value)) {
            setPhoneNumber(event.target.value);
        }
    };

    const conversionList = [
        { id: 1, name: "1-5 " + t("register.conversions") },
        { id: 2, name: "6-15 " + t("register.conversions") },
        { id: 3, name: "16-30 " + t("register.conversions") },
        { id: 4, name: "31-50 " + t("register.conversions") },
        { id: 5, name: "51-200 " + t("register.conversions") },
        { id: 6, name: "+200 " + t("register.conversions") },
    ];

    const updateFranchise = async () => {
        setIsLoading(true);
        if (
            updatedFranchiseDetails.franchiseName.trim() &&
            updatedFranchiseDetails.country &&
            updatedFranchiseDetails.ecActivity
        ) {
            // Update
            const params = {
                franchise_id: franchiseDetails._id,
                franchise_name: updatedFranchiseDetails.franchiseName,
                country_name: updatedFranchiseDetails.country,
                business_activity: updatedFranchiseDetails.ecActivity,
                monthly_conversions:
                    updatedFranchiseDetails.monthlyPages !== ""
                        ? updatedFranchiseDetails.monthlyPages
                        : undefined,
                franchise_address: updatedFranchiseDetails.address ?? undefined,
                franchise_email:
                    updatedFranchiseDetails.email !== ""
                        ? updatedFranchiseDetails.email
                        : undefined,
                franchise_web:
                    updatedFranchiseDetails.website !== ""
                        ? updatedFranchiseDetails.website
                        : undefined,
                phone_code: phoneCode !== "" ? phoneCode : undefined,
                phone_number: phoneNumber !== "" ? phoneNumber : undefined,
            };

            await companyService
                .updateFranchise(params)
                .then(() => {
                    setTexto(t("inbox.successEdit"));
                    setAlertType("success");
                    setShow(true);
                })
                .catch((err) => {
                    console.log(err);
                    setTexto("Error");
                    setAlertType("error");
                    setShow(true);
                });
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    };

    return (
        <>
            <Box
                sx={{
                    borderRadius: "24px",
                    px: { xs: 3, md: 10 },
                    py: { xs: 3, md: 4 },
                    minWidth: "980px",
                    backgroundColor: "#FFF",
                }}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h1">
                            {t("register.business")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            fullWidth
                            label={t("register.subsName")}
                            name="franchiseName"
                            required
                            onChange={handleChange}
                            value={updatedFranchiseDetails.franchiseName}
                            variant="filled"
                            inputProps={{
                                fontStyle: "normal",
                            }}
                            error={isError && updatedFranchiseDetails.franchiseName.trim() === ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomFilledSelectCountries
                            required={true}
                            error={isError && !updatedFranchiseDetails.country}
                            label={t("register.country")}
                            value={updatedFranchiseDetails.country}
                            onChange={handleChange}
                            name="country"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomFilledSelect
                            error={isError && !updatedFranchiseDetails.ecActivity}
                            required
                            label={t("dialog.ecActivity")}
                            value={updatedFranchiseDetails.ecActivity}
                            onChange={handleChange}
                            name="ecActivity"
                            values={bussinessActFinal}
                            idName="id"
                            valueName="name"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomFilledSelect
                            error={
                                isError && !updatedFranchiseDetails.monthlyPages
                            }
                            required={true}
                            name="monthlyPages"
                            label={t("dialog.monthlyPages")}
                            values={conversionList}
                            idName="id"
                            valueName="name"
                            value={updatedFranchiseDetails.monthlyPages}
                            onChange={(event) => handleChange(event)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            fullWidth
                            label={t("converter.address")}
                            name="address"
                            required
                            onChange={handleChange}
                            value={updatedFranchiseDetails.address}
                            variant="filled"
                            inputProps={{
                                fontStyle: "normal",
                            }}
                            error={isError && updatedFranchiseDetails.address.trim() === ""}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            fullWidth
                            label={t("register.email")}
                            name="email"
                            onChange={(event) =>
                                handleChangeCurrentEmail(event)
                            }
                            error={
                                ((isError || errorEmail) &&
                                    !/.+@.+\..+/.test(
                                        updatedFranchiseDetails.email
                                    )) ||
                                existingEmail
                            }
                            value={updatedFranchiseDetails.email}
                            variant="filled"
                            inputProps={{
                                fontStyle: "normal",
                            }}
                            type="text"
                            required
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <CustomTextField
                            fullWidth
                            label={"Website"}
                            name="website"
                            required
                            onChange={handleChange}
                            value={updatedFranchiseDetails.website}
                            variant="filled"
                            inputProps={{
                                fontStyle: "normal",
                            }}
                            error={
                                isError &&
                                updatedFranchiseDetails.website.trim() === ""
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <FormControl
                                    fullWidth
                                    variant="filled"
                                    error={
                                        isError &&
                                        (phoneCode === "" || !phoneCode)
                                            ? true
                                            : false
                                    }
                                    required
                                >
                                    <InputLabel
                                        id="type-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("register.code")}
                                    </InputLabel>
                                    <Select
                                        IconComponent={(props) => {
                                            if (
                                                props.className.includes(
                                                    "MuiSelect-iconOpen"
                                                )
                                            ) {
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position:
                                                                "absolute",
                                                            transform:
                                                                "rotate(270deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents:
                                                                "none",
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform:
                                                            "rotate(90deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }}
                                        name="phoneCode"
                                        value={countryCode ?? ""}
                                        onChange={(event) =>
                                            handleChangePhoneCode(event)
                                        }
                                        fullWidth
                                        input={
                                            <FilledInput
                                                label={t("register.code")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    color: "#131F3E",
                                                }}
                                            />
                                        }
                                        MenuProps={{
                                            PaperProps: {
                                                sx: {
                                                    mt: 0.7,
                                                    borderRadius: 2,
                                                    boxShadow:
                                                        "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    maxHeight: "300px",
                                                },
                                            },
                                        }}
                                        renderValue={() => (
                                            <Stack
                                                direction="row"
                                                spacing={0.5}
                                                alignItems="center"
                                            >
                                                <Flag
                                                    code={countryCode}
                                                    height="12"
                                                    width="20"
                                                />
                                                <Typography>
                                                    {phoneCode}
                                                </Typography>
                                            </Stack>
                                        )}
                                    >
                                        {allCountries.map((option) => (
                                            <MenuItem
                                                value={option.country_code}
                                                key={option.country_code}
                                            >
                                                <Stack
                                                    direction="row"
                                                    spacing={1}
                                                    alignItems="center"
                                                >
                                                    <Flag
                                                        code={
                                                            option.country_code
                                                        }
                                                        height="14"
                                                        width="22"
                                                    />
                                                    <Typography>
                                                        {sessionStorage.getItem(
                                                            "lng"
                                                        ) === "en"
                                                            ? option.name_en
                                                            : option.name_es}
                                                        {" ("}
                                                        {option.phone_code +
                                                            ")"}
                                                    </Typography>
                                                </Stack>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} md={8}>
                                <CustomTextField
                                    required
                                    fullWidth
                                    label={t("register.phone")}
                                    name="phoneNumber"
                                    error={
                                        isError &&
                                        (phoneNumber === "" || !phoneNumber)
                                            ? true
                                            : false
                                    }
                                    onChange={(event) =>
                                        handleChangePhoneNumber(event)
                                    }
                                    value={phoneNumber}
                                    variant="filled"
                                    inputProps={{
                                        fontStyle: "normal",
                                    }}
                                    type="text"
                                    autoComplete="one-time-code"
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{ display: "flex", justifyContent: "end" }}
                            >
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={updateFranchise}
                                    size="large"
                                    sx={{
                                        marginLeft: "auto !important",
                                        mt: 2
                                    }}
                                >
                                    {t("register.saveChanges")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

export default UpdateSubscription;
