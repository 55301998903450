const plansInfo = [
    {
        planId: 0,
        planTitle: "Micro",
        extension: 0,
        subtitle: "plans.starting",
        planType: "plans.essential",
        commType: "plans.inTheApp",
        item1: "plans.knowlBase",
        item2: "plans.specialCustomServ",
        item3: "dashboard.unavailable",
        item4: "plans.standar",
        users: 2,
        training: "plans.knowBase",
        freeCompanies: 0,
        levels: [{ id: 0, pages: 20, price: 0 }],
        discounts: [{ min: 0, max: Infinity, discount: 0 }],
    },
    {
        planId: 1,
        planTitle: "Small",
        extension: 30,
        subtitle: "plans.growing",
        planType: "plans.standar",
        commType: "plans.inTheApp",
        item1: "plans.knowlBase",
        item2: "plans.specialCustomServ",
        item3: "plans.converCon",
        item4: "plans.standar",
        training: "plans.knowBase",
        users: Infinity,
        freeCompanies: 2,
        levels: [
            { id: 1, pages: 20, price: 9 },
            { id: 2, pages: 50, price: 19 },
            { id: 3, pages: 100, price: 29 },
            { id: 4, pages: 200, price: 49 },
            { id: 5, pages: 300, price: 79 },
            { id: 6, pages: 400, price: 99 },
            { id: 7, pages: 500, price: 129 },
        ],
        discounts: [
            { min: 0, max: 10, discount: 0 },
            { min: 11, max: 50, discount: 0.1 },
            { min: 51, max: 250, discount: 0.15 },
            { min: 251, max: 1000, discount: 0.2 },
            { min: 1001, max: 2500, discount: 0.3 },
            { min: 2501, max: Infinity, discount: 0.4 },
        ],
    },
    {
        planId: 2,
        planTitle: "Medium",
        extension: 60,
        subtitle: "plans.stablish",
        planType: "plans.standar",
        commType: "plans.appMail",
        item1: "plans.customize",
        item2: "plans.specialCustomServ",
        item3: "plans.converCon",
        item4: "inbox.priority",
        training: "plans.custom",
        users: Infinity,
        freeCompanies: 5,
        levels: [
            { id: 8, pages: 1000, price: 199 },
            { id: 9, pages: 1500, price: 299 },
            { id: 10, pages: 2000, price: 399 },
            { id: 11, pages: 2500, price: 499 },
            { id: 12, pages: 3000, price: 599 },
        ],
        discounts: [
            { min: 0, max: 10, discount: 0 },
            { min: 11, max: 50, discount: 0.15 },
            { min: 51, max: 250, discount: 0.2 },
            { min: 251, max: 1000, discount: 0.25 },
            { min: 1001, max: 2500, discount: 0.35 },
            { min: 2501, max: Infinity, discount: 0.45 },
        ],
    },
    {
        planId: 3,
        planTitle: "Enterprise",
        extension: 90,
        subtitle: "plans.scaling",
        planType: "plans.customize",
        commType: "plans.appMailPhone",
        item1: "plans.customize",
        item2: "plans.managerAcc",
        item3: "plans.converCon",
        item4: "inbox.priority",
        training: "plans.custom",
        users: Infinity,
        freeCompanies: 20,
        levels: [
            { id: 13, pages: 5000, price: 749 },
            { id: 14, pages: 10000, price: 1499 },
            { id: 15, pages: 15000, price: 2249 },
            { id: 16, pages: 20000, price: 2999 },
            { id: 17, pages: Infinity, price: Infinity, custom: true },
        ],
        discounts: [
            { min: 0, max: 10, discount: 0 },
            { min: 11, max: 50, discount: 0.2 },
            { min: 51, max: 250, discount: 0.25 },
            { min: 251, max: 1000, discount: 0.30 },
            { min: 1001, max: 2500, discount: 0.40 },
            { min: 2501, max: Infinity, discount: 0.50 },
        ],
    },
];
export default plansInfo;
