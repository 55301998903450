import { Grid, styled, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const CustomForm = (props) => {

    const { setQuestionCustom, questionCustom, errorCustomPlan } = props;
    
    const [t] = useTranslation("global");

    const handleChangeQuestions = (event) => {
        const { name, value } = event.target;

        setQuestionCustom((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                }}
            >
                {t("register.customCondMsg1")}
            </Typography>
            <Grid container spacing={1} mt={1} mb={2}>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("register.customCondQues1")}
                        name="question1"
                        onChange={handleChangeQuestions}
                        value={questionCustom.question1}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        type="text"
                        error={errorCustomPlan && !questionCustom.question1}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("register.customCondQues2")}
                        name="question2"
                        onChange={handleChangeQuestions}
                        value={questionCustom.question2}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        type="text"
                        error={errorCustomPlan && !questionCustom.question2}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("register.customCondQues3")}
                        name="question3"
                        onChange={handleChangeQuestions}
                        value={questionCustom.question3}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        type="text"
                        error={errorCustomPlan && !questionCustom.question3}
                    />
                </Grid>
            </Grid>
            <Typography
                fontStyle="normal"
                fontSize={14}
                fontWeight={300}
                sx={{
                    color: "#131F3E",
                }}
                dangerouslySetInnerHTML={{
                    __html: t("register.customCondMsg2"),
                }}
            ></Typography>
        </>
    );
};

export default CustomForm;
