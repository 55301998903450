import {
    Box,
    Button,
    Chip,
    FormControl,
    IconButton,
    MenuItem,
    Popover,
    Select,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { CopyToClipboard } from "react-copy-to-clipboard";

import LightTooltip from "../ui/LightTooltip";

/** MUI icons */
import EditIcon from "@mui/icons-material/Edit";
import EditOffIcon from "@mui/icons-material/EditOff";
import CancelIcon from "@mui/icons-material/Cancel";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import BarChartIcon from "@mui/icons-material/BarChart";
import CheckIcon from "@mui/icons-material/Check";
import EngineeringIcon from "@mui/icons-material/Engineering";
import moment from "moment";
import BiotechIcon from "@mui/icons-material/Biotech";

import { useTranslation } from "react-i18next";

import casesServices from "../../services/case";
import commServices from "../../services/communication";

import { useState } from "react";
import TablePaginationActions from "../ui/TablePagActions";
import { initiateSocket, subscribeToChat, newUserResponse } from "../../services/Socket";
import ChatModal from "../ui/Chat";
import { useSelector } from "react-redux";
import ChatIconCustom from "../../assets/chat-icon-inbox.svg";
import ChatIconCustomNotif from "../../assets/chat-icon-inbox-notif.svg";
import AlertDialog from "../ui/AlertDialog";
import { useEffect } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "rgba(19, 31, 62, 0.03)",
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: theme.palette.common.white,
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const CustomPopover = styled(Popover)({
    ".MuiPopover-paper": {
        borderRadius: 20,
    },
});

const CustomIcon = styled(CancelIcon)({
    "&:hover": {
        color: "#FF007A",
    },
});

const rowsSkeleton = [];

for (let i = 0; i < 10; i++) {
    rowsSkeleton.push({
        id: i,
        global_case: <Skeleton variant="text" animation="wave" />,
        case: <Skeleton variant="text" animation="wave" />,
        conversion_id: <Skeleton variant="text" animation="wave" />,
        creation_date: <Skeleton variant="text" animation="wave" />,
        franchise: <Skeleton variant="text" animation="wave" />,
        company: <Skeleton variant="text" animation="wave" />,
        case_user: <Skeleton variant="text" animation="wave" />,
        case_user_email: <Skeleton variant="text" animation="wave" />,
        case_reason: <Skeleton variant="text" animation="wave" />,
        status: <Skeleton variant="text" animation="wave" />,
        mainUser: <Skeleton variant="text" animation="wave" />,
        devUser: <Skeleton variant="text" animation="wave" />,
        testUser: <Skeleton variant="text" animation="wave" />,
        priority: <Skeleton variant="text" animation="wave" />,
        actions: <Skeleton variant="text" animation="wave" />,
    });
}

const PendingTableAdmin = (props) => {
    const {
        columns,
        mainUsers,
        setIsLoading,
        user_id,
        company_id,
        franchise_id,
        setCases,
        devUsers,
        priorities,
        setShow,
        setTexto,
        setAlertType,
        reloadCases,
        cases,
    } = props;

    const [t] = useTranslation("global");

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [editGlobal, setEditGlobal] = useState(false);
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedCase, setSelectedCase] = useState({});
    const [deletedID, setDeletedID] = useState("");
    const [showModalDel, setShowModalDel] = useState(false);
    const [copied, setCopied] = useState(false);
    const [caseInfo, setCaseInfo] = useState({});
    const [chatInfo, setChatInfo] = useState({});
    const [rows, setRows] = useState(rowsSkeleton);
    const [casesTable, setCasesTable] = useState([]);

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

    const openPop = Boolean(anchorEl);
    const id = openPop ? "simple-popover" : undefined;

    const convertTZ = (date, tzString) => {
        return new Date(
            (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString })
        );
    };

    const deleteCase = async () => {
        setIsLoading(true);
        await casesServices
            .deleteCase({ case_id: deletedID })
            .then(() => {
                reloadCases();
                setEditGlobal(false);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
        handleCloseModal();
    };

    const handleCloseModal = () => {
        setShowModalDel(false);
        setDeletedID("");
    };

    const handleClick = (event, val) => {
        setAnchorEl(event.currentTarget);
        setSelectedCase(val);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedCase({});
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeSelect = (event, index) => {
        setCasesTable(
            casesTable.map((aux, index2) => {
                if (index === index2) {
                    return { ...aux, [event.target.name]: event.target.value };
                } else {
                    return aux;
                }
            })
        );
    };

    const handleAssign = async (id, index) => {
        setIsLoading(true);

        const params = {
            case_id: id,
            case_responsible: casesTable[index].mainUser,
            dev_manager: casesTable[index].devUser,
            test_manager: casesTable[index].testUser,
            priority: casesTable[index].priority,
            lang: t("language.locale"),
        };

        await casesServices
            .assignAdminCase(params)
            .then(async () => {
                let params2 = {
                    user_id: user_id,
                    company_id: company_id,
                    franchise_id: franchise_id,
                };

                await casesServices
                    .getAdminCases(params2)
                    .then((data) => {
                        // console.log("data", data);
                        setCases(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((err) => {
                console.log(err);
            });

        setIsLoading(false);
        setEditGlobal(false);
    };

    const checkIconDown = (props) => {
        if (props.className.includes("MuiSelect-iconOpen")) {
            return (
                <PlayArrowIcon
                    sx={{
                        position: "absolute",
                        transform: "rotate(270deg)",
                        color: "#131F3E",
                        right: ".5rem",
                        cursor: "pointer",
                        zIndex: 0,
                        pointerEvents: "none",
                    }}
                />
            );
        }
        return (
            <PlayArrowIcon
                sx={{
                    position: "absolute",
                    transform: "rotate(90deg)",
                    color: "#131F3E",
                    right: ".5rem",
                    cursor: "pointer",
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            />
        );
    };

    const handleEdit = (index, action) => {
        if (action === "edit" && editGlobal === false) {
            setEditGlobal(true);
            setCasesTable(
                casesTable.map((aux, index2) => {
                    if (index === index2) {
                        return { ...aux, edit: !aux.edit };
                    } else {
                        return aux;
                    }
                })
            );
        } else if (action === "edit" && editGlobal === true) {
            setAlertType("warning");
            setTexto(t("inbox.activeEdition"));
            setShow(true);
        } else if (action === "save") {
            setEditGlobal(false);
            setCasesTable(
                casesTable.map((aux, index2) => {
                    if (index === index2) {
                        return { ...aux, edit: !aux.edit };
                    } else {
                        return aux;
                    }
                })
            );
        }
    };

    const todos = useSelector((state) => state.value);

    const connectSocket = async (case_id, reason, convID) => {
        setIsLoading(true);
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await casesServices
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo);
                setChatInfo(data.chatCase);

                initiateSocket(
                    case_id,
                    todos.userInfo,
                    reason === 1 && convID ? "conversion" : "general",
                    ""
                );
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setOpen(true);
                await commServices
                    .updateStatusMess({
                        room: case_id,
                        user: todos.userInfo._id,
                    })
                    .then()
                    .catch((err) => console.log(err));
                reloadCases();
                setEditGlobal(false);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const showModal = (id) => {
        setDeletedID(id);
        setShowModalDel(true);
    };

    const handleCopy = () => {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 5000);
    };

    useEffect(() => {
        if (cases) {
            const newArr = [];

            const notAssingData = cases.pending.map((item, index) => {
                const defaultUsers = {
                    index: index,
                    mainUser: item.case_responsible ?? "",
                    devUser: item.case_developer_manager ?? "",
                    testUser: item.case_test_manager ?? "",
                    priority: item.priority ?? "",
                    edit: false,
                    status: item.case_status,
                };

                newArr.push(defaultUsers);

                return {
                    index: index,
                    id: item.case_id,
                    global_case: item.global_case_number,
                    case: item.case_number,
                    conversion_id: item.conversion_id ? (
                        <LightTooltip title={item.conversion_id}>{item.conversion_id.slice(-6)}</LightTooltip>
                    ) : (
                        ""
                    ),
                    conversion_status: item.conversion_status,
                    creation_date: moment(convertTZ(item.creation_date, "America/New_York")).format(
                        `${todos.dateFormat} HH:mm`
                    ),
                    franchise: item.franchise_name,
                    company: item.company_name,
                    case_user: item.case_user,
                    case_user_email: item.case_user_email,
                    case_reason:
                        item.case_reason === 1
                            ? t("inbox.conversions")
                            : item.case_reason === 2
                                ? t("inbox.newBank")
                                : t("inbox.directRequest"),
                    origin: item.case_reason,
                    status:
                        item.case_status === 1 ? (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography color="#FFB23F">{t("inbox.analysis")}</Typography>
                                <BarChartIcon sx={{ color: "#FFB23F" }} fontSize="inherit" />
                            </Stack>
                        ) : item.case_status === 2 ? (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography color="#3FA7D6">{t("inbox.progress")}</Typography>
                                <EngineeringIcon sx={{ color: "#3FA7D6" }} fontSize="inherit" />
                            </Stack>
                        ) : item.case_status === 3 ? (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography color="#005792">{t("inbox.test")}</Typography>
                                <BiotechIcon sx={{ color: "#005792" }} fontSize="inherit" />
                            </Stack>
                        ) : item.case_status === 4 ? (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography color="#00C8C8">{t("inbox.solvedKiiper")}</Typography>
                                <CheckIcon sx={{ color: "#00C8C8" }} fontSize="inherit" />
                            </Stack>
                        ) : (
                            <Stack direction="row" spacing={1} alignItems="center">
                                <Typography color="#00B147">{t("inbox.solvedUser")}</Typography>
                                <CheckIcon sx={{ color: "#00B147" }} fontSize="inherit" />
                            </Stack>
                        ),
                    unread: item.unread,
                    phone_code: item.phone_code,
                    phone_number: item.phone_number,
                    bank_name: item.bank_name ? item.bank_name : "",
                    bank_currency: item.bank_currency ? item.bank_currency : "",
                    bank_country: item.bank_country ? item.bank_country : "",
                };
            });

            setCasesTable(newArr);
            setRows(notAssingData);
        }
    }, [cases, company_id, franchise_id, setCases, setIsLoading, t, todos.dateFormat, user_id]);

    return (
        <>
            <TableContainer>
                <Table
                    sx={{ borderCollapse: "separate", borderSpacing: "0 6px", minWidth: 1400 }}
                    aria-label="simple table"
                >
                    <TableHead>
                        <StyledTableRow>
                            {columns.map((item) => (
                                <StyledTableCell
                                    key={item.name}
                                    align={item.align ?? "left"}
                                    style={{ width: item.width }}
                                >
                                    {item.title}
                                </StyledTableCell>
                            ))}
                        </StyledTableRow>
                    </TableHead>
                    <TableBody>
                        {(rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row) => (
                            <StyledTableRow key={row.id}>
                                <StyledTableCell>
                                    <Button onClick={(event) => handleClick(event, row)} size="small">
                                        {row.global_case}
                                    </Button>
                                </StyledTableCell>
                                <StyledTableCell>{row.franchise}</StyledTableCell>
                                <StyledTableCell align="center">{row.creation_date}</StyledTableCell>
                                <StyledTableCell>{row.status}</StyledTableCell>
                                <StyledTableCell>
                                    {row.mainUser ? (
                                        row.mainUser
                                    ) : casesTable[row.index].edit ? (
                                        <FormControl variant="standard" fullWidth>
                                            <Select
                                                id="demo-simple-select-standard"
                                                name="mainUser"
                                                value={casesTable[row.index]?.mainUser ?? ""}
                                                onChange={(event) => handleChangeSelect(event, row.index)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                IconComponent={(props) => checkIconDown(props)}
                                            >
                                                <MenuItem value="">{t("inbox.assign")}</MenuItem>
                                                {mainUsers.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        mainUsers.find((item) => item.value === casesTable[row.index]?.mainUser)
                                            ?.name ?? ""
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.devUser ? (
                                        row.devUser
                                    ) : casesTable[row.index].edit ? (
                                        <FormControl variant="standard" fullWidth>
                                            <Select
                                                id="demo-simple-select-standard"
                                                name="devUser"
                                                value={casesTable[row.index]?.devUser ?? ""}
                                                onChange={(event) => handleChangeSelect(event, row.index)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                IconComponent={(props) => checkIconDown(props)}
                                            >
                                                <MenuItem value="">{t("inbox.assign")}</MenuItem>
                                                {devUsers.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        devUsers.find((item) => item.value === casesTable[row.index]?.devUser)?.name ??
                                        ""
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.testUser ? (
                                        row.testUser
                                    ) : casesTable[row.index].edit ? (
                                        <FormControl variant="standard" fullWidth>
                                            <Select
                                                id="demo-simple-select-standard"
                                                name="testUser"
                                                value={casesTable[row.index]?.testUser ?? ""}
                                                onChange={(event) => handleChangeSelect(event, row.index)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                IconComponent={(props) => checkIconDown(props)}
                                            >
                                                <MenuItem value="">{t("inbox.assign")}</MenuItem>
                                                {mainUsers.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        mainUsers.find((item) => item.value === casesTable[row.index]?.testUser)
                                            ?.name ?? ""
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.priority ? (
                                        row.priority
                                    ) : casesTable[row.index].edit ? (
                                        <FormControl variant="standard" fullWidth>
                                            <Select
                                                id="demo-simple-select-standard"
                                                name="priority"
                                                value={casesTable[row.index]?.priority ?? ""}
                                                onChange={(event) => handleChangeSelect(event, row.index)}
                                                displayEmpty
                                                inputProps={{ "aria-label": "Without label" }}
                                                IconComponent={(props) => checkIconDown(props)}
                                            >
                                                <MenuItem value="">{t("inbox.assign")}</MenuItem>
                                                {priorities.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        <Typography color={item.color}>{item.name}</Typography>
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    ) : (
                                        <Typography
                                            color={
                                                priorities.find(
                                                    (item) => item.value === casesTable[row.index]?.priority
                                                )?.color
                                            }
                                        >
                                            {priorities.find((item) => item.value === casesTable[row.index]?.priority)
                                                ?.name ?? ""}
                                        </Typography>
                                    )}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.actions ?? (
                                        <Stack direction="row" spacing={1}>
                                            {!casesTable[row.index].edit ? (
                                                <LightTooltip title={t("team.edit")}>
                                                    <IconButton
                                                        disabled={editGlobal && !casesTable[row.index].edit}
                                                        onClick={() => handleEdit(row.index, "edit")}
                                                    >
                                                        <EditIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            ) : (
                                                <LightTooltip title={t("team.cancel")}>
                                                    <IconButton onClick={() => handleEdit(row.index, "save")}>
                                                        <EditOffIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            )}
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                endIcon={
                                                    <Box
                                                        component="img"
                                                        src={row.unread > 0 ? ChatIconCustomNotif : ChatIconCustom}
                                                        sx={{ width: "15px", height: "15px" }}
                                                    />
                                                }
                                                onClick={() => connectSocket(row.id, row.origin, row.conversion_id)}
                                                sx={{ whiteSpace: "nowrap", width: { lg: 132 } }}
                                            >
                                                Chat
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                disableElevation
                                                onClick={() => handleAssign(row.id, row.index)}
                                                size="small"
                                                sx={{ width: { lg: 132 } }}
                                                disabled={
                                                    !casesTable[row.index]?.mainUser ||
                                                    !casesTable[row.index]?.devUser ||
                                                    !casesTable[row.index]?.testUser ||
                                                    !casesTable[row.index]?.priority
                                                }
                                            >
                                                {t("inbox.assign")}
                                            </Button>
                                            <IconButton onClick={() => showModal(row.id)}>
                                                <CustomIcon />
                                            </IconButton>
                                        </Stack>
                                    )}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                            </TableRow>
                        )}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                rowsPerPageOptions={[10, 25, 50, { label: t("miscellaneous.all"), value: -1 }]}
                                colSpan={12}
                                count={rows.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                ActionsComponent={TablePaginationActions}
                                labelRowsPerPage={t("dashboard.rowsPerPage")}
                                labelDisplayedRows={(page) =>
                                    `${page.from}-${page.to === -1 ? page.count : page.to} ${t("team.unlinkText2")} ${page.count
                                    }`
                                }
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer>
            <CustomPopover
                id={id}
                open={openPop}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                sx={{ borderRadius: 20 }}
            >
                <Box sx={{ padding: "10px 30px", backgroundColor: "#6544DB" }}>
                    <Typography variant="h2" sx={{ color: "white", pb: 1 }}>
                        {selectedCase.company}
                    </Typography>
                    <Typography sx={{ color: "white", fontSize: 12 }}>
                        {t("inbox.origin")}: {selectedCase.case_reason}
                    </Typography>
                    <Typography sx={{ color: "white", fontSize: 12 }}>
                        {t("inbox.interID")}: {selectedCase.case}
                    </Typography>
                    {selectedCase.origin === 1 && (
                        <Stack direction="row" alignItems="center" spacing={1}>
                            <Typography sx={{ color: "white", fontSize: 12 }}>
                                {t("inbox.convID")}: {selectedCase.conversion_id}
                            </Typography>
                            <Chip
                                label={
                                    selectedCase.conversion_status === 1
                                        ? t("converter.inProc")
                                        : selectedCase.conversion_status === 2
                                            ? t("converter.convertible")
                                            : selectedCase.conversion_status === 3
                                                ? t("converter.successful")
                                                : selectedCase.conversion_status === 4
                                                    ? t("converter.inReview")
                                                    : t("converter.invalid")
                                }
                                color={
                                    selectedCase.conversion_status === 3 || selectedCase.conversion_status === 2
                                        ? "success"
                                        : selectedCase.conversion_status === 4 || selectedCase.conversion_status === 1
                                            ? "warning"
                                            : "error"
                                }
                                size="small"
                                sx={{ color: "white", fontSize: 12 }}
                            />
                        </Stack>
                    )}
                    {selectedCase.origin === 1 && (
                        <Typography sx={{ color: "white", fontSize: 12 }}>
                            {selectedCase.bank_name} - {selectedCase.bank_country} - {selectedCase.bank_currency}
                        </Typography>
                    )}
                </Box>
                <Box sx={{ padding: "10px 30px" }}>
                    <Typography variant="h2" color="primary" sx={{ pb: 1 }}>
                        {selectedCase.case_user}
                    </Typography>
                    <Stack direction="row" alignItems="center" spacing={0.5} display="flex" sx={{ pb: 1 }}>
                        <Typography sx={{ textDecoration: "underline" }}>{selectedCase.case_user_email}</Typography>
                        <CopyToClipboard text={selectedCase.case_user_email} onCopy={() => handleCopy()}>
                            <div>
                                <LightTooltip title={copied ? t("inbox.copied") : t("inbox.copy")} position="bottom">
                                    <FileCopyIcon fontSize="inherit" sx={{ cursor: "pointer" }} />
                                </LightTooltip>
                            </div>
                        </CopyToClipboard>
                    </Stack>
                    <Typography sx={{ pb: 1 }}>
                        {selectedCase.phone_code} {selectedCase.phone_number}
                    </Typography>
                </Box>
            </CustomPopover>
            {open && <ChatModal
                room={room}
                setRoom={setRoom}
                open={open}
                setOpen={setOpen}
                setChat={setChat}
                chat={chat}
                users={users}
                caseInfo={caseInfo}
                setCaseInfo={setCaseInfo}
                reloadCases={reloadCases}
                chatInfo={chatInfo}
                setChatInfo={setChatInfo}
                connectSocket={connectSocket}
                params={3}
            />}
            <AlertDialog
                open={showModalDel}
                onClose={handleCloseModal}
                agreeBtnLabel={t("dialog.continue")}
                disagreeBtnLabel={t("team.cancel")}
                type={"warning"}
                title={t("dialog.warning")}
                message={
                    <Box sx={{ textAlign: "center" }}>
                        <Typography>{t("inbox.sureDel1")}</Typography>
                        <Typography>{t("inbox.sureDel2")}</Typography>
                    </Box>
                }
                agreeAction={deleteCase}
                maxWidth="xs"
            />
        </>
    );
};

export default PendingTableAdmin;
