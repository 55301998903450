import {
    Button,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import DownloadIcon from "@mui/icons-material/Download";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Loading from "../../assets/loading.png";
import WarningIcon from "@mui/icons-material/Warning";

import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

import conversionService from "../../services/conversion";
import conversionFormats from "../Js/conversionFormats";
import LightTooltip from "../ui/LightTooltip";
import UploadFile from "../ui/UploadFile";
import { fetchFileFromS3, getFileExtension, zeroPad } from "../../utils/utils";

import { read, utils } from "xlsx";
import { useEffect } from "react";
import { useSelector } from "react-redux";

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    // hide last border
    "& td, &:last-child th": {
        border: 0,
    },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const GroupedContent = (props) => {
    const {
        setTexto,
        setAlertType,
        setShow,
        setConvFormat,
        setFileData,
        setAcceptedFiles,
        acceptedFiles,
        setGroupedData,
        setS3FileName,
        s3FileName,
        formats,
        rows,
        setRows,
        setOpenPreview,
        setHTML,
        setIsLoading,
        isHist,
        setFileNameFull,
        accountId,
        setProgress,
        progress,
        setConvInfo,
        convInfo,
        modalType,
        setRowsModal,
        rowsModal,
        setBasePreviewData,
        setShowRepeatModal,
        setShowRepeatVal,
        setOpenModalGrouped,
        repeatedConv,
        replaceInvBill
    } = props;

    useEffect(() => {
        if (!isHist) {
            setRowsModal(rows)
        }
        
    }, [isHist, rows, setRowsModal]);

    const [t] = useTranslation("global");

    const columns = [{ name: "conversion", title: t("subscription.conversion"), width: "10%" }];

    let success;
    let revision;
    let errConv;

    if (modalType === "bankStatement") {
        columns.push({ name: "file_name", title: t("converter.bankStatement"), width: "35%" });
        columns.push({ name: "period", title: t("payment.period"), align: "center", width: "20%" });
        if (isHist) {
            columns.push({ name: "status", title: t("converter.status"), width: "16%" });
        } else {
            columns.push({ name: "price", title: t("converter.price"), align: "center", width: "6%" });
            columns.push({ name: "action", title: "", width: "5%" });
        }
        success = rowsModal.filter((item) => item.status === 2 || item.status === 3).length;
        revision = rowsModal.filter((item) => item.status === 4).length;
        errConv = rowsModal.filter((item) => item.status === 5).length;
    } else if (modalType === "check") {
        if (isHist) {
            columns.push({ name: "check", title: t("converter.check"), width: "15%" });
            columns.push({ name: "contact", title: t("converter.contact"), width: "15%" });
            columns.push({ name: "date", title: t("payment.date"), width: "15%" });
            columns.push({ name: "status", title: t("converter.status"), width: "16%" });
        } else {
            columns.push({ name: "file_name", title: t("converter.bankStatement"), width: "35%" });
            columns.push({ name: "price", title: t("converter.price"), align: "center", width: "6%" });
            columns.push({ name: "action", title: "", width: "5%" });
        }
        success = rowsModal.filter((item) => item.status === 3 || item.status === 4).length;
        revision = rowsModal.filter((item) => item.status === 2).length;
        errConv = rowsModal.filter((item) => item.status === 10).length;
    } else {
        if (isHist) {
            columns.push({ name: "invoice", title: replaceInvBill(t("payment.invoice")), width: "15%" });
            columns.push({ name: "contact", title: t("converter.contact"), width: "15%" });
            columns.push({ name: "date", title: t("payment.date"), width: "15%" });
            columns.push({ name: "status", title: t("converter.status"), width: "16%" });
        } else {
            columns.push({ name: "file_name", title: t("converter.bankStatement"), width: "35%" });
            columns.push({ name: "price", title: t("converter.price"), align: "center", width: "6%" });
            columns.push({ name: "action", title: "", width: "5%" });
        }
        success = rowsModal.filter((item) => item.status === 3 || item.status === 4).length;
        revision = rowsModal.filter((item) => item.status === 2).length;
        errConv = rowsModal.filter((item) => item.status === 10).length;
    }
    
    const todos = useSelector((state) => state.value);

    // Function to find duplicated names
    function findDuplicatedNames(data) {
        const nameCount = {};
        const duplicates = [];

        // Count the occurrences of each name
        data.forEach((item) => {
            if (nameCount[item.file_name]) {
                nameCount[item.file_name]++;
            } else {
                nameCount[item.file_name] = 1;
            }
        });

        // Collect names that occur more than once
        for (const name in nameCount) {
            if (nameCount[name] > 1) {
                duplicates.push(name);
            }
        }

        return duplicates;
    }

    // Function to find duplicated names
    function findDuplicatedNamesChecks(data) {
        const nameIdMap = {};
        const duplicates = [];

        // Count the occurrences of each name and store the IDs
        data.forEach((item) => {
            if (!nameIdMap[item.file_name]) {
                nameIdMap[item.file_name] = new Set();
            }
            nameIdMap[item.file_name].add(item.id);
        });

        // Collect names that have more than one unique ID
        for (const name in nameIdMap) {
            if (nameIdMap[name].size > 1) {
                duplicates.push(name);
            }
        }

        return duplicates;
    }

    // Function to find duplicated periods
    function findDuplicatedPeriods(data) {
        const nameCount = {};
        const duplicates = [];

        // Count the occurrences of each name
        data.forEach((item) => {
            if (nameCount[item.period]) {
                nameCount[item.period]++;
            } else {
                nameCount[item.period] = 1;
            }
        });

        // Collect names that occur more than once
        for (const name in nameCount) {
            if (nameCount[name] > 1) {
                duplicates.push(name);
            }
        }

        return duplicates;
    }

    const duplicatedPeriods = findDuplicatedPeriods(rowsModal.filter((item) => item.period !== "-"));

    const preview = async (filename_s3, filename, id) => {
        setIsLoading(true);

        const data = await fetchFileFromS3(filename_s3);

        if (!data) {
            setTexto(t("converter.dnldError"));
            setAlertType("error");
            setShow(true);
        } else {
            if (getFileExtension(filename_s3) === "xlsx" || getFileExtension(filename_s3) === "xls") {
                const f = await data.arrayBuffer();

                /* parse workbook */
                const workbook = read(f, { type: "binary" });
                /* get first worksheet */
                const worksheet = workbook.Sheets[workbook.SheetNames[0]];
                /* generate and display HTML */
                const table = utils.sheet_to_html(worksheet);
                setHTML(table);
            }
            setFileData(data);
            setFileNameFull(filename_s3);
        }

        setIsLoading(false);

        if (isHist) {
            setBasePreviewData({
                id: id,
                type: modalType,
            });
        } else {
            setBasePreviewData({
                type: modalType,
                file_s3: filename_s3,
                file: filename,
            });
        }

        setOpenPreview(true);
    };

    const duplicatedNames = findDuplicatedNames(rowsModal);

    const duplicatedNamesChecks = findDuplicatedNamesChecks(rowsModal);
    
    const deleteFiles = async (id, filename, filenames3) => {
        if (filenames3 !== "") {
            const params = { uploaded_file: filenames3, type: modalType, company_id: todos.companyID, id: id };

            if (modalType === "bankStatement" && "groupedConversionId" in convInfo && convInfo.groupedConversionId !== undefined) {
                params.groupedConversionId = convInfo.groupedConversionId;
            }

            if (modalType === "bankStatement" && "statusByFile" in convInfo) {
                params.statusByFile = JSON.stringify(convInfo.statusByFile);
            }

            setIsLoading(true);

            await conversionService
                .deleteFileS3(params)
                .then(async (response) => {
                    setFileData(acceptedFiles.filter((item) => item.name !== filename));
                    setConvFormat(conversionFormats);
                    setAcceptedFiles(acceptedFiles.filter((item) => item.name !== filename));
                    let finalRows = rows.filter((item) => item.id !== id)
                    if (modalType === "bankStatement") {
                        finalRows = finalRows.map((item) => ({ ...item, number: response.data.correlatives.filter((item2) => item2.conversionId === item.id)[0].correlative }))
                    } else {
                        let firstCorrelativeRows = Math.min(...rows.map((item) => item.number))
                        // reordenar los correlativos de las rows
                        for (let i=0; i < finalRows.length; i++) {
                            finalRows[i].number = firstCorrelativeRows
                            firstCorrelativeRows++;
                        }
                    }
                    setRows(finalRows);
                    let finalRowsModal = rowsModal.filter((item) => item.id !== id)
                    if (modalType === "bankStatement") {
                        finalRowsModal = finalRowsModal.map((item) => ({ ...item, number: response.data.correlatives.filter((item2) => item2.conversionId === item.id)[0].correlative }))
                    } else {
                        let firstCorrelativeRows = Math.min(...rowsModal.map((item) => item.number))
                        // reordenar los correlativos de las rows
                        for (let i=0; i < finalRowsModal.length; i++) {
                            finalRowsModal[i].number = firstCorrelativeRows
                            firstCorrelativeRows++;
                        }
                    }
                    setRowsModal(finalRowsModal);
                    setRows(finalRows);
                    if (modalType === "bankStatement") {
                        let copyStatusByFile = convInfo
                        if (copyStatusByFile.statusByFile.length > 0) {
                            let statusByFile = copyStatusByFile.statusByFile.filter((item) => item.conversionId !== id)
                            if (statusByFile.length > 0) {
                                if (statusByFile.length === 1) {
                                    delete copyStatusByFile.groupedConversionId;
                                    statusByFile = statusByFile.map((item) => ({ ...item, correlative: response.data.correlatives.filter((item2) => item2.conversionId === item.conversionId)[0].correlative }))
                                    copyStatusByFile.statusByFile = statusByFile;
                                } else {
                                    copyStatusByFile.statusByFile = statusByFile;
                                }
                                setConvInfo(copyStatusByFile)
                            }
                        }
                    }

                    setS3FileName(s3FileName.filter((item) => item !== filenames3));

                    if (finalRows.length === 0) {
                        setOpenModalGrouped(false)
                        setFileData(null);
                        setAcceptedFiles([]);
                        setS3FileName([]);
                        setRows([]);
                        setRowsModal([]);
                        if (modalType === "bankStatement") {
                            setConvInfo({});
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setTexto(t("converter.errorUpload"));
                    setAlertType("error");
                    setShow(true);
                });

            setIsLoading(false);
        } else {
            let finalRows = rows.filter((item) => item.id !== id)
            setRows(finalRows);
            let finalRowsModal = rowsModal.filter((item) => item.id !== id)
            setRowsModal(finalRowsModal);
            if (finalRows.length === 0) {
                setOpenModalGrouped(false)
                setFileData(null);
                setAcceptedFiles([]);
                setS3FileName([]);
                setRows([]);
                setRowsModal([]);
                if (modalType === "bankStatement") {
                    setConvInfo({});
                }
            }
        }
    };

    return (
        <>
            <Stack direction="row" spacing={3}>
                <Typography sx={{ pl: 1 }}>
                    <b>{rowsModal.length}</b>{" "}
                    {modalType === "bankStatement"
                        ? t("converter.bankStatements")
                        : modalType === "check"
                        ? t("converter.checks")
                        : replaceInvBill(t("converter.invoices"))}
                </Typography>
                {success > 0 && (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography>{success}</Typography>
                        <CheckIcon fontSize="small" color="success" />
                    </Stack>
                )}
                {revision > 0 && (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography>{revision}</Typography>
                        <AccessTimeIcon fontSize="small" color="warning" />
                    </Stack>
                )}
                {errConv > 0 && (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography>{errConv}</Typography>
                        <CloseIcon fontSize="small" color="error" />
                    </Stack>
                )}
            </Stack>
            <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                    <TableContainer sx={{ maxHeight: 250 }}>
                        <Table
                            aria-label="simple table"
                            sx={{ borderCollapse: "separate", borderSpacing: "0 4px", minWidth: 1100 }}
                        >
                            <TableHead>
                                <StyledTableRow>
                                    {columns.map((item, index) => (
                                        <StyledTableCell
                                            key={item.name + index}
                                            align={item.align ?? "left"}
                                            style={{ width: item.width }}
                                        >
                                            {item.title}
                                        </StyledTableCell>
                                    ))}
                                </StyledTableRow>
                            </TableHead>
                            <TableBody>
                                {rowsModal.map((row) => (
                                    <StyledTableRow
                                        key={row.id}
                                        sx={{
                                            "& td:first-of-type": {
                                                border: 0,
                                                borderTopLeftRadius: 20,
                                                borderBottomLeftRadius: 20,
                                            },
                                            "& td:last-child": {
                                                borderTopRightRadius: 20,
                                                borderBottomRightRadius: 20,
                                            },
                                            backgroundColor: "#F8F8F9",
                                        }}
                                    >
                                        {modalType === "bankStatement" ? (
                                            <>
                                                <StyledTableCell>
                                                    <Stack direction="row" alignItems="center" sx={{ pl: 3 }}>
                                                        <Typography>{`#${zeroPad(row.number, 5)}`}</Typography>
                                                        {duplicatedNames.includes(row.file_name) && (
                                                            <LightTooltip
                                                                title={`${t("converter.thisConv1")} ${
                                                                    rowsModal.find(
                                                                        (item) =>
                                                                            item.file_name === row.file_name &&
                                                                            item.id !== row.id
                                                                    ).number
                                                                }`}
                                                            >
                                                                <ReportProblemIcon color="error" fontSize="small" />
                                                            </LightTooltip>
                                                        )}
                                                    </Stack>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <Button
                                                        onClick={() => preview(row.file_name_s3, row.file_name, row.id)}
                                                    >
                                                        {row.file_name}
                                                    </Button>
                                                </StyledTableCell>
                                                <StyledTableCell align="center">
                                                    <Stack direction="row" alignItems="center" justifyContent="center">
                                                        {row.period}
                                                        {duplicatedPeriods.includes(row.period) && (
                                                            <LightTooltip
                                                                title={`${t("converter.thisConv2")} ${
                                                                    rowsModal.find(
                                                                        (item) =>
                                                                            item.period === row.period &&
                                                                            item.id !== row.id
                                                                    ).number
                                                                }`}
                                                            >
                                                                <ReportProblemIcon color="error" fontSize="small" />
                                                            </LightTooltip>
                                                        )}
                                                    </Stack>
                                                </StyledTableCell>
                                                {isHist ? (
                                                    <>
                                                        <StyledTableCell>
                                                            <Stack direction="row" alignItems="center">
                                                                {(row.status === 2 || row.status === 3) && (
                                                                    <CheckIcon fontSize="small" color="success" />
                                                                )}
                                                                {row.status === 4 && (
                                                                    <AccessTimeIcon fontSize="small" color="warning" />
                                                                )}
                                                                {row.status === 5 && (
                                                                    <CloseIcon fontSize="small" color="error" />
                                                                )}
                                                                {row.status === 2 || row.status === 3
                                                                    ? t("converter.successfully")
                                                                    : row.status === 4
                                                                    ? t("converter.inReview")
                                                                    : row.status === 1
                                                                    ? t("converter.toConvert")
                                                                    : t("converter.invalid")}
                                                            </Stack>
                                                        </StyledTableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <StyledTableCell align="center">{row.price}</StyledTableCell>
                                                        <StyledTableCell>
                                                            <IconButton
                                                                color="primary"
                                                                size="small"
                                                                onClick={() =>
                                                                    deleteFiles(row.id, row.file_name, row.file_name_s3)
                                                                }
                                                            >
                                                                <CloseIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </>
                                                )}
                                            </>
                                        ) : modalType === "check" ? (
                                            <>
                                                {isHist ? (
                                                    <>
                                                        <StyledTableCell>
                                                            <Stack direction="row" alignItems="center" sx={{ pl: 3 }}>
                                                                <Typography>{`#${zeroPad(row.number, 5)}`}</Typography>
                                                            </Stack>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">
                                                            <Stack direction="row" alignItems="center">
                                                                <Button
                                                                    onClick={() =>
                                                                        preview(row.file_name_s3, row.file_name, row.id)
                                                                    }
                                                                >
                                                                    {row.check}
                                                                </Button>
                                                                {repeatedConv.filter((item) => item.check_pay_to === row.contact && item.check_number === row.check).length > 0 && (
                                                                    <LightTooltip
                                                                        title={`${t("converter.repeatedConvCheck1")} ${
                                                                            repeatedConv.filter((item) => item.check_pay_to === row.contact && item.check_number === row.check).map((item) => `#${zeroPad(item.correlative, 5)}`).join(", ")
                                                                        }. ${t("converter.repeatedConvCheck2")}`}
                                                                    >
                                                                        <WarningIcon sx={{ color: "#FFB23F", fontSize: "20px" }} />
                                                                    </LightTooltip>
                                                                )}
                                                            </Stack>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.contact}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.date}</StyledTableCell>
                                                        <StyledTableCell>
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="left"
                                                            >
                                                                {(row.status === 1 || row.status === 5) && (
                                                                    <img src={Loading} alt="loading" width={20} />
                                                                )}
                                                                {row.status === 2 && (
                                                                    <AccessTimeIcon
                                                                        sx={{ color: "#FFB23F" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {row.status === 3 && (
                                                                    <UploadFileIcon
                                                                        sx={{ color: "#4a22d4" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {row.status === 4 && (
                                                                    <DownloadIcon
                                                                        sx={{ color: "#4a22d4" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {(row.status === 6 || row.status === 7) && (
                                                                    <UploadFileIcon
                                                                        sx={{ color: "#34bd6a" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {row.status === 8 && (
                                                                    <UploadFileIcon
                                                                        sx={{ color: "#FFB23F" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {row.status === 9 && (
                                                                    <DownloadIcon
                                                                        sx={{ color: "#34bd6a" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {row.status === 10 && (
                                                                    <CloseIcon
                                                                        sx={{ color: "#DA0069" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                &nbsp;
                                                                <Typography>
                                                                    {row.status === 1
                                                                        ? t("converter.inProc")
                                                                        : row.status === 2
                                                                        ? t("converter.inReview")
                                                                        : row.status === 3
                                                                        ? t("converter.toBeSynch")
                                                                        : row.status === 4
                                                                        ? t("converter.convertible")
                                                                        : row.status === 5
                                                                        ? t("converter.synchronizing")
                                                                        : row.status === 6
                                                                        ? t("converter.synchronized")
                                                                        : row.status === 7
                                                                        ? t("converter.manualReg")
                                                                        : row.status === 8
                                                                        ? t("converter.partialSync")
                                                                        : row.status === 9
                                                                        ? t("converter.downloaded")
                                                                        : t("converter.invalid2")}
                                                                </Typography>
                                                            </Stack>
                                                        </StyledTableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <StyledTableCell>
                                                            <Stack direction="row" alignItems="center" sx={{ pl: 3 }}>
                                                                <Typography>{`#${zeroPad(row.number, 5)}`}</Typography>
                                                                {duplicatedNamesChecks.includes(row.file_name) && (
                                                                    <LightTooltip
                                                                        title={`${t("converter.thisConv1")} ${
                                                                            rowsModal.find(
                                                                                (item) =>
                                                                                    item.file_name === row.file_name &&
                                                                                    item.id !== row.id
                                                                            ).number
                                                                        }`}
                                                                    >
                                                                        <ReportProblemIcon color="error" fontSize="small" />
                                                                    </LightTooltip>
                                                                )}
                                                            </Stack>
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <Button
                                                                onClick={() =>
                                                                    preview(row.file_name_s3, row.file_name, row.id)
                                                                }
                                                            >
                                                                {row.file_name}
                                                            </Button>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row.status !== 200 ? t("converter.invalid") : row.price}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <IconButton
                                                                color="primary"
                                                                size="small"
                                                                onClick={() =>
                                                                    deleteFiles(row.id, row.file_name, row.file_name_s3)
                                                                }
                                                            >
                                                                <CloseIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <StyledTableCell>
                                                    <Stack direction="row" alignItems="center" sx={{ pl: 3 }}>
                                                        <Typography>{`#${zeroPad(row.number, 5)}`}</Typography>
                                                        {duplicatedNamesChecks.includes(row.file_name) && (
                                                            <LightTooltip
                                                                title={`${t("converter.thisConv1")} ${
                                                                    rowsModal.find(
                                                                        (item) =>
                                                                            item.file_name === row.file_name &&
                                                                            item.id !== row.id
                                                                    ).number
                                                                }`}
                                                            >
                                                                <ReportProblemIcon color="error" fontSize="small" />
                                                            </LightTooltip>
                                                        )}
                                                    </Stack>
                                                </StyledTableCell>
                                                {isHist ? (
                                                    <>
                                                        <StyledTableCell align="left">
                                                            <Button
                                                                onClick={() =>
                                                                    preview(row.file_name_s3, row.file_name, row.id)
                                                                }
                                                            >
                                                                {row.invoice}
                                                            </Button>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="left">{row.contact}</StyledTableCell>
                                                        <StyledTableCell align="left">{row.date}</StyledTableCell>
                                                        <StyledTableCell>
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="left"
                                                            >
                                                                {(row.status === 1 || row.status === 5) && (
                                                                    <img src={Loading} alt="loading" width={20} />
                                                                )}
                                                                {row.status === 2 && (
                                                                    <AccessTimeIcon
                                                                        sx={{ color: "#FFB23F" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {row.status === 3 && (
                                                                    <UploadFileIcon
                                                                        sx={{ color: "#4a22d4" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {row.status === 4 && (
                                                                    <DownloadIcon
                                                                        sx={{ color: "#4a22d4" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {(row.status === 6 || row.status === 7) && (
                                                                    <UploadFileIcon
                                                                        sx={{ color: "#34bd6a" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {row.status === 8 && (
                                                                    <UploadFileIcon
                                                                        sx={{ color: "#FFB23F" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {row.status === 9 && (
                                                                    <DownloadIcon
                                                                        sx={{ color: "#34bd6a" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                {(row.status === 10 || row.status === 11) && (
                                                                    <CloseIcon
                                                                        sx={{ color: "#DA0069" }}
                                                                        fontSize="inherit"
                                                                    />
                                                                )}
                                                                &nbsp;
                                                                <Typography>
                                                                    {row.status === 1
                                                                        ? t("converter.inProc")
                                                                        : row.status === 2
                                                                        ? t("converter.inReview")
                                                                        : row.status === 3
                                                                        ? t("converter.toBeSynch")
                                                                        : row.status === 4
                                                                        ? t("converter.convertible")
                                                                        : row.status === 5
                                                                        ? t("converter.synchronizing")
                                                                        : row.status === 6
                                                                        ? t("converter.synchronized")
                                                                        : row.status === 7
                                                                        ? t("converter.manualReg")
                                                                        : row.status === 8
                                                                        ? t("converter.partialSync")
                                                                        : row.status === 9
                                                                        ? t("converter.downloaded")
                                                                        : row.status === 10
                                                                        ? t("converter.invalid2")
                                                                        : t("navBar.cancelled")}
                                                                </Typography>
                                                            </Stack>
                                                        </StyledTableCell>
                                                    </>
                                                ) : (
                                                    <>
                                                        <StyledTableCell>
                                                            <Button
                                                                onClick={() =>
                                                                    preview(row.file_name_s3, row.file_name, row.id)
                                                                }
                                                            >
                                                                {row.file_name}
                                                            </Button>
                                                        </StyledTableCell>
                                                        <StyledTableCell align="center">
                                                            {row.status !== 200 ? t("converter.invalid") : row.price}
                                                        </StyledTableCell>
                                                        <StyledTableCell>
                                                            <IconButton
                                                                color="primary"
                                                                size="small"
                                                                onClick={() =>
                                                                    deleteFiles(row.id, row.file_name, row.file_name_s3)
                                                                }
                                                            >
                                                                <CloseIcon fontSize="inherit" />
                                                            </IconButton>
                                                        </StyledTableCell>
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </StyledTableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                {!isHist && (
                    <Grid item xs={12} md={11}>
                        <UploadFile
                            setFileData={setFileData}
                            acceptedFiles={acceptedFiles}
                            setAcceptedFiles={setAcceptedFiles}
                            formats={formats}
                            disabled={false}
                            setTexto={setTexto}
                            setAlertType={setAlertType}
                            setShow={setShow}
                            setS3FileName={setS3FileName}
                            s3FileName={s3FileName}
                            setConvFormat={setConvFormat}
                            conversionFormats={conversionFormats}
                            isModal={true}
                            setRows={setRows}
                            rows={rows}
                            accountId={accountId}
                            setProgress={setProgress}
                            progress={progress}
                            setConvInfo={setConvInfo}
                            setGroupedData={setGroupedData}
                            type={modalType}
                            setRowsModal={setRowsModal}
                            rowsModal={rowsModal}
                            setShowRepeatModal={setShowRepeatModal}
                            setShowRepeatVal={setShowRepeatVal}
                        />
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default GroupedContent;
