import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { initiateSocket, subscribeToChat, newUserResponse } from "../services/Socket";
import introJs from "intro.js";

/* Componentes */
import BackGeneral from "../components/layout/BackGeneral";
import AccountSetupTable from "../components/accounts/AccountSetupTable";
import accountsService from "../services/accounts";
import caseService from "../services/case";
import PendingsAccounts from "../components/accounts/PendingsAccounts";
import ChatModal from "../components/ui/Chat";

/* MUI Imports */
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import BaseSnackbar from "../components/ui/BaseSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { addAccounts, addTutorial, dontShowAgainTutorial } from "../redux/userSlice";

import companyService from "../services/company";
import casesServices from "../services/case";
import LightTooltip from "../components/ui/LightTooltip";

// import { usePlaidLink } from "react-plaid-link";

const Accounts = () => {
    const todos = useSelector((state) => state.value);

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /* React redux */
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);
    const [accounts, setAccounts] = useState([]);
    const [currenciesFilters, setCurrenciesFilters] = useState([]);
    const [countriesFilters, setCountriesFilters] = useState([]);
    const [banksFiltersAux, setBanksFiltersAux] = useState([]);
    const [banksFiltersConfig, setBanksFiltersConfig] = useState([]);
    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [integration, setIntegration] = useState(null);
    const [showSkeleton, setShowSkeleton] = useState(false);
    const [room, setRoom] = useState(null);
    const [open, setOpen] = useState(false);
    const [chat, setChat] = useState([]);
    const [users, setUsers] = useState([]);
    const [caseInfo, setCaseInfo] = useState({});
    const [disabledCount, setdisabledCount] = useState(0);

    const connectSocket = async (case_id) => {
        setRoom(case_id);

        let params1 = {
            case_id: case_id,
        };

        await casesServices
            .getCaseInfo(params1)
            .then(async (data) => {
                setCaseInfo(data.caseInfo)

                initiateSocket(case_id, todos.userInfo, "general", "");
                subscribeToChat((err, data) => {
                    if (err) return;
                    setChat((oldChats) => [...oldChats, data]);
                });
                newUserResponse((err, data) => {
                    if (err) return;
                    setUsers(data);
                });
                setOpen(true);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const handleCloseSnackBar = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    const reloadAcc = async () => {
        setShowSkeleton(true);
        await accountsService
            .getAccounts({ company_id: todos.companyID, language: sessionStorage.getItem("lng") })
            .then(async (response) => {
                setAccounts(response);
                setdisabledCount(response.disabledCount);
                dispatch(addAccounts(response));
                await accountsService
                    .getBanks()
                    .then((data) => {
                        setBanksFiltersConfig(
                            data.filter((item) => response.setup.some((item2) => item2.bank_id === item._id))
                        );
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                await accountsService
                    .getCurrencies()
                    .then((data) => setCurrenciesFilters(data))
                    .catch((error) => {
                        console.log(error);
                    });
                await accountsService
                    .getCountries()
                    .then((data) => setCountriesFilters(data))
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((err) => {
                console.log(err);
            });
        setShowSkeleton(false);
    };

    useEffect(() => {
        (async () => {
            setShowSkeleton(true);

            await accountsService
                .getAccounts({ company_id: todos.companyID, language: sessionStorage.getItem("lng") })
                .then(async (accounts) => {
                    setAccounts(accounts);
                    setdisabledCount(accounts.disabledCount);
                    dispatch(addAccounts(accounts));

                    await accountsService
                        .getBanks()
                        .then((data) => {
                            setBanksFiltersAux(data);
                            setBanksFiltersConfig(
                                data.filter((item) => accounts.setup.some((item2) => item2.bank_id === item._id))
                            );
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                })
                .catch((err) => {
                    console.log(err);
                });

            await accountsService
                .getCurrencies()
                .then((data) => setCurrenciesFilters(data))
                .catch((error) => {
                    console.log(error);
                });
            await accountsService
                .getCountries()
                .then((data) => setCountriesFilters(data))
                .catch((error) => {
                    console.log(error);
                });

            setShowSkeleton(false);
        })();
    }, [dispatch, todos.companyID]);

    useEffect(() => {
        (async () => {
            if (!todos.isGrouped) {
                if (todos.companyID) {
                    await companyService
                        .getCompanyDetails(todos.companyID)
                        .then((data) => {
                            setIntegration(data.system_integration);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                } else {
                    setIntegration(null);
                }
            }
        })();
    }, [todos.companyID, todos.franchiseID, todos.allComData, todos.isGrouped]);

    const handleClickHelp = async () => {
        setIsLoading(true);

        let params = {
            company_id: todos.companyID,
            franchise_id: todos.franchiseID,
            user_email: todos.userInfo.email,
            type: "banks",
        };

        await caseService
            .createNewCase(params)
            .then(async(response) => {
                await connectSocket(response._id);
            })
            .catch((err) => {
                console.log(err);
            });
        setIsLoading(false);
    };

    const openTutorial = () => {
        dispatch(dontShowAgainTutorial(false))

        const defaultTut = {
            step1: false,
            step2: false,
            step4: false,
            step5: false,
            step6: false,
            step7: false,
            step8: false,
            step9: false,
        };

        let isDone = false;

        dispatch(addTutorial(defaultTut));

        introJs()
            .setOptions({
                disableInteraction: true,
                exitOnOverlayClick: false,
                keyboardNavigation: false,
                nextLabel: t("dialog.continue"),
                prevLabel: t("miscellaneous.goBack"),
                doneLabel: t("miscellaneous.accept"),
                steps: [
                    {
                        title: `${t("miscellaneous.step")} 3`,
                        element: "#account-tutorial",
                        intro: t("miscellaneous.tutorialAcc1"),
                    },
                ],
            })
            .oncomplete(() => {
                isDone = true;
                dispatch(addTutorial({ ...todos.steps, step3: false, step4: true }));
            })
            .onexit(() => {
                if (!isDone) {
                    dispatch(addTutorial({ ...todos.steps, step3: false }));
                }
            })
            .start();

    };

    // Manejar clic de botón de "X" en Intro.js
    useEffect(() => {
        const handleButtonClick = (event) => {
            if (event.target.classList.contains('introjs-skipbutton')) {
                dispatch(dontShowAgainTutorial(true))
            }
        };

        document.addEventListener('click', handleButtonClick);

        // Limpiar event listener al desmontar el componente
        return () => {
            document.removeEventListener('click', handleButtonClick);
        };
    }, [dispatch]);

    return (
        <BackGeneral colorBg="#fff">
            <Container maxWidth="xl">
                <Box sx={{ p: { xs: 0, md: 3 } }}>
                    <Typography fontSize={24} fontWeight={600} sx={{ mb: 1, ml: 1, pt: { xs: 2, md: 0 } }}>
                        {t("accounts.bankAccounts")}{" "}
                        <LightTooltip title={t("miscellaneous.help")}>
                            <IconButton onClick={openTutorial}>
                                <HelpIcon color="primary" />
                            </IconButton>
                        </LightTooltip>
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={4} lg={3}>
                            <PendingsAccounts
                                pendingAccounts={accounts.pending}
                                archivedAccounts={accounts.archived}
                                accounts={accounts.setup}
                                setReload={reloadAcc}
                                setIsLoading={setIsLoading}
                                countriesFilters={countriesFilters}
                                currenciesFilters={currenciesFilters}
                                banksFilters={banksFiltersAux}
                                setAlertType={setAlertType}
                                setTexto={setTexto}
                                setShow={setShow}
                                companyID={todos.companyID}
                                isLoading={isLoading}
                                showSkeleton={showSkeleton}
                                setShowSkeleton={setShowSkeleton}
                                handleClickHelp={handleClickHelp}
                            />
                        </Grid>
                        <Grid item xs={12} md={8} lg={9}>
                            <AccountSetupTable
                                accounts={accounts.setup}
                                setAccounts={setAccounts}
                                setReload={reloadAcc}
                                setAlertType={setAlertType}
                                setTexto={setTexto}
                                setShow={setShow}
                                banksFilters={banksFiltersAux}
                                banksFiltersConfig={banksFiltersConfig}
                                currenciesFilters={currenciesFilters}
                                countriesFilters={countriesFilters}
                                companyID={todos.companyID}
                                franchiseID={todos.franchiseID}
                                grupal={todos.isGrouped}
                                integration={integration}
                                showSkeleton={showSkeleton}
                                setShowSkeleton={setShowSkeleton}
                                allAccounts={accounts}
                                setCountriesFilters={setCountriesFilters}
                                setCurrenciesFilters={setCurrenciesFilters}
                                setBanksFiltersConfig={setBanksFiltersConfig}
                                handleClickHelp={handleClickHelp}
                                disabledCount={disabledCount}
                                setdisabledCount={setdisabledCount}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Container>
            <BaseSnackbar type={alertType} show={show} message={texto} onClose={handleCloseSnackBar} />
            {open && <ChatModal
                room={room}
                setRoom={setRoom}
                open={open}
                setOpen={setOpen}
                setChat={setChat}
                chat={chat}
                type={2}
                users={users}
                franchiseName={
                    todos.allComData?.find(
                        (franchise) => franchise.franchise_name && franchise._id === todos.franchiseID
                    )?.franchise_name
                }
                setCaseInfo={setCaseInfo}
                caseInfo={caseInfo}
            />}
        </BackGeneral>
    );
};

export default Accounts;
