/** React imports */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

/** MUI imports */
import {
    Box,
    Stepper,
    Step,
    StepButton,
    Typography,
    Stack,
    Button,
} from "@mui/material";
import ExclamationIcon from "../../../assets/exclamation-icon.svg";

/** Js imports **/
import companyService from "../../../services/company";
import userCompanyService from "../../../services/user_company";
import authService from "../../../services/auth";
import BasicInfoStep from "../../company/AddCompanyStep1";
import ButtonsStep from "../../company/AddCompanyButtons";
import { useSearchParams } from "react-router-dom";
import {
    addIsGrouped,
    addCompanyID,
    addAllComData,
    addSubscription,
    addCredits
} from "../../../redux/userSlice";
import { useDispatch, useSelector } from "react-redux";
import PlanSubs from "../../subscription/plans/PlansMain";
import ShoppingCartReg from "../../subscription/shoppingCart/ShoppingCartReg";
import AlertDialog from "../../ui/AlertDialog";
import Integrations from "../../subscription/Integrations";
import { getCompanyCredits, updateCartPlan } from "../../../utils/utils";
import CustomForm from "../../subscription/CustomForm";
import DiscountCartBanner from "../../subscription/DiscountCartBanner";
import plansInfo from "../../Js/plans";

const systemsList = ["Xero", "QuickBooks desktop", "QuickBooks online", "Otro"];

const AddCompany = (props) => {
    const {
        onClose,
        setReload,
        setIsLoading,
        franchiseID,
        setShow,
        setTexto,
        setAlertType,
        addNew,
        franchiseInfo,
        activeStep,
        setActiveStep,
        setSmbInfo,
        smbInfo,
        companyInfo,
        setCompanyInfo,
        allCompanies,
        currentFreePlan,
        maxFreePlan,
        franchiseSubsType,
        handleClickHelp
    } = props;

    const [t] = useTranslation("global");

    const steps = [
        t("dialog.companyData"),
        t("plans.choosePlan"),
        t("subscription.integ"),
    ];

    const [integ, setInteg] = useState(3);
    const [isError, setIsError] = useState(false);

    const [searchParams, setSearchParams] = useSearchParams();

    const dispatch = useDispatch();

    /** Navigate */
    const navigate = useNavigate();

    const [isErrorName, setIsErrorName] = useState(false);

    const todos = useSelector((state) => state.value);

    const [openAlert, setOpenAlert] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [bodyMess, setBodyMess] = useState("");
    const [agreeBtnMsg, setAgreeBtnMsg] = useState(t("dialog.continue"));
    const [disagreeBtnMsg, setDisagreeBtnMsg] = useState(t("dialog.cancel"));
    const [questionCustom, setQuestionCustom] = useState({
        question1: "",
        question2: "",
        question3: "",
    });
    const [currentPlan, setCurrentPlan] = useState({
        value: 1,
        custom: false,
    });
    const [paymentType, setPaymentType] = useState("monthly");
    const [currentLevel, setCurrentLevel] = useState("");
    const [priceMonthly, setPriceMonthly] = useState("");
    const [priceMonthlyOG, setPriceMonthlyOG] = useState("");
    const [showCart, setShowCart] = useState(false);
    const [errorCustomPlan, setErrorCustomPlan] = useState(false);

    const companyTypeList = [
        { id: 2, name: t("dialog.smb") },
        { id: 1, name: t("dialog.firm") },
    ];

    const handleCloseDialog = () => {
        setQuestionCustom({
            question1: "",
            question2: "",
            question3: "",
        });
        setOpenAlert(false);
        setErrorCustomPlan(false)
    };

    const handleNext = async () => {
        setIsLoading(true);
        setShowCart(false);
        if (activeStep === 0) {
            if (
                companyInfo.companyType &&
                companyInfo.companyName.trim() &&
                companyInfo.country &&
                companyInfo.systems.length > 0 &&
                companyInfo.ecActivity &&
                isErrorName === false
            ) {
                // Agregar SMB
                if (companyInfo.id) {
                    // Update
                    const params = {
                        company_id: companyInfo.id,
                        company_name: companyInfo.companyName,
                        country_name: companyInfo.country,
                        client_type: companyInfo.companyType,
                        business_activity: companyInfo.ecActivity,
                        franchise_id: companyInfo.franchise,
                    };

                    await companyService
                        .updateCompany(params)
                        .then((response) => {
                            setActiveStep(
                                (prevActiveStep) => prevActiveStep + 1
                            );
                            setSmbInfo(response)
                        })
                        .catch((err) => {
                            console.log(err);
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        });
                } else {
                    // Add
                    const params = {
                        company_name: companyInfo.companyName,
                        country_name: companyInfo.country,
                        client_type: 1,
                        business_activity: companyInfo.ecActivity,
                        file_formats: companyInfo.systems,
                        franchise_id: franchiseID,
                    };

                    await companyService
                        .addNewCompany(params)
                        .then((response) => {
                            setActiveStep(
                                (prevActiveStep) => prevActiveStep + 1
                            );
                            setCompanyInfo({ ...companyInfo, id: response._id });
                            setSmbInfo(response);
                        })
                        .catch((err) => {
                            console.log(err);
                            setTexto("Error");
                            setAlertType("error");
                            setShow(true);
                        });
                }
            } else {
                setIsError(true);
            }
        }
        if (activeStep === 1) {
            if (integ) {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
        } else {
            setIsError(true);
        }
        setIsLoading(false);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleChange = (event) => {
        setCompanyInfo({
            ...companyInfo,
            [event.target.name]: event.target.value,
        });
    };

    const handleChangeCompanyName = async (event) => {
        setCompanyInfo({
            ...companyInfo,
            [event.target.name]: event.target.value,
        });

        const params = {
            franchise_id: franchiseID,
            company_name: event.target.value,
            company_id: companyInfo.id ? companyInfo.id : undefined,
        };

        await companyService
            .validateCompanyName(params)
            .then((data) => {
                if (data === true) {
                    setIsErrorName(true);
                } else {
                    setIsErrorName(false);
                }
            })
            .catch((err) => {
                console.log(err);
                setTexto("Error");
                setAlertType("error");
                setShow(true);
            });
    };

    const createCompany = async () => {
        setIsLoading(true);
        if (
            companyInfo.companyType &&
            companyInfo.companyName.trim() &&
            companyInfo.country &&
            companyInfo.systems.length &&
            companyInfo.ecActivity &&
            integ
        ) {
            const params = {
                company_id: companyInfo.id,
                company_name: companyInfo.companyName,
                country_name: companyInfo.country,
                client_type: 1,
                business_activity: companyInfo.ecActivity,
                file_formats: companyInfo.systems,
                system_integration: integ,
                franchise_id: franchiseID,
            };

            await companyService
                .updateCompany(params)
                .then(async (data) => {

                    handleClose(true);
                    setAlertType("success");
                    setTexto(t("dialog.successAddSmb"));
                    setShow(true);
                    setReload((prevState) => !prevState);

                    dispatch(addSubscription({
                        value: data.id_status,
                        last_value: data.id_last_status,
                        plan: data.subscription.subscription_plan,
                    }))

                    const credits = await getCompanyCredits(data._id);
                    dispatch(addCredits(credits));

                    const result = await userCompanyService.getCompanies();
                    dispatch(addAllComData(result.records));
                    dispatch(addIsGrouped(false));
                    dispatch(addCompanyID(data._id));

                    if (todos.selected === "ddlSalesInv") {
                        navigate(`/convert/invoices`);
                    } else if (todos.selected === "ddlPurchaseInv") {
                        navigate(`/convert/bills`);
                    } else {
                        navigate("/dashboard");
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setTexto("Error");
                    setAlertType("error");
                    setShow(true);
                });
        } else {
            setIsError(true);
        }

        setIsLoading(false);
    };

    const handleSkip = () => {
        if (activeStep === 1) {
            setActiveStep(0);
        } else {
            handleClose(false);
        }
    };

    const handleClose = async (isCreated) => {
        searchParams.delete("connected");
        searchParams.delete("connect");
        searchParams.delete("company_id");
        searchParams.delete("redirectType");
        setSearchParams(searchParams);

        if (companyInfo.id && !isCreated) {
            setIsLoading(true);

            await companyService
                .deleteNewCompany({ company_id: companyInfo.id })
                .then(() => setReload((prevState) => !prevState))
                .catch((err) => {
                    console.log(err.message);
                    setTexto("Error");
                    setAlertType("error");
                    setShow(true);
                });

            setIsLoading(false);
        }

        onClose();
        setActiveStep(0);
        setInteg(3);
        setIsError(false);
        setCompanyInfo({
            id: null,
            companyType: 2, // 2: SMB | 1: Firma
            companyName: "",
            country: "",
            systems: [],
            ecActivity: "",
        });
        setIsErrorName(false);
    };

    const showStep3 = () => {
        setShowCart(false);
    };

    const handleShowCart = async (e, planId = null) => {
        handleCloseDialog(false);
        const finalPlan = planId !== null ? planId : currentLevel
        await updateCartPlan(1, finalPlan, paymentType, smbInfo, setIsLoading, allCompanies.length, "month")

        setShowCart(true);
    };

    const handleSubmitCustomPlan = async () => {
        if (
            questionCustom.question1 === "" ||
            questionCustom.question2 === "" ||
            questionCustom.question3 === ""
        ) {
            setAlertType("error");
            setShow(true);
            setTexto(t("register.requiredData"));
            setErrorCustomPlan(true)
        } else {
            setErrorCustomPlan(false)
            handleCloseDialog()
            setIsLoading(true)

            const paramsC = {
                company_id: smbInfo._id,
                planId: plansInfo.flatMap((item) => item.levels).find((item) => item?.custom === true).id,
                origin_form: "addCompany",
                origin: 2,
                question1: questionCustom.question1,
                question2: questionCustom.question2,
                question3: questionCustom.question3,
                user_email: todos?.userInfo?.email
            }

            // Se agrega free trial del plan 11
            await authService
                .addFreeTrialPlan(paramsC)
                .then(async () => {
                    await createCompany()
                })
                .catch((error) => console.log(error.response));

            setIsLoading(false)
        }
    };

    const updateFreePlan = async () => {
        handleCloseDialog();
        setIsLoading(true)
        await authService
            .addFreeTrialPlan({ company_id: smbInfo._id, planId: currentLevel, origin_form: "addCompany", user_email: todos?.userInfo?.email })
            .then(async () => {
                await createCompany()
            })
            .catch((error) => console.log(error.response));
            setIsLoading(false)
    }

    const finalF = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        showStep3();
    }

    const updateMicroPlan = async () => {
        handleCloseDialog();
        setIsLoading(true)
        await authService
            .addMicroPlan({ company_id: smbInfo._id, origin: "addCompany", user_email: todos?.userInfo?.email })
            .then(async () => {
                await createCompany()
            })
            .catch((error) => console.log(error.response));
        setIsLoading(false)
    }

    return (
        <>
            <Box
                sx={{
                    borderRadius: "8px",
                    px: { xs: 3, md: 10 },
                    py: { xs: 3, md: 4 },
                    minWidth: "980px",
                    backgroundColor: "#FFF",
                }}
            >
                <Stack
                    direction="row"
                    justifyContent="start"
                    alignItems="center"
                >
                    {addNew && (
                        <Box
                            component="img"
                            sx={{
                                height: 28,
                                width: 28,
                                maxHeight: { xs: 28, md: 28 },
                                maxWidth: { xs: 28, md: 28 },
                                zIndex: 999,
                                mr: 0.8,
                            }}
                            src={ExclamationIcon}
                        />
                    )}
                    <Typography variant="h1" fontSize={24} fontWeight={600}>
                        {t("dialog.addCompanyFranchise")}{" "}
                        <Box
                            component="span"
                            sx={{
                                color: "#4A22D4",
                            }}
                        >
                            {franchiseInfo.franchise_name}
                        </Box>
                    </Typography>
                </Stack>
                <Box sx={{ width: "100%" }}>
                    <Stepper
                        activeStep={activeStep}
                        sx={{
                            pt: 4,
                            mx: 4,
                        }}
                        orientation={
                            window.innerWidth < 600 ? "vertical" : "horizontal"
                        }
                    >
                        {steps.map((label, index) => {
                            const stepProps = {};
                            return (
                                <Step
                                    key={label}
                                    {...stepProps}
                                    sx={{
                                        "& .MuiStepLabel-root .Mui-completed": {
                                            color: "#031851", // circle color (COMPLETED)
                                            borderRadius: "50%",
                                        },
                                        "& .MuiStepLabel-root .Mui-active": {
                                            color: "#031851", // circle color (ACTIVE)
                                            borderRadius: "50%",
                                        },
                                        ".MuiSvgIcon-root": {
                                            color: "rgba(0, 0, 0, 0.38)",
                                            borderRadius: "50%",
                                        },
                                    }}
                                    /*MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiStepIcon-root css-jl6sxv-MuiSvgIcon-root-MuiStepIcon-root*/
                                >
                                    <StepButton
                                        color="inherit"
                                        onClick={handleStep(index)}
                                    >
                                        <Typography
                                            fontStyle="normal"
                                            fontSize={18}
                                            fontWeight={600}
                                            sx={{ color: "#131F3E", mx: 1 }}
                                        >
                                            {label}
                                        </Typography>
                                    </StepButton>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <>
                        {activeStep === 0 && (
                            <BasicInfoStep
                                isError={isError}
                                smbInfo={companyInfo}
                                companyTypeList={companyTypeList}
                                handleChange={handleChange}
                                systemsList={systemsList}
                                handleChangeCompanyName={
                                    handleChangeCompanyName
                                }
                                isErrorName={isErrorName}
                            />
                        )}
                        {activeStep === 1 && !showCart &&
                            <Box mt={3}>
                                <PlanSubs
                                    setTitleModal={setTitleModal}
                                    setOpenAlert={setOpenAlert}
                                    setAgreeBtnMsg={setAgreeBtnMsg}
                                    setDisagreeBtnMsg={setDisagreeBtnMsg}
                                    setBodyMess={setBodyMess}
                                    setCurrentPlan={setCurrentPlan}
                                    paymentType={paymentType}
                                    setPaymentType={setPaymentType}
                                    setCurrentLevel={setCurrentLevel}
                                    setPriceMonthly={setPriceMonthly}
                                    setPriceMonthlyOG={setPriceMonthlyOG}
                                    currentLevel={currentLevel}
                                    priceMonthly={priceMonthly}
                                    origin="addCompany"
                                    currentPlan={currentPlan}
                                    handleShowCart={handleShowCart}
                                    maxFreeCompanies={maxFreePlan}
                                    currentFreeCompanies={currentFreePlan}
                                    subsType={franchiseSubsType}
                                />
                            </Box>
                        }
                        {activeStep === 2 && (
                            <Box mt={3}>
                                <Integrations
                                    smbInfo={smbInfo}
                                    setTexto={setTexto}
                                    setShow={setShow}
                                    setAlertType={setAlertType}
                                    integ={integ}
                                    setInteg={setInteg}
                                    origin="company"
                                />
                            </Box>
                        )}
                        {!(activeStep === 1) && (
                            <ButtonsStep
                                activeStep={activeStep}
                                steps={steps}
                                onClose={handleSkip}
                                handleNext={handleNext}
                                createCompany={createCompany}
                                isErrorName={isErrorName}
                                smbInfo={companyInfo}
                                hiddenSkip={true}
                                showButtonInt={integ === 3 && activeStep === 2}
                            />
                        )}
                    </>
                </Box>
            </Box>
            {activeStep === 1 && showCart && (
                <>
                    <Stack
                        direction="row"
                        spacing={2}
                        justifyContent="space-between"
                        mt={2}
                    >
                        <Button
                            variant="outlined"
                            onClick={showStep3}
                            sx={{ width: 170 }}
                        >
                            {t("miscellaneous.goBack")}
                        </Button>
                        <Button onClick={() => handleClickHelp("generalConv", "", "")}>{t("accounts.needHelp")}</Button>
                    </Stack>
                    {allCompanies.length >= 11 && franchiseSubsType === 1 && (
                        <DiscountCartBanner
                            allCompaniesLength={allCompanies.length}
                            currentPlan={currentPlan}
                        />
                    )}
                    <ShoppingCartReg
                        origin="addCompany"
                        currentPlan={currentPlan}
                        setCurrentPlan={setCurrentPlan}
                        paymentType={paymentType}
                        setPaymentType={setPaymentType}
                        currentLevel={currentLevel}
                        priceMonthly={priceMonthly}
                        priceMonthlyOG={priceMonthlyOG}
                        setPriceMonthly={setPriceMonthly}
                        setActiveStep={setActiveStep}
                        allCompanies={allCompanies}
                        smbInfo={smbInfo}
                        finalF={finalF}
                        setShowCart={setShowCart}
                        setIsLoading={setIsLoading}
                        subsType={franchiseSubsType}
                    />
                </>
            )}
            <AlertDialog
                open={openAlert}
                onClose={handleCloseDialog}
                agreeBtnLabel={agreeBtnMsg}
                disagreeBtnLabel={disagreeBtnMsg}
                type="info2"
                title={titleModal}
                message={
                    currentPlan.value === 3 && currentPlan.custom === true
                        ? <CustomForm
                                setQuestionCustom={setQuestionCustom}
                                questionCustom={questionCustom}
                                errorCustomPlan={errorCustomPlan}
                            />
                        : bodyMess
                }
                agreeAction={
                    !currentPlan.custom
                        ? (currentPlan.value === 0 ? updateMicroPlan : handleShowCart)
                        : handleSubmitCustomPlan
                }
                maxWidth="sm"
                origin="converter"
                onlyAction={true}
                oneAction={
                    currentPlan.value === 1 ||
                    currentPlan.value === 2 ||
                    (currentPlan.value === 3 && !currentPlan.custom)
                        ? updateFreePlan
                        : handleCloseDialog
                }
            />
        </>
    );
};

export default AddCompany;
