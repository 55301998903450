import { Grid, TextField, Divider, Autocomplete, Typography, Box, Link, Stack } from "@mui/material";
import BaseFillTextField from "../ui/BaseFillTextField";
import BaseSelect from "../ui/BaseSelect";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import InfoIcon from "@mui/icons-material/Info";

import accountsService from "../../services/accounts";
import LightTooltip from "../ui/LightTooltip";
import { useDispatch, useSelector } from "react-redux";

import introJs from "intro.js";
import { addTutorial } from "../../redux/userSlice";

const CssTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
    },
    "& label.Mui-focused": {
        color: "#131F3E",
    },
});

const AccountDetails = (props) => {
    const {
        countries,
        currencies,
        banks,
        data,
        setSelectedAcc,
        showAcc,
        accounts,
        isError,
        setIsLoading,
        editMode,
        handleClickHelp,
    } = props;

    const [t, i18n] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const dispatch = useDispatch();

    const finalAccs =
        accounts &&
        accounts
            .filter((item) => item.system_integration === 3)
            .map((item) => {
                return { id: item._id, name: item.account_name };
            });

    const [finalBanks, setFinalBanks] = useState([]);
    const [countriesNew, setCountriesNew] = useState([]);
    const [currencyNew, setCurrencyNew] = useState([]);
    const [defaultAuto, setDefaultAuto] = useState(null);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));
    const [errorMoni, setErrorMoni] = useState(false);

    const handleChangeCon = async (event) => {
        setIsLoading(true);

        const params = {
            country_id: event.target.value,
            currency_id: data.currency_id,
        };

        await accountsService
            .getCurrencies(params)
            .then((response) => {
                let finalCurrency = response.map((item) => {
                    return { id: item._id, name: language === "en" ? item.currency_name_en : item.currency_name };
                });
                const newValue = finalCurrency.find((item) => item.id === data.currency_id);

                params.currency_id = newValue ? newValue.id : "";

                let changeBanks = false;
                if (data.bank_id && editMode) {
                    const hasCountry = banks.some(
                        (bank) => data.bank_id.includes(bank._id) && bank.country_id === event.target.value
                    );
                    changeBanks = !hasCountry;
                }

                if (data.bank_id && !changeBanks) {
                    let matchBanks = banks.filter(
                        (bank) => data.bank_id.includes(bank._id) && bank.country_id === event.target.value
                    );
                    let currenciesId = matchBanks.map((e) => e.currency_id);
                    finalCurrency = finalCurrency.filter((item) => currenciesId.includes(item.id));
                }

                setCurrencyNew(finalCurrency);

                setSelectedAcc({
                    ...data,
                    country_id: event.target.value,
                    currency_id: finalCurrency.length === 1 ? finalCurrency[0].id : newValue ? newValue.id : "",
                    bank_id: changeBanks ? "" : data.bank_id,
                });

                if (!data.bank_id || changeBanks) {
                    reloadBanks(params);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);
    };

    const handleChangeName = (event) => {
        setSelectedAcc({ ...data, account_name: event.target.value });
    };

    const handleChangeCurrency = async (event) => {
        setIsLoading(true);

        const params = {
            country_id: data.country_id,
            currency_id: event.target.value,
        };

        await accountsService
            .getCountries(params)
            .then((response) => {
                let finalCon = response.map((item) => {
                    return { id: item._id, name: language === "en" ? item.country_name_en : item.country_name };
                });
                const newValue = finalCon.find((item) => item.id === data.country_id);

                params.country_id = newValue ? newValue.id : "";

                let changeBanks = false;
                if (data.bank_id && editMode) {
                    const hasCurrency = banks.some(
                        (bank) => data.bank_id.includes(bank._id) && bank.currency_id === event.target.value
                    );
                    changeBanks = !hasCurrency;
                }

                if (data.bank_id && !changeBanks) {
                    let matchBanks = banks.filter(
                        (bank) => data.bank_id.includes(bank._id) && bank.currency_id === event.target.value
                    );
                    let countriesId = matchBanks.map((e) => e.country_id);
                    finalCon = finalCon.filter((item) => countriesId.includes(item.id));
                }

                setCountriesNew(finalCon);

                setSelectedAcc({
                    ...data,
                    currency_id: event.target.value,
                    country_id: finalCon.length === 1 ? finalCon[0].id : newValue ? newValue.id : "",
                    bank_id: changeBanks ? "" : data.bank_id,
                });

                if (!data.bank_id || changeBanks) {
                    reloadBanks(params);
                }
            })
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);
    };

    const reloadBanks = (params) => {
        let newBanks = [];

        if (!params.country_id) {
            newBanks = banks.filter((item) => item.currency_id === params.currency_id);
        } else if (!params.currency_id) {
            newBanks = banks.filter((item) => item.country_id === params.country_id);
        } else {
            newBanks = banks.filter(
                (item) => item.country_id === params.country_id && item.currency_id === params.currency_id
            );
        }

        const finalBanks = newBanks.map((item) => {
            return { id: item._id, name: item.bank_name };
        });

        let finalBanks2 = [];

        finalBanks.forEach((item) => {
            let bankIndex = finalBanks2?.findIndex((e) => e.name === item.name);

            if (bankIndex === -1) {
                finalBanks2.push({
                    id: item.id,
                    name: item.name,
                });
            } else {
                finalBanks2[bankIndex] = {
                    ...finalBanks2[bankIndex],
                    id: finalBanks2[bankIndex].id + "," + item.id,
                };
            }
        });

        setFinalBanks(finalBanks2);
    };

    const handleChangeBank = (e, info) => {
        if (info !== null) {
            if (info.id.includes(",")) {
                let ids = info.id.split(",");
                let banksFilter = banks.filter((item) => ids.includes(item._id));
                let countriesId = banksFilter.map((item) => item.country_id);
                let currenciesId = banksFilter.map((item) => item.currency_id);

                const auxCon = countries
                    .filter((item) => countriesId.includes(item._id))
                    .map((item) => {
                        return { id: item._id, name: language === "en" ? item.country_name_en : item.country_name };
                    });

                const auxCur = currencies
                    .filter((item) => currenciesId.includes(item._id))
                    .map((item) => {
                        return { id: item._id, name: language === "en" ? item.currency_name_en : item.currency_name };
                    });

                setSelectedAcc({
                    ...data,
                    bank_id: info.id,
                    country_id: auxCon.length === 1 ? auxCon[0].id : "",
                    currency_id: auxCur.length === 1 ? auxCur[0].id : "",
                });

                setCountriesNew(auxCon);
                setCurrencyNew(auxCur);

                setDefaultAuto(info);
            } else {
                const bank = banks.find((item) => item._id === info.id);

                let auxCon = [];
                if (bank.country_id) {
                    auxCon = countries
                        .filter((item) => item._id === bank.country_id)
                        .map((item) => {
                            return { id: item._id, name: language === "en" ? item.country_name_en : item.country_name };
                        });
                } else {
                    auxCon = countries.map((item) => {
                        return { id: item._id, name: language === "en" ? item.country_name_en : item.country_name };
                    });
                }

                let auxCur = [];
                if (bank.currency_id) {
                    auxCur = currencies
                        .filter((item) => item._id === bank.currency_id)
                        .map((item) => {
                            return {
                                id: item._id,
                                name: language === "en" ? item.currency_name_en : item.currency_name,
                            };
                        });
                } else {
                    auxCur = currencies.map((item) => {
                        return { id: item._id, name: language === "en" ? item.currency_name_en : item.currency_name };
                    });
                }

                setCountriesNew(auxCon);
                setCurrencyNew(auxCur);

                setSelectedAcc({
                    ...data,
                    bank_id: info.id,
                    country_id: bank.country_id,
                    currency_id: bank.currency_id,
                });

                setDefaultAuto(info);
            }
        } else {
            setDefaultAuto(null);
            const finalCon = countries.map((item) => {
                return { id: item._id, name: language === "en" ? item.country_name_en : item.country_name };
            });

            const finalCurrency = currencies.map((item) => {
                return { id: item._id, name: language === "en" ? item.currency_name_en : item.currency_name };
            });

            const finalBanks = banks.map((item) => {
                return { id: item._id, name: item.bank_name };
            });

            let finalBanks2 = [];

            finalBanks.forEach((item) => {
                let bankIndex = finalBanks2?.findIndex((e) => e.name === item.name);

                if (bankIndex === -1) {
                    finalBanks2.push({
                        id: item.id,
                        name: item.name,
                    });
                } else {
                    finalBanks2[bankIndex] = {
                        ...finalBanks2[bankIndex],
                        id: finalBanks2[bankIndex].id + "," + item.id,
                    };
                }
            });

            setFinalBanks(finalBanks2);
            setCountriesNew(finalCon);
            setCurrencyNew(finalCurrency);

            let copy = { ...data };
            delete copy["bank_id"];
            setSelectedAcc(copy);
        }
    };

    const handleChangeMoni = (event) => {
        let aux = event.target.value;

        if (aux > 31) {
            setErrorMoni(true);
        } else {
            setErrorMoni(false);
            setSelectedAcc({ ...data, frequency: aux });
        }
    };

    const handleChangeAcc = (event) => {
        const newAcc = accounts.find((element) => element._id === event.target.value);

        setSelectedAcc({
            ...data,
            country_id: newAcc.country_id,
            currency_id: newAcc.currency_id,
            bank_id: newAcc.bank_id,
            frequency: newAcc.frequency,
            acc_id: event.target.value,
        });

        setDefaultAuto({ id: newAcc.bank_id, name: banks.find((item) => item._id === newAcc.bank_id).bank_name });
    };

    useEffect(() => {
        const finalCon = countries.map((item) => {
            return { id: item._id, name: language === "en" ? item.country_name_en : item.country_name };
        });

        const finalCurrency = currencies.map((item) => {
            return { id: item._id, name: language === "en" ? item.currency_name_en : item.currency_name };
        });

        const finalBanks = banks.map((item) => {
            return { id: item._id, name: item.bank_name };
        });

        let finalBanks2 = [];

        finalBanks.forEach((item) => {
            let bankIndex = finalBanks2?.findIndex((e) => e.name === item.name);

            if (bankIndex === -1) {
                finalBanks2.push({
                    id: item.id,
                    name: item.name,
                });
            } else {
                finalBanks2[bankIndex] = {
                    ...finalBanks2[bankIndex],
                    id: finalBanks2[bankIndex].id + "," + item.id,
                };
            }
        });

        setFinalBanks(finalBanks2);
        setCountriesNew(finalCon);
        setCurrencyNew(finalCurrency);
    }, [banks, countries, currencies, language]);

    useEffect(() => {
        if (editMode) {
            if (data && data.bank_id) {
                let bankFind = finalBanks.find((bank) => bank.id.includes(data.bank_id));
                if (bankFind) {
                    setDefaultAuto(bankFind);
                    setSelectedAcc({
                        ...data,
                        bank_id: bankFind.id,
                    });
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode, finalBanks, setSelectedAcc]);

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    useEffect(() => {
        if (todos.steps.step4 && !todos.dontShowAgain) {
            let isDone = false;

            introJs()
                .setOptions({
                    disableInteraction: true,
                    exitOnOverlayClick: false,
                    keyboardNavigation: false,
                    nextLabel: t("dialog.continue"),
                    prevLabel: t("miscellaneous.goBack"),
                    doneLabel: t("miscellaneous.accept"),
                    steps: [
                        {
                            title: `${t("miscellaneous.step")} 4`,
                            element: "#account2-tutorial",
                            intro: t("miscellaneous.tutorialAcc2"),
                        },
                    ],
                })
                .oncomplete(() => {
                    isDone = true;
                })
                .onexit(() => {
                    if (!isDone) {
                        dispatch(addTutorial({ ...todos.steps, step4: false }));
                    }
                })
                .start();
        }
    }, [dispatch, t, todos.dontShowAgain, todos.steps]);

    return (
        <Grid container spacing={2} id="account2-tutorial">
            <Grid item xs={12} md={data.system_integration !== 3 && data.account_id_system ? 3 : 4}>
                <BaseFillTextField
                    id="name"
                    label={t("accounts.description")}
                    value={data.account_name ?? ""}
                    onChange={handleChangeName}
                    error={isError && !data.account_name}
                    disabled={data.system_integration && data.system_integration !== 3}
                />
            </Grid>
            <Grid item xs={12} md={data.system_integration !== 3 && data.account_id_system ? 2 : 3}>
                <Autocomplete
                    options={finalBanks}
                    getOptionLabel={(option) => (option.name ? option.name : "")}
                    value={defaultAuto ?? null}
                    onChange={handleChangeBank}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={<Typography sx={{ color: "#131f3e" }}>{t("dashboard.bank")}</Typography>}
                            variant="filled"
                            size="small"
                        />
                    )}
                    popupIcon={
                        <PlayArrowIcon
                            sx={{
                                transform: "rotate(90deg)",
                                color: "#131F3E",
                                right: ".5rem",
                                cursor: "pointer",
                                zIndex: 0,
                                pointerEvents: "none",
                            }}
                        />
                    }
                    noOptionsText={
                        <Box sx={{ bgcolor: "#e8e9ec", p: 0.8, borderRadius: 1 }}>
                            <Link
                                fontWeight={600}
                                sx={{ cursor: "pointer", textDecoration: "none" }}
                                onClick={handleClickHelp}
                            >
                                {t("accounts.bankNotFound")}
                            </Link>
                            <Typography fontStyle="italic" fontSize={13} mt={0.5} color="#131f3e">
                                {t("accounts.bankNotFound2")}
                            </Typography>
                        </Box>
                    }
                />
            </Grid>
            <Grid item xs={12} md={3}>
                <BaseSelect
                    id="country"
                    label={t("register.country")}
                    value={countriesNew.length > 0 ? data.country_id ?? "" : ""}
                    values={countriesNew}
                    onChange={handleChangeCon}
                    error={isError && !data.country_id}
                />
            </Grid>
            <Grid item xs={12} md={2}>
                <BaseSelect
                    id="currency"
                    label={t("dashboard.currency")}
                    value={currencyNew.length > 0 ? data.currency_id ?? "" : ""}
                    values={currencyNew}
                    onChange={handleChangeCurrency}
                    error={isError && !data.currency_id}
                />
            </Grid>
            {data.system_integration !== 3 && data.account_id_system && (
                <Grid item xs={12} md={2}>
                    <LightTooltip title={t("accounts.msgFrequency")} position="top">
                        <CssTextField
                            id="frequency"
                            variant="filled"
                            size="small"
                            label={t("accounts.transMonitoring")}
                            fullWidth
                            type="number"
                            inputProps={{ min: 1, max: 31 }}
                            value={data.frequency}
                            onChange={handleChangeMoni}
                            error={errorMoni || (isError && !(data.frequency >= 1 && data.frequency <= 31))}
                            helperText={
                                errorMoni
                                    ? t("accounts.errorMonitoring")
                                    : isError &&
                                      !(data.frequency >= 1 && data.frequency <= 31) &&
                                      t("accounts.errorTransMonitoring")
                            }
                            onBlur={() => {
                                if (data.frequency === "" || data.frequency < 1) {
                                    setSelectedAcc({ ...data, frequency: 1 });
                                    setErrorMoni(false);
                                }
                            }}
                        />
                    </LightTooltip>
                </Grid>
            )}
            {showAcc && (
                <>
                    <Grid item xs={12}>
                        <Divider variant="middle" sx={{ borderWidth: 1, borderColor: "rgba(3, 24, 81, 0.30)" }} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Stack direction="row" alignItems="center">
                            <BaseSelect
                                id="account"
                                label={t("dashboard.accounts")}
                                value={data.acc_id}
                                values={finalAccs}
                                onChange={handleChangeAcc}
                                error={isError && !data.acc_id}
                            />
                            <LightTooltip title={t("accounts.msgCombineBanks")}>
                                <InfoIcon fontSize="small" sx={{ color: "#4A22D4", cursor: "pointer", ml: 1 }} />
                            </LightTooltip>
                        </Stack>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default AccountDetails;
