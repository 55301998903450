/* Componentes */
import {
    Box,
    Tabs,
    Tab,
    Stack,
    Card,
    CardContent,
    Container,
    Divider,
    Button,
    styled,
} from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import SubscriptionTab3 from "./SubscriptionTab3";

import paymentMethodsService from "../../services/payment_methods";

import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

/* Estilos */
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ShoppingCartReg from "./shoppingCart/ShoppingCartReg";
import PlanSubs from "./plans/PlansMain";
import Integrations from "./Integrations";
import DiscountCartBanner from "./DiscountCartBanner";

const ColorButton = styled(Button)(({ theme }) => ({
    backgroundColor: "#4A22D4",
    color: "#FFF",
    fontStyle: "normal",
    fontWeight: "350",
    border: "1px solid #4A22D4",
    borderRadius: "10px",
    "&:hover": {
        backgroundColor: "#2f3190",
        border: "1px solid #2f3190",
    },
    "&:disabled": {
        backgroundColor: "#B3B3B3",
        border: "1px solid #B3B3B3",
        color: "#FFF",
    },
}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        "aria-controls": `vertical-tabpanel-${index}`,
    };
}

const TabProps = {
    whiteSpace: "nowrap",
    fontSize: 16,
    fontWeight: 300,
    mr: 3,
    py: 2.8,
    px: 3,
    backgroundColor: "#e5e0f9",
    borderRadius: "8px",
    minHeight: 35,
    height: 35,
    color: "#131F3E !important",
    "&.Mui-selected": {
        backgroundColor: "#c1b4f0",
        fontWeight: 600,
        borderRadius: "8px",
    },
};

const MainSubscription = (props) => {
    const {
        franchise_id,
        company_id,
        currentPlan,
        setIsLoading,
        setPayments,
        showSkeleton,
        setShowSkeleton,
        plansInfoNew,
        setCurrentPlan,
        paymentType,
        setPaymentType,
        currentLevel,
        priceMonthly,
        priceMonthlyOG,
        setPriceMonthly,
        setPriceMonthlyOG,
        setCurrentLevel,
        setTitleModal,
        setAgreeBtnMsg,
        setDisagreeBtnMsg,
        setBodyMess,
        setActiveStep,
        setShowCart,
        showCart,
        companyDetails,
        allCompanies,
        setOpenAlert,
        activeStep,
        setTexto,
        setShow,
        setAlertType,
        setInteg,
        integ,
        updateCompany,
        activePlan,
        handleShowCart,
        setShowModalCredits,
        handleClickHelp
    } = props;

    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);
    const location = useLocation();

    const [value, setValue] = useState(0);

    var userEmail = "";
    if (todos) {
        if (todos.userInfo) {
            if (todos.userInfo.email) {
                userEmail = todos.userInfo.email;
            }
        }
    }

    const navigate = useNavigate();

    const handleChangeTab = async (event, newValue) => {
        setValue(newValue);
        setShowCart(false);
        if (newValue === 1) {
            reloadData();
        }
        navigate("/subscription", {
            state: {
                tab: newValue,
            },
        });
    };

    useEffect(() => {
        (async () => {
            if (location?.search?.includes("tab=0") || location.state?.tab === 0 ) {
                setValue(0);
            } else if (location?.search?.includes("tab=1") || location.state?.tab === 1) {
                setValue(1);
            }
        })();
    }, [location?.search, location.state]);

    const finalF = async () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setShowCart(false);
    }

    const reloadData = async () => {
        setShowSkeleton(true);

        await paymentMethodsService
            .getPaymentMethods({
                franchise_id: todos.franchiseID,
            })
            .then((response) => {
                setPayments(response);
            })
            .catch((error) => {
                console.log(error);
            });

        setShowSkeleton(false);
    };

    useEffect(() => {
        if (location?.search?.includes("tab=0") || location.state?.tab === 0) {
            setValue(0);
        } else if (location?.search?.includes("tab=1") || location.state?.tab === 1) {
            setValue(1);
        } else if (location?.search?.includes("tab=2") || location.state?.tab === 2) {
            setValue(2);
        }
    }, [location?.search, location.state?.tab]);

    const showStep3 = () => {
        setShowCart(false);
    };

    return (
        <>
            <Card sx={{ border: "none", borderRadius: 0, boxShadow: 0 }}>
                <CardContent sx={{ mt: 1, border: "none", borderRadius: 0 }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                        <Tabs
                            orientation={window.innerWidth < 600 ? "vertical" : "horizontal"}
                            value={value}
                            onChange={handleChangeTab}
                            aria-label="Horizontal tabs"
                            sx={{
                                p: 1,
                                border: "none",
                            }}
                            TabIndicatorProps={{
                                style: { display: "none" },
                            }}
                        >
                            {userEmail !== "support@kiiper.app" && (
                                <Tab
                                    label={t("plans.compPlan")}
                                    {...a11yProps(0)}
                                    sx={TabProps}
                                />
                            )}
                            {/* <Tab
                                label={t("subscription.myPayments")}
                                {...a11yProps(userEmail !== "support@kiiper.app" ? 1 : 0)}
                                sx={TabProps}
                            /> */}
                            <Tab
                                label={t("subscription.conversBalance")}
                                {...a11yProps(1)}
                                sx={TabProps}
                            />
                        </Tabs>
                        {/* {cartItems.length !== 0 && userEmail !== "support@kiiper.app" && (
                            <Button
                                variant="contained"
                                size="small"
                                className={shoppingCartOpen ? classes.shoppingBtnSel : classes.shoppingBtn}
                                startIcon={<ShoppingCartIcon />}
                                onClick={handleOpenCart}
                            >
                                {t("subscription.cart")}
                                <Chip
                                    className={classes.number}
                                    size="small"
                                    label={cartItems.length}
                                    color="primary"
                                />
                            </Button>
                        )} */}
                    </Stack>
                </CardContent>
            </Card>
            <Divider />
            <Box sx={{ background: "#f7f8f9", minHeight: "calc(100vh - 180px)" }}>
                <Container maxWidth="lg">
                    {showCart ? (<>
                        <Stack direction="row" spacing={2} justifyContent="space-between" pt={4}>
                            <Button variant="outlined" onClick={showStep3} sx={{ width: 170 }}>
                                {t("miscellaneous.goBack")}
                            </Button>
                            <Button onClick={() => handleClickHelp("generalConv", "", "")}>{t("accounts.needHelp")}</Button>
                        </Stack>
                        {allCompanies.length >= 11 && companyDetails.franchise_id.type === 1 && (
                            <DiscountCartBanner
                                allCompaniesLength={allCompanies.length}
                                currentPlan={currentPlan}
                            />
                        )}
                        <ShoppingCartReg
                            origin="updateSusbcription"
                            currentPlan={currentPlan}
                            setCurrentPlan={setCurrentPlan}
                            paymentType={paymentType}
                            setPaymentType={setPaymentType}
                            currentLevel={currentLevel}
                            priceMonthly={priceMonthly}
                            priceMonthlyOG={priceMonthlyOG}
                            setPriceMonthly={setPriceMonthly}
                            setActiveStep={setActiveStep}
                            allCompanies={allCompanies}
                            smbInfo={companyDetails}
                            finalF={finalF}
                            setShowCart={setShowCart}
                            activePlan={companyDetails.id_status === 3 ? companyDetails?.subscription?.subscription_plan ?? null : null}
                            //userEmail={userEmail}
                            setIsLoading={setIsLoading}
                            subsType={companyDetails.franchise_id.type}
                        />
                        </>
                    ) : (
                        <>
                            <TabPanel value={value} index={0}>
                                {activeStep === 0 && <PlanSubs
                                    setTitleModal={setTitleModal}
                                    setOpenAlert={setOpenAlert}
                                    setAgreeBtnMsg={setAgreeBtnMsg}
                                    setDisagreeBtnMsg={setDisagreeBtnMsg}
                                    setBodyMess={setBodyMess}
                                    setCurrentPlan={setCurrentPlan}
                                    paymentType={paymentType}
                                    setPaymentType={setPaymentType}
                                    setCurrentLevel={setCurrentLevel}
                                    setPriceMonthly={setPriceMonthly}
                                    setPriceMonthlyOG={setPriceMonthlyOG}
                                    currentLevel={currentLevel}
                                    priceMonthly={priceMonthly}
                                    origin="updateSubscription"
                                    currentPlan={currentPlan}
                                    activePlan={companyDetails.id_status === 3 ? companyDetails?.subscription?.subscription_plan ?? null : null}
                                    handleShowCart={handleShowCart}
                                    companyDetails={companyDetails}
                                    setIsLoading={setIsLoading}
                                    setShowCart={setShowCart}
                                    maxFreeCompanies={companyDetails.free_companies_max_count}
                                    currentFreeCompanies={companyDetails.free_companies_count}
                                    subsType={companyDetails?.franchise_id?.type}
                                />}
                                {activeStep === 1 && (
                                    <>
                                        <Box mt={3}>
                                            <Integrations
                                                smbInfo={companyDetails}
                                                setTexto={setTexto}
                                                setShow={setShow}
                                                setAlertType={setAlertType}
                                                integ={integ}
                                                setInteg={setInteg}
                                                origin="company"
                                            />
                                        </Box>
                                        <Box sx={{ display: "flex", flexDirection: "row", pt: 5, justifyContent: "end" }}>
                                            <ColorButton onClick={updateCompany} disableElevation variant="contained">
                                                {integ === 3 ? t("register.continueWithoutInteg") : t("dialog.continue")}
                                            </ColorButton>
                                        </Box>
                                    </>
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                <SubscriptionTab3
                                    setIsLoading={setIsLoading}
                                    franchise_id={franchise_id}
                                    company_id={company_id}
                                    showSkeleton={showSkeleton}
                                    setShowSkeleton={setShowSkeleton}
                                    plansInfo={plansInfoNew}
                                    activePlan={activePlan}
                                    setShowModalCredits={setShowModalCredits}
                                />
                            </TabPanel>
                        </>
                    )}
                </Container>
            </Box>
        </>
    );
};

export default MainSubscription;
