import {
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    FormControl,
    InputLabel,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Select,
    Stack,
    Typography,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CheckIcon from "@mui/icons-material/Check";


import LightTooltip from "../../ui/LightTooltip";

const checkIconDown = (props) => {
    if (props.className.includes("MuiSelect-iconOpen")) {
        return (
            <PlayArrowIcon
                sx={{
                    position: "absolute",
                    transform: "rotate(270deg)",
                    color: "#131F3E",
                    right: ".5rem",
                    cursor: "pointer",
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            />
        );
    }
    return (
        <PlayArrowIcon
            sx={{
                position: "absolute",
                transform: "rotate(90deg)",
                color: "#131F3E",
                right: ".5rem",
                cursor: "pointer",
                zIndex: 0,
                pointerEvents: "none",
            }}
        />
    );
};

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const PlansCard = (props) => {
    const {
        plan,
        levels,
        paymentType,
        setTitleModal,
        setOpenAlert,
        setAgreeBtnMsg,
        setDisagreeBtnMsg,
        setBodyMess,
        setCurrentPlan,
        setLevelGlobal,
        setPriceMonthlyGlobal,
        setPriceMonthlyOG,
        origin,
        activePlan,
        handleShowCart,
        maxFreeCompanies,
        currentFreeCompanies,
        subsType,
        handleOpenModal
    } = props;

    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);

    const [currentLevel, setCurrentLevel] = useState(plan.planTitle === "Micro" ? 0 : plan.planTitle === "Small" ? (subsType === 1 ? 1 : 3) : plan.planTitle === "Medium" ? 8 : 14);
    const [priceMonthly, setPriceMonthly] = useState(plan.planTitle === "Micro" ? 0 : plan.planTitle === "Small" ? (subsType === 1 ? 9 : 29) : plan.planTitle === "Medium" ? 199 : 749);

    useEffect(() => {
        const monthPrice = plan.levels.find((item) => item.id === currentLevel)?.price;
        if (paymentType === "annual") {
            setPriceMonthly(monthPrice - monthPrice * 0.15);
        } else {
            setPriceMonthly(monthPrice);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paymentType]);

    useEffect(() => {
        if (activePlan && activePlan >= 0 && plan.levels.map((item) => item.id).includes(activePlan)) {
            const currentLevel = plan.levels.find((item) => item.id === activePlan);

            setCurrentLevel(currentLevel.id);
            setPriceMonthly(paymentType === "annual" ? currentLevel.price - currentLevel.price * 0.15 : currentLevel.price);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activePlan]);

    useEffect(() => {
        setCurrentLevel(plan.planTitle === "Micro" ? 0 : plan.planTitle === "Small" ? (subsType === 1 ? 1 : 3) : plan.planTitle === "Medium" ? 8 : 13)
        setPriceMonthly(plan.planTitle === "Micro" ? 0 : plan.planTitle === "Small" ? (subsType === 1 ? 9 : 29) : plan.planTitle === "Medium" ? 199 : 749)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subsType]);

    const handleChange = (event) => {
        const currentLevel = plan.levels.find((item) => item.id === event.target.value);
        setCurrentLevel(currentLevel.id);
        setPriceMonthly(paymentType === "annual" ? currentLevel.price - currentLevel.price * 0.15 : currentLevel.price);
    };

    const showModal = (selectedPlan) => {
        if (selectedPlan === 0) {
            setCurrentPlan({
                value: selectedPlan,
                custom: false,
            });
            setTitleModal(t("register.microCond"));
            setBodyMess(
                <>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        justifyContent="space-between"
                        spacing={2}
                        sx={{ mt: 1, textAlign: "justify", mb: 2 }}
                    >
                        <Typography
                            fontStyle="normal"
                            fontSize={14}
                            fontWeight={300}
                            sx={{
                                color: "#131F3E",
                            }}
                            dangerouslySetInnerHTML={{
                                __html: t("register.selectedMicro"),
                            }}
                        ></Typography>
                    </Stack>
                    <Typography
                        fontStyle="normal"
                        fontSize={14}
                        fontWeight={300}
                        sx={{
                            color: "#131F3E",
                            textAlign: "center",
                            mb: 2,
                            fontWeight: 600,
                        }}
                    >
                        {t("dialog.warningConfirm2")}
                    </Typography>
                </>
            );
            setAgreeBtnMsg(t("dialog.continue"));
            setDisagreeBtnMsg(t("register.back"));
            setOpenAlert(true);
            setLevelGlobal(0);
        } else if (selectedPlan === 1 || selectedPlan === 2 || selectedPlan === 3) {
            setLevelGlobal(currentLevel);
            if (selectedPlan === 3 && plan.levels.find(item => item.id === currentLevel)?.custom === true) {
                setCurrentPlan({
                    value: selectedPlan,
                    custom: true,
                });
                setTitleModal(t("register.customCond"));
                setAgreeBtnMsg(t("converter.send"));
                setDisagreeBtnMsg(t("register.back"));
                setOpenAlert(true);
            } else {
                const monthPrice = plan.levels.find((item) => item.id === currentLevel)?.price;
                setPriceMonthlyGlobal(priceMonthly);
                setPriceMonthlyOG(monthPrice);
                setCurrentPlan({
                    value: selectedPlan,
                    custom: false,
                });
                if (origin === "register") {
                    setTitleModal(t("register.beforePlan"));
                    setBodyMess(
                        <>
                            <Stack
                                direction={{ xs: "column", md: "row" }}
                                justifyContent="space-between"
                                spacing={2}
                                sx={{ mt: 1, textAlign: "justify", mb: 2 }}
                            >
                                <Typography
                                    fontStyle="normal"
                                    fontSize={14}
                                    fontWeight={300}
                                    sx={{
                                        color: "#131F3E",
                                    }}
                                >
                                    {t("register.selectedMedium1")}{" "}
                                    <Typography component="span" fontWeight={600}>
                                        Plan {plan.planTitle} - {plan.levels.find((item) => item.id === currentLevel)?.pages} {t("register.pagesMonthly")}
                                    </Typography>{" "}
                                    <Typography
                                        component="span"
                                        dangerouslySetInnerHTML={{
                                            __html: t("register.selectedMedium2"),
                                        }}
                                    ></Typography>
                                </Typography>
                            </Stack>
                        </>
                    );
                    setAgreeBtnMsg(t("register.actNow"));
                    setDisagreeBtnMsg(t("register.startFreeTrial"));
                    setOpenAlert(true);
                } else {
                    handleShowCart(null, currentLevel);
                }
            }
        }
    };



    const checkCurrentPlan = () => (origin === "editCompany" || origin === "updateSubscription") && activePlan === currentLevel;

    return (
        <Card sx={{ boxShadow: "4px 4px 15px 0px #4A22D41A", borderRadius: 4 }}>
            <CardContent>
                <Stack alignItems="center">
                    <Box
                        sx={{
                            backgroundColor: "#6544DB",
                            textAlign: "center",
                            p: 2,
                            borderRadius: 4,
                            width: 200,
                        }}
                    >
                        <Typography variant="h1" sx={{ color: "white" }}>
                            {plan.planTitle}
                        </Typography>
                    </Box>

                    <Typography color="primary" mt={1} gutterBottom>
                        {t("plans.bestBusiness")} {t(plan.subtitle)}
                    </Typography>
                    <Typography variant="h1">
                        {plan.levels[0].price === 0
                            ? t("plans.free")
                            : !(plan.levels.find(item => item.id === currentLevel)?.custom === true)
                                ? `US$ ${parseFloat(priceMonthly).toLocaleString(todos.amountFormat, {
                                    minimumFractionDigits: 2,
                                })}/${t("converter.month")}`
                                : t("plans.custom")}
                    </Typography>
                </Stack>
                <Box pl={{ lg: 4, xs: 0 }} pt={3} sx={{ textAlign: { xs: "center", md: "left" } }}>
                    {plan.planTitle === "Micro" ? (
                        <Typography pt="12px" pb="13px">
                            20 {t("plans.pageMonth")}
                        </Typography>
                    ) : (
                        <FormControl variant="filled" sx={{ width: "90%" }}>
                            <InputLabel
                                id="simple-select-label-all"
                                sx={{
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    color: "#131F3E",
                                }}
                            >
                                {t("plans.chooseLevel")}
                            </InputLabel>
                            <Select
                                labelId="simple-select-label-all"
                                name="price"
                                value={currentLevel}
                                onChange={handleChange}
                                IconComponent={(props) => checkIconDown(props)}
                                size="small"
                                MenuProps={MenuProps}
                                renderValue={(selected) => {
                                    // Renderizar solo el texto seleccionado sin el icono
                                    const selectedItem = plan.levels.find(item => item.id === selected);
                                    return !(selectedItem?.custom === true)
                                        ? `${parseFloat(selectedItem?.pages).toLocaleString(todos.amountFormat)} ${t("plans.pageMonth")}`
                                        : t("plans.custom");
                                }}
                            >
                                {levels.map((item, index) => (
                                    <MenuItem value={item.id} key={index} sx={{ whiteSpace: "nowrap" }}>
                                        <ListItemText primary={!(item?.custom === true)
                                            ? `${parseFloat(item.pages).toLocaleString(todos.amountFormat)} ${t(
                                                "plans.pageMonth"
                                            )}`
                                            : t("plans.custom")} />
                                        {activePlan > 0 && item.id === activePlan &&
                                            <ListItemIcon>
                                                <CheckIcon color="primary" fontSize="small" />
                                            </ListItemIcon>
                                        }
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                    <Typography mt={3.5} gutterBottom>
                        1 {t("plans.pageForEach")}
                    </Typography>
                    <Typography gutterBottom>{t("plans.pagesPerDoc")}</Typography>
                    <Typography mb={3}>{t("plans.pagesPerState")}</Typography>

                    <Typography mt={4.5} gutterBottom>
                        {t(plan.planType)}
                    </Typography>
                    <Typography gutterBottom>{t(plan.item1)}</Typography>
                    <Typography gutterBottom>{t(plan.commType)}</Typography>
                    <Typography mb={3}>{t(plan.item2)}</Typography>
                    <Typography mt={4.5} gutterBottom>
                        {plan.planId === 0 ? `2 ${t("plans.users")}` : t("plans.unlimitedUpp")}
                    </Typography>
                    <Typography gutterBottom>{t(plan.item3)}</Typography>
                    <Typography gutterBottom>{t(plan.item4)}</Typography>
                    <Typography>
                        {plan.extension} {t("miscellaneous.days")}
                    </Typography>
                </Box>
            </CardContent>
            <CardActions sx={{ justifyContent: "center", mb: checkCurrentPlan() && plan.planId !== 0 ? 1 : 5.5 }}>
                <Stack direction="column">
                    <LightTooltip title={plan.planId === 0 && currentFreeCompanies >= maxFreeCompanies ? "Has alcanzado el límite de creación de empresas con el plan Micro." : ""} position="bottom">
                        <Box component="span">
                            <Button
                                variant="contained"
                                disableElevation
                                endIcon={checkCurrentPlan() ? <CheckIcon /> : null}
                                sx={{
                                    width: 200,
                                    pointerEvents: checkCurrentPlan() ? "none" : "auto",
                                    backgroundColor: checkCurrentPlan() ? "#FFF" : "#4A22D4",
                                    color: checkCurrentPlan() ? "#4A22D4 !important" : "#FFF",
                                    fontWeight: checkCurrentPlan() ? "600 !important" : "300",
                                    border: plan.planId === 0 && currentFreeCompanies >= maxFreeCompanies ? "1px solid #e0e0e0" : "1px solid #4A22D4",
                                    "&:hover": {
                                        backgroundColor: checkCurrentPlan() ? "#FFF" : "#331794",
                                        color: checkCurrentPlan() ? "#4A22D4 !important" : "#FFF",
                                        fontWeight: checkCurrentPlan() ? "600 !important" : "300",
                                        border: checkCurrentPlan() ? "1px solid #4A22D4" : "1px solid #331794",
                                    },
                                }}
                                onClick={() => showModal(plan.planId)}
                                disabled={plan.planId === 0 && currentFreeCompanies >= maxFreeCompanies}
                            >
                                {checkCurrentPlan() ? t("team.active") : origin === "register" || origin === "addCompany" ? t("plans.startNow") : t("dialog.select")}
                            </Button>
                        </Box>
                    </LightTooltip>
                    {checkCurrentPlan() && plan.planId !== 0 && (
                        <Button color="error" onClick={handleOpenModal}>
                            {t("team.cancel")}
                        </Button>
                    )}
                </Stack>
            </CardActions>
        </Card>
    );
};

export default PlansCard;
