/** Imports ---------------------------------------------------------------- */

/** React imports */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/** MUI icons */
import DeleteIcon from "@mui/icons-material/Delete";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import "moment/locale/es";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

/** MUI imports */
import { Typography, Grid, FormControl, InputLabel, Select, FilledInput, MenuItem } from "@mui/material";
import conversionsService from "../../services/conversion";

import conversionFormats from "../Js/conversionFormats";
import { useLocation } from "react-router-dom";
import FiltersPopover from "../ui/FiltersPopover";

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 45 * 4.5 + 8,
            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
        },
        sx: {
            borderRadius: 2,
            mt: 0.7,
        },
    },
    variant: "menu",
    getcontentanchorel: null,
};

const FiltersButton = (props) => {
    const {
        accounts,
        users,
        company,
        setConversions,
        setShowSkeleton,
        franchise,
        grupal,
        filters,
        setFilters,
        tabValue,
        setGroupConversions,
        setAccount,
        setIsLoading
    } = props;

    const [t, i18n] = useTranslation("global");
    const location = useLocation();

    const [anchorEl, setAnchorEl] = useState(null);
    const [language, setLanguage] = useState(sessionStorage.getItem("lng"));

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    /* Filtraríamos por Banco (cuenta bancaria), fecha de carga (campo desde y hasta), formato, fecha de estados de cuenta (fecha desde y hasta), usuario y estatus.*/

    const attributes = [
        { id: 1, name: t("dashboard.bank"), query: "account_id_filter" },
        { id: 2, name: t("converter.uploadDate"), query: "uploaded" },
        { id: 3, name: t("converter.format"), query: "format" },
        { id: 4, name: t("converter.bankStatementDate"), query: "period" },
        { id: 5, name: t("converter.user"), query: "user_name" },
        { id: 6, name: t("converter.status"), query: "id_status" },
    ];

    const status = [
        { id: 1, name: t("converter.inProc") },
        { id: 2, name: t("converter.convertible") },
        { id: 3, name: t("converter.successful") },
        { id: 4, name: t("converter.inReview") },
        { id: 5, name: t("converter.invalid") },
    ];

    const addFilter = () => {
        let array = [...filters.filtersArray];

        if (array.length <= 6) {
            setFilters({
                ...filters,
                filtersArray: [
                    ...filters.filtersArray,
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                    },
                ],
            });
        }
    };

    const reloadHistConversions = async (params) => {
        setShowSkeleton(true);
        setIsLoading(true);

        if (tabValue === 1) {
            await conversionsService
                .getGroupedConversions(params)
                .then((data) => {
                    setGroupConversions(data);
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            await conversionsService
                .getConversions(params)
                .then((response) => {
                    if (response.length > 0) {
                        setConversions(response);
                    } else {
                        setConversions([]);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }

        setIsLoading(false);

        setShowSkeleton(false);
    };


    const removeFilter = async (index) => {
        let array = [...filters.filtersArray];

        if (index !== -1) {
            array.splice(index, 1);
            setFilters({
                ...filters,
                filtersArray: array,
            });

            setShowSkeleton(true);

            let objectFinal = {};

            array.forEach((item) => {
                if (item.value && item.type) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value);
                }
                if (item.type && item.since) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                        item.since.format("YYYY-MM-DD"));
                }
                if (item.type && item.until) {
                    return (objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                        item.until.format("YYYY-MM-DD"));
                }
            });

            let params = {
                company_id: company ?? undefined,
                ...objectFinal
            }
            reloadHistConversions(params)

            setShowSkeleton(false);
        }
    };

    const removeFirstFilter = async () => {
        setShowSkeleton(true);
        setFilters({
            filtersArray: [
                {
                    type: "",
                    value: "",
                    since: "",
                    until: "",
                },
            ],
        });

        let params = { company_id: company ?? undefined }
        setAccount("");
        reloadHistConversions(params)

        setShowSkeleton(false);
    };

    const handleChangeType = (event, index) => {
        let aux = [...filters.filtersArray];
        aux[index].type = event.target.value;
        aux[index].value = "";
        aux[index].since = "";
        aux[index].until = "";

        setFilters({
            ...filters,
            filtersArray: aux,
        });
    };

    const handleChangeFilter = async (event, index, filterType) => {
        let aux = [...filters.filtersArray];

        aux[index].value = event.target.value;
        aux[index].since = "";
        aux[index].until = "";

        setShowSkeleton(true);

        let objectFinal = {};

        aux.forEach((item) => {
            if (item.value && item.type) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
            }
            if (item.type && item.since) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                    item.since.format("YYYY-MM-DD");
            }
            if (item.type && item.until) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                    item.until.format("YYYY-MM-DD");
            }
        });

        let params = { company_id: company ?? undefined, ...objectFinal }
        reloadHistConversions(params)

        setFilters({
            ...filters,
            filtersArray: aux,
        });

        setShowSkeleton(false);
    };

    const handleChangeFilterRange = async (event, index, filterType, range) => {
        let aux = [...filters.filtersArray];
        aux[index][range] = event;
        aux[index].value = "";

        setShowSkeleton(true);

        let objectFinal = {};

        aux.forEach((item) => {
            if (item.value && item.type) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query] = item.value;
            }
            if (item.type && item.since) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_since"] =
                    item.since.format("YYYY-MM-DD");
            }
            if (item.type && item.until) {
                objectFinal[attributes.find((item2) => item2.id === item.type).query + "_until"] =
                    item.until.format("YYYY-MM-DD");
            }
        });

        let params = { company_id: company ?? undefined, ...objectFinal }
        reloadHistConversions(params)

        setFilters({
            ...filters,
            filtersArray: aux,
        });

        setShowSkeleton(false);
    };

    const cleanFilters = async (e) => {
        if (e.target.classList.contains("MuiAvatar-img")) {

            setShowSkeleton(true);
            setFilters({
                filtersArray: [
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                    },
                ],
            });

            let params = { company_id: company ?? undefined }
            reloadHistConversions(params)
            //setAccount("");

            setShowSkeleton(false);
        }
    };

    useEffect(() => {
        if (location.state?.account_id) {
            setFilters({
                filtersArray: [
                    {
                        type: 1,
                        value: location.state?.account_id,
                        since: "",
                        until: "",
                    },
                ],
            });
        } else {
            setFilters({
                filtersArray: [
                    {
                        type: "",
                        value: "",
                        since: "",
                        until: "",
                    },
                ],
            });
        }
    }, [company, franchise, grupal, location.state?.account_id, setFilters]);

    useEffect(() => {
        if (i18n.language) {
            setLanguage(i18n.language);
        }
    }, [i18n.language]);

    return (
        <FiltersPopover
            id={id}
            open={open}
            anchorEl={anchorEl}
            handleClose={handleClose}
            cleanFilters={cleanFilters}
            handleClick={handleClick}
            length={filters.filtersArray.filter((item) => item.type && (item.value || item.since || item.until)).length}
            addFilter={addFilter}
            disabled={filters.filtersArray.length === 6}
        >
            {filters.filtersArray.map((value, index) => (
                <Grid container spacing={2} alignItems="center" key={"convComponent_" + index} sx={{ mb: 2 }}>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <FormControl fullWidth variant="filled">
                                    <InputLabel
                                        id="clientStatus-simple-select-label"
                                        sx={{
                                            fontStyle: "normal",
                                            fontWeight: "600",
                                            color: "#131F3E",
                                        }}
                                    >
                                        {t("filters.attribute")}
                                    </InputLabel>
                                    <Select
                                        size="small"
                                        IconComponent={(props) => {
                                            if (props.className.includes("MuiSelect-iconOpen")) {
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(270deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform: "rotate(90deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }}
                                        value={value.type}
                                        displayEmpty
                                        onChange={(e) => handleChangeType(e, index)}
                                        className="ddlGeneral"
                                        input={
                                            <FilledInput
                                                label={t("filters.attribute")}
                                                sx={{
                                                    fontStyle: "normal",
                                                    fontWeight: "600",
                                                    color: "#131F3E",
                                                }}
                                            />
                                        }
                                        MenuProps={MenuProps}
                                        defaultValue=""
                                    >
                                        {value.type !== ""
                                            ? attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .concat(attributes.filter((item) => item.id === value.type))
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))
                                            : attributes
                                                .filter(
                                                    (item) =>
                                                        !filters.filtersArray
                                                            .map((item) => item.type)
                                                            .includes(item.id)
                                                )
                                                .map((item) => (
                                                    <MenuItem value={item.id} key={"attr_" + item.id}>
                                                        <Typography
                                                            variant="body1"
                                                            component="span"
                                                            align="center"
                                                            color="textPrimary"
                                                            sx={{
                                                                fontStyle: "normal",
                                                            }}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                    </MenuItem>
                                                ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            {value.type === "" && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled" disabled>
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.type}
                                            displayEmpty
                                            onChange={(e) => handleChangeType(e, index)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        ></Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 1 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        >
                                            {accounts.map((item) => (
                                                <MenuItem value={item.id} key={"accounts_" + item.id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.name}
                                                        {" | "}
                                                        {item.bankName}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {(value.type === 2 || value.type === 4) && (
                                <>
                                    <Grid item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    >
                                                        {t("filters.since")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                value={value.since !== "" ? value.since : null}
                                                onChange={(e) => handleChangeFilterRange(e, index, value.type, "since")}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                    <Grid item xs={6} md={3}>
                                        <LocalizationProvider
                                            dateAdapter={AdapterMoment}
                                            adapterLocale={t("language.locale")}
                                            localeText={{ clearButtonLabel: t("miscellaneous.clear") }}
                                        >
                                            <DatePicker
                                                label={
                                                    <Typography
                                                        sx={{
                                                            fontStyle: "normal",
                                                            fontWeight: "600",
                                                            color: "#131F3E",
                                                        }}
                                                    >
                                                        {t("filters.until")}
                                                    </Typography>
                                                }
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        style: { width: "100%" },
                                                        variant: "filled",
                                                        readOnly: true,
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                minDate={
                                                    filters.filtersArray[index]?.since !== ""
                                                        ? filters.filtersArray[index].since
                                                        : null
                                                }
                                                value={value.until !== "" ? value.until : null}
                                                onChange={(e) => handleChangeFilterRange(e, index, value.type, "until")}
                                            />
                                        </LocalizationProvider>
                                    </Grid>
                                </>
                            )}
                            {value.type === 3 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={MenuProps}
                                            defaultValue=""
                                        >
                                            {conversionFormats.map((item) => (
                                                <MenuItem value={item.id} key={"formats_" + item.id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {language === "es" ? item.name : item.name_en}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 5 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        >
                                            {users.map((item) => (
                                                <MenuItem value={item} key={"users_" + item}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                            {value.type === 6 && (
                                <Grid item xs={12} md={6}>
                                    <FormControl fullWidth variant="filled">
                                        <InputLabel
                                            id="clientStatus-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("filters.select")}
                                        </InputLabel>
                                        <Select
                                            size="small"
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            value={value.value}
                                            displayEmpty
                                            onChange={(e) => handleChangeFilter(e, index, value.type)}
                                            className="ddlGeneral"
                                            input={
                                                <FilledInput
                                                    label={t("filters.select")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        fontWeight: "600",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                    },
                                                },
                                            }}
                                            defaultValue=""
                                        >
                                            {status.map((item) => (
                                                <MenuItem value={item.id} key={"status_" + item.id}>
                                                    <Typography
                                                        variant="body1"
                                                        component="span"
                                                        align="center"
                                                        color="textPrimary"
                                                        sx={{
                                                            fontStyle: "normal",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Typography>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={1} display="flex" justifyContent="center">
                        {filters.filtersArray.length > 1 && (
                            <DeleteIcon
                                color="primary"
                                sx={{ cursor: "pointer" }}
                                onClick={() => removeFilter(index)}
                            />
                        )}
                        {filters.filtersArray.length === 1 &&
                            (value.value !== "" || value.since !== "" || value.until !== "") && (
                                <DeleteIcon
                                    color="primary"
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => removeFirstFilter()}
                                />
                            )}
                    </Grid>
                </Grid>
            ))}
        </FiltersPopover>
    );
};

export default FiltersButton;
