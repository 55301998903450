import { useState } from "react";

/* Componentes */
import SubscriptionManagement from "../components/management/Management";
import SimpleBackdrop from "../components/ui/SimpleBackdrop";
import BackGeneral from "../components/layout/BackDashboard";
import BaseSnackbar from "../components/ui/BaseSnackbar";

const SubscriptionManag = () => {
    const [isLoading, setIsLoading] = useState(false);

    const [show, setShow] = useState(false);
    const [texto, setTexto] = useState("");
    const [alertType, setAlertType] = useState("success");

    const handleClose = (event, reason) => {
        if (reason === "clickaway") {
            return;
        }

        setShow(false);
    };

    return (
        <>
            <BackGeneral color="#f8f8f9">
                <SubscriptionManagement
                    setIsLoading={setIsLoading}
                    setShow={setShow}
                    setTexto={setTexto}
                    setAlertType={setAlertType}
                />
            </BackGeneral>
            <SimpleBackdrop open={isLoading} />
            <BaseSnackbar
                type={alertType}
                show={show}
                message={texto}
                onClose={handleClose}
            />
        </>
    );
};

export default SubscriptionManag;
