/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";

/* Styles */
import classes from "./UserRegister.module.css";

/* MUI Imports */
import {
    Typography,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    styled,
    FilledInput,
    FormControl,
    Stack,
    Divider,
    IconButton,
    Box,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Button,
} from "@mui/material";
import GridUI from "@mui/material/Grid";

/* MUI Icons */
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

/* Services */
import authService from "../../../services/auth";
import userService from "../../../services/user";

/** Country flags */
import Flag from "react-world-flags";
import { useSelector } from "react-redux";

const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const UserRegisterTab1 = (props) => {
    const { isError, setIsError, errorEditMode, userData, handleChangeUserData, editMode, originList, phoneCode, setPhoneCode, countryCode, setCountryCode, allCountries, language, phoneNumber, setPhoneNumber, setUserData, compareUserData, setAlertType, existingEmail, setExistingEmail, errorEmail, setErrorEmail, setShow, setTexto, password, setPassword, updatePassword, setUpdatePassword, colorSecurityLevel, validateSecurityLevel, showChangePassword, setShowChangePassword, errorPassword, setErrorPassword, setOpenBackdrop } = props;
    
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const roleList = [
        { id: 1, data: t("register.accountant") },
        { id: 2, data: t("register.businessOwner") },
        { id: 3, data: t("register.manager") },
        { id: 4, data: t("register.administrator") },
        { id: 5, data: t("register.businessman") },
        { id: 6, data: t("register.other") },
    ];

    const countryAndPhoneInput = (
        <>
            <GridUI item xs={12} md={6}>
                <FormControl
                    fullWidth
                    variant="filled"
                    error={(isError || errorEditMode) && (userData.role === "" || !userData.role)}
                >
                    <InputLabel
                        id="type-simple-select-label"
                        sx={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            color: "#131F3E",
                        }}
                    >
                        {t("register.role")}
                    </InputLabel>
                    <Select
                        name="role"
                        IconComponent={(props) => {
                            if (props.className.includes("MuiSelect-iconOpen")) {
                                return (
                                    <PlayArrowIcon
                                        sx={{
                                            position: "absolute",
                                            transform: "rotate(270deg)",
                                            color: "#131F3E",
                                            right: ".5rem",
                                            cursor: "pointer",
                                            zIndex: 0,
                                            pointerEvents: "none",
                                        }}
                                    />
                                );
                            }
                            return (
                                <PlayArrowIcon
                                    sx={{
                                        position: "absolute",
                                        transform: "rotate(90deg)",
                                        color: "#131F3E",
                                        right: ".5rem",
                                        cursor: "pointer",
                                        zIndex: 0,
                                        pointerEvents: "none",
                                    }}
                                />
                            );
                        }}
                        value={userData.role ?? ""}
                        onChange={(event) => handleChangeUserData(event)}
                        fullWidth
                        input={
                            <FilledInput
                                label={t("register.role")}
                                sx={{
                                    fontStyle: "normal",
                                    color: "#131F3E",
                                }}
                            />
                        }
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    mt: 0.7,
                                    borderRadius: 2,
                                    boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                    maxHeight: "300px",
                                },
                            },
                        }}
                        renderValue={() => (
                            <Stack direction="row" spacing={0.5} alignItems="center">
                                <Typography>{roleList.find((rol) => rol.id === userData.role)?.data}</Typography>
                            </Stack>
                        )}
                    >
                        {roleList?.map(({ id, data }) => (
                            <MenuItem value={id} key={`${id}${data}`}>
                                {data}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </GridUI>
            <GridUI item xs={12} md={6}>
                <Grid container spacing={1}>
                    <Grid item xs={12} md={4}>
                        <FormControl
                            fullWidth
                            variant="filled"
                            error={(isError || errorEditMode) && (phoneCode === "" || !phoneCode)}
                        >
                            <InputLabel
                                id="type-simple-select-label"
                                sx={{
                                    fontStyle: "normal",
                                    fontWeight: "600",
                                    color: "#131F3E",
                                }}
                            >
                                {t("register.code")}
                            </InputLabel>
                            <Select
                                IconComponent={(props) => {
                                    if (props.className.includes("MuiSelect-iconOpen")) {
                                        return (
                                            <PlayArrowIcon
                                                sx={{
                                                    position: "absolute",
                                                    transform: "rotate(270deg)",
                                                    color: "#131F3E",
                                                    right: ".5rem",
                                                    cursor: "pointer",
                                                    zIndex: 0,
                                                    pointerEvents: "none",
                                                }}
                                            />
                                        );
                                    }
                                    return (
                                        <PlayArrowIcon
                                            sx={{
                                                position: "absolute",
                                                transform: "rotate(90deg)",
                                                color: "#131F3E",
                                                right: ".5rem",
                                                cursor: "pointer",
                                                zIndex: 0,
                                                pointerEvents: "none",
                                            }}
                                        />
                                    );
                                }}
                                name="phoneCode"
                                value={countryCode ?? ""}
                                onChange={(event) => handleChangePhoneCode(event)}
                                fullWidth
                                input={
                                    <FilledInput
                                        label={t("register.code")}
                                        sx={{
                                            fontStyle: "normal",
                                            color: "#131F3E",
                                        }}
                                    />
                                }
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            mt: 0.7,
                                            borderRadius: 2,
                                            boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                            maxHeight: "300px",
                                        },
                                    },
                                }}
                                renderValue={() => (
                                    <Stack direction="row" spacing={0.5} alignItems="center">
                                        <Flag code={countryCode} height="12" width="20" />
                                        <Typography>{phoneCode}</Typography>
                                    </Stack>
                                )}
                            >
                                {allCountries.map((option) => (
                                    <MenuItem value={option.country_code} key={option.country_code}>
                                        <Stack direction="row" spacing={1} alignItems="center">
                                            <Flag code={option.country_code} height="14" width="22" />
                                            <Typography>
                                                {sessionStorage.getItem("lng") === "en" || language === "en"
                                                    ? option.name_en
                                                    : option.name_es}
                                                {" ("}
                                                {option.phone_code + ")"}
                                            </Typography>
                                        </Stack>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <CustomTextField
                            fullWidth
                            label={t("register.phone")}
                            name="phoneNumber"
                            error={(isError || errorEditMode) && (phoneNumber === "" || !phoneNumber) ? true : false}
                            onChange={(event) => handleChangePhoneNumber(event)}
                            value={phoneNumber}
                            variant="filled"
                            inputProps={{
                                fontStyle: "normal",
                            }}
                            type="text"
                            autoComplete="one-time-code"
                        />
                    </Grid>
                </Grid>
            </GridUI>
        </>
    );

    const handleChangePhoneCode = (event) => {
        let codeFilter = allCountries.find((country) => country.country_code === event.target.value);
        if (codeFilter) {
            setCountryCode(event.target.value);
            setPhoneCode(codeFilter.phone_code);
        }
    };

    const handleChangePhoneNumber = (event) => {
        if (!isNaN(event.target.value)) {
            setPhoneNumber(event.target.value);
        }
    };

    const handleChangeCurrentEmail = async (event) => {
        setUserData({
            ...userData,
            email: event.target.value,
        });
        verifyEmail(event.target.value);
    };

    const verifyEmail = async (email) => {
        if (email.trim() && /.+@.+\..+/.test(email)) {
            await authService
                .verifyExistingUser(email)
                .then((response) => {
                    if (response.status === 200 && email !== compareUserData.email) {
                        setAlertType("error");
                        setExistingEmail(true);
                        setErrorEmail(true);
                        setShow(true);
                        setTexto(t("register.existingEmail"));
                    } else {
                        setExistingEmail(false);
                        setErrorEmail(false);
                    }
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        setExistingEmail(false);
                        setErrorEmail(false);
                    }
                });
        } else {
            setIsError(true);
        }
    };

    const renderPasswordInput = (
        label,
        type,
        name,
        onChange,
        value,
        error,
        onClick,
        onMouseDown,
        iconName,
        showVisibility,
        mt,
        onBlur
    ) => {
        return (
            <GridUI item xs={12} mt={mt ? mt : null}>
                <FormControl fullWidth variant="filled" error={error}>
                    <InputLabel
                        id="type-simple-select-label"
                        sx={{
                            fontStyle: "normal",
                            fontWeight: "600",
                            color: "#131F3E",
                        }}
                    >
                        {label}
                    </InputLabel>
                    <FilledInput
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        label={label}
                        name={name}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        type={type}
                        autoComplete="one-time-code"
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={onClick} onMouseDown={onMouseDown} edge="end" name={iconName}>
                                    {showVisibility ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
            </GridUI>
        );
    };
    
    const handleChangePassword = (event) => {
        if (!editMode) {
            setPassword({
                ...password,
                [event.target.name]: event.target.value,
            });
        } else {
            setUpdatePassword({
                ...updatePassword,
                [event.target.name]: event.target.value,
            });
        }
    };

    const handleClickShowPassword = (event) => {
        if (!editMode) {
            setPassword({
                ...password,
                showPassword: !password.showPassword,
            });
        } else {
            setUpdatePassword({
                ...updatePassword,
                showCurrentPassword: !updatePassword.showCurrentPassword,
            });
        }
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowRepeatPassword = (event) => {
        if (!editMode) {
            setPassword({
                ...password,
                showRepeatPassword: !password.showRepeatPassword,
            });
        } else {
            setUpdatePassword({
                ...updatePassword,
                showNewPassword: !updatePassword.showNewPassword,
            });
        }
    };

    const boxSecurityLevel = (color) => (
        <Box sx={{ backgroundColor: color, width: "40px", height: "15px", borderRadius: "4px", ml: 0.6 }}></Box>
    );
    
    const securityLevelContent = (
        <GridUI item xs={6}>
            <InputLabel className={classes.labels}>{t("register.securityLevel")}</InputLabel>
            <Box sx={{ mt: 1.5, mb: 2, display: "flex", flexDirection: "row" }}>
                {boxSecurityLevel(colorSecurityLevel.firstLevel)}
                {boxSecurityLevel(colorSecurityLevel.secondLevel)}
                {boxSecurityLevel(colorSecurityLevel.thirdLevel)}
                {boxSecurityLevel(colorSecurityLevel.fourthLevel)}
                {boxSecurityLevel(colorSecurityLevel.fifthLevel)}
            </Box>
            <List sx={{ mt: -2 }}>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.uppercaseLetter ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.uppercaseLetter")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.lowercaseLetter ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.lowercaseLetter")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.requiredNumber ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.requiredNumber")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            color={validateSecurityLevel.requiredSymbol ? "success" : "disabled"}
                            sx={{ fontSize: "20px" }}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.requiredSymbol")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
                <ListItem sx={{ mt: 0, mb: -2 }}>
                    <ListItemIcon>
                        <CheckCircleIcon
                            sx={{ fontSize: "20px" }}
                            color={validateSecurityLevel.eightCharacters ? "success" : "disabled"}
                        />
                    </ListItemIcon>
                    <ListItemText
                        primary={t("register.eightCharacters")}
                        className={classes.securityLevel}
                        primaryTypographyProps={{ style: { fontSize: "14px" } }}
                    />
                </ListItem>
            </List>
        </GridUI>
    );

    const handleShowChangePassword = () => {
        setShowChangePassword((show) => !show);
        setUpdatePassword({
            currentPassword: "",
            newPassword: "",
            repeatPassword: "",
            showCurrentPassword: false,
            showNewPassword: false,
            showRepeatNewPassword: false,
        });
    };

    const handleChangeCurrentPassword = async (event) => {
        setUpdatePassword({
            ...updatePassword,
            currentPassword: event.target.value,
        });
    };

    const verifyPass = async () => {
        setOpenBackdrop(true);
        await userService
            .verifyPassword({
                user_id: todos?.userInfo._id,
                password: updatePassword.currentPassword,
            })
            .then((response) => {
                if (response.status === 200) {
                    setErrorPassword(false);
                }
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    setErrorPassword(true);
                }
            });

        setOpenBackdrop(false);
    };

    const handleClickShowRepeatNewPassword = (event) => {
        setUpdatePassword({
            ...updatePassword,
            showRepeatNewPassword: !updatePassword.showRepeatNewPassword,
        });
    };

    return (
        <>
            <Grid container spacing={2}>
                <GridUI item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        error={
                            (isError || errorEditMode) && (userData.fullName === "" || !userData.fullName)
                                ? true
                                : false
                        }
                        label={t("register.fullName")}
                        name="fullName"
                        onChange={(event) => handleChangeUserData(event)}
                        value={userData.fullName}
                        variant="filled"
                        inputProps={{
                            fontStyle: "normal",
                        }}
                        type="text"
                    />
                </GridUI>
                {!editMode ? (
                    <>
                        <GridUI item xs={6}>
                            <FormControl
                                fullWidth
                                variant="filled"
                                error={
                                    (isError || errorEditMode) && (userData.role === "" || !userData.role)
                                        ? true
                                        : false
                                }
                            >
                                <InputLabel
                                    id="type-simple-select-label"
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        color: "#131F3E",
                                    }}
                                    shrink
                                >
                                    {t("register.role")}
                                </InputLabel>
                                <Select
                                    name="role"
                                    displayEmpty
                                    IconComponent={(props) => {
                                        if (props.className.includes("MuiSelect-iconOpen")) {
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform: "rotate(270deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }
                                        return (
                                            <PlayArrowIcon
                                                sx={{
                                                    position: "absolute",
                                                    transform: "rotate(90deg)",
                                                    color: "#131F3E",
                                                    right: ".5rem",
                                                    cursor: "pointer",
                                                    zIndex: 0,
                                                    pointerEvents: "none",
                                                }}
                                            />
                                        );
                                    }}
                                    value={userData.role ?? ""}
                                    onChange={(event) => handleChangeUserData(event)}
                                    fullWidth
                                    input={
                                        <FilledInput
                                            label={t("register.role")}
                                            sx={{
                                                fontStyle: "normal",
                                                color: "#131F3E",
                                            }}
                                        />
                                    }
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                maxHeight: "300px",
                                            },
                                        },
                                    }}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return t("register.select");
                                        }
                                        return roleList.find((rol) => rol.id === selected)?.data;
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <em>{t("register.select")}</em>
                                    </MenuItem>
                                    {roleList?.map(({ id, data }) => (
                                        <MenuItem value={id} key={`${id}${data}`}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridUI>
                        <GridUI item xs={6}>
                            <FormControl
                                fullWidth
                                variant="filled"
                                error={isError && userData.origin.length === 0 ? true : false}
                            >
                                <InputLabel
                                    id="type-simple-select-label"
                                    sx={{
                                        fontStyle: "normal",
                                        fontWeight: "600",
                                        color: "#131F3E",
                                    }}
                                    shrink
                                >
                                    {t("register.origin")}
                                </InputLabel>
                                <Select
                                    IconComponent={(props) => {
                                        if (props.className.includes("MuiSelect-iconOpen")) {
                                            return (
                                                <PlayArrowIcon
                                                    sx={{
                                                        position: "absolute",
                                                        transform: "rotate(270deg)",
                                                        color: "#131F3E",
                                                        right: ".5rem",
                                                        cursor: "pointer",
                                                        zIndex: 0,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            );
                                        }
                                        return (
                                            <PlayArrowIcon
                                                sx={{
                                                    position: "absolute",
                                                    transform: "rotate(90deg)",
                                                    color: "#131F3E",
                                                    right: ".5rem",
                                                    cursor: "pointer",
                                                    zIndex: 0,
                                                    pointerEvents: "none",
                                                }}
                                            />
                                        );
                                    }}
                                    displayEmpty
                                    name="origin"
                                    value={userData.origin}
                                    onChange={(event) => handleChangeUserData(event)}
                                    fullWidth
                                    input={
                                        <FilledInput
                                            label={t("register.origin")}
                                            sx={{
                                                fontStyle: "normal",
                                                color: "#131F3E",
                                            }}
                                        />
                                    }
                                    MenuProps={{
                                        PaperProps: {
                                            sx: {
                                                mt: 0.7,
                                                borderRadius: 2,
                                                boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                maxHeight: "300px",
                                            },
                                        },
                                    }}
                                    renderValue={(selected) => {
                                        if (selected.length === 0) {
                                            return t("register.select");
                                        }

                                        return originList.find((rol) => rol.id === selected)?.data;
                                    }}
                                >
                                    <MenuItem disabled value="">
                                        <em>{t("register.select")}</em>
                                    </MenuItem>
                                    {originList?.map(({ id, data }) => (
                                        <MenuItem value={id} key={`${id}${data}`}>
                                            {data}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </GridUI>
                        <GridUI item xs={6}>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <FormControl
                                        fullWidth
                                        variant="filled"
                                        error={
                                            (isError || errorEditMode) && (phoneCode === "" || !phoneCode)
                                                ? true
                                                : false
                                        }
                                    >
                                        <InputLabel
                                            id="type-simple-select-label"
                                            sx={{
                                                fontStyle: "normal",
                                                fontWeight: "600",
                                                color: "#131F3E",
                                            }}
                                        >
                                            {t("register.code")}
                                        </InputLabel>
                                        <Select
                                            IconComponent={(props) => {
                                                if (props.className.includes("MuiSelect-iconOpen")) {
                                                    return (
                                                        <PlayArrowIcon
                                                            sx={{
                                                                position: "absolute",
                                                                transform: "rotate(270deg)",
                                                                color: "#131F3E",
                                                                right: ".5rem",
                                                                cursor: "pointer",
                                                                zIndex: 0,
                                                                pointerEvents: "none",
                                                            }}
                                                        />
                                                    );
                                                }
                                                return (
                                                    <PlayArrowIcon
                                                        sx={{
                                                            position: "absolute",
                                                            transform: "rotate(90deg)",
                                                            color: "#131F3E",
                                                            right: ".5rem",
                                                            cursor: "pointer",
                                                            zIndex: 0,
                                                            pointerEvents: "none",
                                                        }}
                                                    />
                                                );
                                            }}
                                            name="phoneCode"
                                            value={countryCode ?? ""}
                                            onChange={(event) => handleChangePhoneCode(event)}
                                            fullWidth
                                            input={
                                                <FilledInput
                                                    label={t("register.code")}
                                                    sx={{
                                                        fontStyle: "normal",
                                                        color: "#131F3E",
                                                    }}
                                                />
                                            }
                                            MenuProps={{
                                                PaperProps: {
                                                    sx: {
                                                        mt: 0.7,
                                                        borderRadius: 2,
                                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                                        maxHeight: "300px",
                                                    },
                                                },
                                            }}
                                            renderValue={() => (
                                                <Stack direction="row" spacing={0.5} alignItems="center">
                                                    <Flag code={countryCode} height="12" width="20" />
                                                    <Typography>{phoneCode}</Typography>
                                                </Stack>
                                            )}
                                        >
                                            {allCountries.map((option) => (
                                                <MenuItem
                                                    value={option.country_code}
                                                    key={option.country_code}
                                                >
                                                    <Stack direction="row" spacing={1} alignItems="center">
                                                        <Flag
                                                            code={option.country_code}
                                                            height="14"
                                                            width="22"
                                                        />
                                                        <Typography>
                                                            {sessionStorage.getItem("lng") === "en" ||
                                                            language === "en"
                                                                ? option.name_en
                                                                : option.name_es}
                                                            {" ("}
                                                            {option.phone_code + ")"}
                                                        </Typography>
                                                    </Stack>
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={8}>
                                    <CustomTextField
                                        fullWidth
                                        label={t("register.phone")}
                                        name="phoneNumber"
                                        error={
                                            (isError || errorEditMode) &&
                                            (phoneNumber === "" || !phoneNumber)
                                                ? true
                                                : false
                                        }
                                        onChange={(event) => handleChangePhoneNumber(event)}
                                        value={phoneNumber}
                                        variant="filled"
                                        inputProps={{
                                            fontStyle: "normal",
                                        }}
                                        type="text"
                                        autoComplete="one-time-code"
                                    />
                                </Grid>
                            </Grid>
                        </GridUI>
                    </>
                ) : (
                    <>
                        <GridUI item xs={12} md={6}>
                            <CustomTextField
                                fullWidth
                                label={t("register.email")}
                                name="email"
                                onChange={(event) => handleChangeCurrentEmail(event)}
                                error={
                                    ((isError || errorEditMode || errorEmail) &&
                                        !/.+@.+\..+/.test(userData.email)) ||
                                    existingEmail
                                }
                                value={userData.email}
                                variant="filled"
                                inputProps={{
                                    fontStyle: "normal",
                                }}
                                type="text"
                            />
                        </GridUI>
                        {countryAndPhoneInput}
                    </>
                )}
            </Grid>
            {!editMode ? (
                <>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Divider sx={{ mt: 2, mb: 2, width: "50%" }} />
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            {renderPasswordInput(
                                t("register.password"),
                                password.showPassword ? "text" : "password",
                                "password",
                                handleChangePassword,
                                password.password,
                                isError &&
                                    (password.password === "" ||
                                        password.password !== password.repeatPassword)
                                    ? true
                                    : false,
                                handleClickShowPassword,
                                handleMouseDownPassword,
                                "showPassword",
                                password.showPassword
                            )}
                            {renderPasswordInput(
                                t("register.repeatPassword"),
                                password.showRepeatPassword ? "text" : "password",
                                "repeatPassword",
                                handleChangePassword,
                                password.repeatPassword,
                                isError &&
                                    (password.repeatPassword === "" ||
                                        password.password !== password.repeatPassword)
                                    ? true
                                    : false,
                                handleClickShowRepeatPassword,
                                handleMouseDownPassword,
                                "showRepeatPassword",
                                password.showRepeatPassword,
                                3
                            )}
                            {password.password &&
                                password.repeatPassword &&
                                password.password !== password.repeatPassword && (
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        {
                                            <Typography sx={{ fontSize: "12px" }} color="error" mt={1}>
                                                {t("register.passwordError")}
                                            </Typography>
                                        }
                                    </Box>
                                )}
                        </Grid>
                        {securityLevelContent}
                    </Grid>
                </>
            ) : !showChangePassword ? (
                <Button sx={{ mt: 2, mb: 2 }} onClick={handleShowChangePassword}>
                    {t("register.changePassword")}
                </Button>
            ) : null}
            {showChangePassword ? (
                <>
                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Divider sx={{ mt: 2, mb: 2, width: "50%" }} />
                    </div>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            {renderPasswordInput(
                                t("register.currentPassword"),
                                updatePassword.showCurrentPassword ? "text" : "password",
                                "currentPassword",
                                handleChangeCurrentPassword,
                                updatePassword.currentPassword,
                                errorPassword ? true : false,
                                handleClickShowPassword,
                                handleMouseDownPassword,
                                "showCurrentPassword",
                                updatePassword.showCurrentPassword,
                                3,
                                verifyPass
                            )}
                            {renderPasswordInput(
                                t("register.newPassword"),
                                updatePassword.showNewPassword ? "text" : "password",
                                "newPassword",
                                handleChangePassword,
                                updatePassword.newPassword,
                                isError &&
                                    (updatePassword.newPassword === "" ||
                                        updatePassword.newPassword !== updatePassword.repeatPassword)
                                    ? true
                                    : false,
                                handleClickShowRepeatPassword,
                                handleMouseDownPassword,
                                "showNewPassword",
                                updatePassword.showNewPassword,
                                3
                            )}
                            {renderPasswordInput(
                                t("register.repeatPassword"),
                                updatePassword.showRepeatNewPassword ? "text" : "password",
                                "repeatPassword",
                                handleChangePassword,
                                updatePassword.repeatPassword,
                                isError &&
                                    (updatePassword.repeatPassword === "" ||
                                        updatePassword.repeatPassword !== updatePassword.newPassword)
                                    ? true
                                    : false,
                                handleClickShowRepeatNewPassword,
                                handleMouseDownPassword,
                                "showRepeatPassword",
                                updatePassword.showRepeatNewPassword,
                                3
                            )}
                            {updatePassword.newPassword &&
                                updatePassword.repeatPassword &&
                                updatePassword.newPassword !== updatePassword.repeatPassword && (
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        {
                                            <Typography sx={{ fontSize: "12px" }} color="error" mt={1}>
                                                {t("register.passwordError")}
                                            </Typography>
                                        }
                                    </Box>
                                )}
                        </Grid>
                        {securityLevelContent}
                    </Grid>
                </>
            ) : null}
            <Grid item sx={{ display: "flex", justifyContent: "flex-end", mt: 1 }}>
                {editMode && showChangePassword && (
                    <Typography
                        onClick={handleShowChangePassword}
                        sx={{
                            color: "#4A22D4",
                            fontWeight: "600",
                            mr: "auto",
                            mt: 1,
                            cursor: "pointer",
                        }}
                    >
                        {t("register.cancelChangePass")}
                    </Typography>
                )}
            </Grid>
        </>
    );
};

export default UserRegisterTab1;
