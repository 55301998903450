/* MUI Imports */
import { Stack } from "@mui/material";
import UserRegister from "../components/auth/UserRegister/UserRegister";

const UserProfile = () => {
    return (
        <Stack alignItems="center" justifyContent="center" sx={{ width: "100%" }}>
            <UserRegister editMode={true} />
        </Stack>
    );
};

export default UserProfile;
