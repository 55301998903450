import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

/* Paginas */
import Authentication from "./pages/Authentication";
import Team from "./pages/Team";
import Dashboard from "./pages/Dashboard";
import Error404 from "./pages/Error404";
import Converter from "./pages/Converter";
import Accounts from "./pages/Accounts";
import Subscription from "./pages/Subscription";
import Inbox from "./pages/Inbox";
import UserProfile from "./pages/UserProfile";
import ExpiredInvitation from "./pages/ExpiredInvitation";
import Payments from "./pages/Payments";
import SubscriptionManag from "./pages/SubscriptionManag";
import Notifications from "./pages/Notifications";
import ComputerVisionChecks from "./pages/ComputerVisionChecks";
import ComputerVisionInvoices from "./pages/ComputerVisionInvoices";
import ComputerVisionStatements from "./pages/ComputerVisionStatements";
import ConverterInvoices from "./pages/Converter2";

import ITTeam from "./components/Js/ITTeam";

/* Componentes */
import NavBar from "./components/layout/NavBar";
import ScrollToTop from "./components/layout/ScrollToTop";
import Reconciliations from "./pages/Reconciliations";

const PrivateRoute = ({ children, isAuthenticated, hasAccess, isEmployee }) => {
    return isAuthenticated && hasAccess ? (
        isEmployee ? (
            <>
                <NavBar />
                {children}
            </>
        ) : (
            <div
                style={{
                    display: "flex",
                    position: "relative",
                    height: "100vh",
                }}
            >
                <NavBar />
                {children}
            </div>
        )
    ) : (
        <Navigate to="/" />
    );
};

function App() {
    const todos = useSelector((state) => state.value);
    const isEmployee = ITTeam.includes(todos?.userInfo?.email);
    const isAdmin = todos?.userRol !== 3;

    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route exact path="/" element={<Authentication />} />
                <Route
                    path="/dashboard"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={!isEmployee}
                            isEmployee={isEmployee}
                        >
                            <Dashboard />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/team"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={!isEmployee}
                            isEmployee={isEmployee}
                        >
                            <Team />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/accounts"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={!isEmployee}
                            isEmployee={isEmployee}
                        >
                            <Accounts />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/convert"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={!isEmployee}
                            isEmployee={isEmployee}
                        >
                            <Converter />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/subscription"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={!isEmployee && isAdmin}
                            isEmployee={isEmployee}
                        >
                            <Subscription />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={true}
                            isEmployee={isEmployee}
                        >
                            <UserProfile />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/payments"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={!isEmployee}
                            isEmployee={isEmployee}
                        >
                            <Payments />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/inbox"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={true}
                            isEmployee={isEmployee}
                        >
                            <Inbox />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/management"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={true}
                            isEmployee={isEmployee}
                        >
                            <SubscriptionManag />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/notifications"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={isEmployee}
                            isEmployee={isEmployee}
                        >
                            <Notifications />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/reconciliations"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={!isEmployee}
                            isEmployee={isEmployee}
                        >
                            <Reconciliations />
                        </PrivateRoute>
                    }
                />
                <Route path="/expired" element={<ExpiredInvitation />} />
                <Route path="/fullUsers" element={<ExpiredInvitation />} />
                <Route path="*" element={<Error404 />} />
                <Route
                    path="/ocr"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={isEmployee}
                            isEmployee={isEmployee}
                        >
                            <ComputerVisionInvoices />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/ocrChecks"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={isEmployee}
                            isEmployee={isEmployee}
                        >
                            <ComputerVisionChecks />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/ocrStatements"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={isEmployee}
                            isEmployee={isEmployee}
                        >
                            <ComputerVisionStatements />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/convert/invoices"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={!isEmployee}
                            isEmployee={isEmployee}
                        >
                            <ConverterInvoices conversion_type={1} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/convert/bills"
                    element={
                        <PrivateRoute
                            isAuthenticated={todos?.auth}
                            hasAccess={!isEmployee}
                            isEmployee={isEmployee}
                        >
                            <ConverterInvoices conversion_type={2} />
                        </PrivateRoute>
                    }
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
