import { Button, Card, CardContent, Grid, Paper, Stack, Typography, Skeleton, Box } from "@mui/material";
import { useTranslation } from "react-i18next";

import classes from "./SubscriptionTab3.module.css";
import CreditsRecord from "./CreditsRecord";

import InfoIcon from "@mui/icons-material/Info";
import LightTooltip from "../ui/LightTooltip";
import { useEffect, useState } from "react";
import credService from "../../services/subscription";
import userCompanyService from "../../services/user_company";
import subscriptionService from "../../services/subscription";
import moment from "moment";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { formatDate } from "../../utils/utils";

const SubscriptionTab3 = (props) => {
    const { setIsLoading, franchise_id, company_id, showSkeleton, setShowSkeleton, plansInfo, activePlan, setShowModalCredits } = props;

    const [credits, setCredits] = useState([]);
    const [creditsOG, setCreditsOG] = useState([]);
    const [totalCredits, setTotalCredits] = useState(0);
    const [planInfo, setPlanInfo] = useState(!activePlan ? plansInfo[0] : plansInfo.find((item) => item.levels.map((level) => level.id).includes(activePlan)));
    const [usersFranq, setUsersFranq] = useState([]);
    const [currentPlanInfo, setCurrentPlanInfo] = useState({});
    
    const [t] = useTranslation("global");
    const todos = useSelector((state) => state.value);

    const navigate = useNavigate();

    useEffect(() => {
        (async () => {

            await subscriptionService
                .getSubscription({
                    company_id: todos.companyID,
                })
                .then((response) => {
                    setCurrentPlanInfo(response);
                })
                .catch((err) => console.log(err));

            let plan = {}
            if (!activePlan) {
                plan = plansInfo[0]; // Micro plan
            } else {
                plan = plansInfo.find((item) => item.levels.map((level) => level.id).includes(activePlan))
            }
            setPlanInfo(plan);

            setShowSkeleton(true);

            const params = {
                franchise_id: franchise_id,
                company_id: todos.companyID,
                all: true,
            };

            let counter = 0;

            await credService
                .getCredits(params)
                .then((response) => {
                    setCredits(response.historyCredits);
                    setCreditsOG(response.historyCredits);
                    if (response.currentPlan) {
                        counter += response.currentPlan.count;
                    }
                    setTotalCredits(counter);
                })
                .catch((error) => {
                    console.log(error);
                });

            await userCompanyService
                .getAllUsersFranchise(params)
                .then(async (data) => {
                    setUsersFranq(data.users);
                })
                .catch((error) => {
                    console.log(error);
                });

            setShowSkeleton(false);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [company_id, franchise_id, setShowSkeleton, activePlan]);

    /** Component functions */
    const goToPlans = () => {
        navigate("/subscription", {
            state: {
                tab: 0,
            },
        });
    };

    const SkeletonCardContent = () => (
        <CardContent className={classes.sizeCard}>
            <Grid container spacing={2} alignItems="center" sx={{ paddingTop: 1 }}>
                <Grid item xs={12} md={8}>
                    <Skeleton variant="text" animation="wave" sx={{ mt: 1 }} />
                    <Skeleton variant="text" animation="wave" />
                    <Skeleton variant="text" animation="wave" />
                </Grid>
                <Grid item xs={12} md={4}>
                    <Skeleton
                        variant="text"
                        animation="wave"
                        width="35%"
                        height={70}
                        sx={{ ml: "auto", mt: 1 }}
                    />
                </Grid>
            </Grid>
        </CardContent>
    );

    const nextExp = () => {
        let moments = credits.filter((item) => item.movement === 1).map((credit) => moment.utc(credit.roll_over_date))
        return moment.min(moments).format(todos.dateFormat)
    }

    const handleCreditsModal = () => {
        setShowModalCredits(true)
    }
    
    return (
        <>
            <Paper square={false} elevation={0} sx={{ backgroundColor: "#f7f8f9", p: "15px 20px" }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: "none", borderRadius: "8px" }}>
                            {showSkeleton ? (
                                <SkeletonCardContent />
                            ) : (
                                <CardContent className={classes.sizeCard} sx={{display: "flex", alignItems: "center"}}>
                                    {/* On hold o Cancelado */}
                                    {todos.subscription &&
                                    (
                                        todos.subscription.value === 5 ||
                                        todos.subscription.value === 6 ||
                                        todos.subscription.value === 2) && (
                                        <Grid container spacing={2} alignItems="center" sx={{ paddingTop: 1 }}>
                                            <Grid item xs={12} md={7}>
                                                <Typography variant="h2" gutterBottom color="#4A22D4">
                                                    {t("credits.noSubs")}
                                                </Typography>
                                                <Typography>
                                                    {t("credits.subsBestSuit")}{" "}
                                                    <b>{t("credits.subsBestSuit2")}</b>
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12} md={5} className={classes.textCard}>
                                                <Typography fontSize={11} color="primary">
                                                    {t("credits.convMonthly")}
                                                </Typography>
                                                <Button
                                                    variant="contained"
                                                    className={classes.subsButton2}
                                                    onClick={goToPlans}
                                                    sx={{ width: { lg: "100%" } }}
                                                >
                                                    {t("credits.subsPlan")}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                    {/* Free trial */}
                                    {todos.subscription && (todos.subscription.value === 1 || todos.subscription.value === 4) && (
                                        <Stack direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent="space-between" sx={{width: "100%"}} spacing={2}>
                                            <Stack direction={{ xs: "column", md: "row" }} alignItems="center" spacing={{xs: 1, lg: 6}}>
                                                <Box>
                                                    <Typography variant="h1" gutterBottom fontSize={24}>
                                                        Plan {planInfo?.planTitle}
                                                    </Typography>
                                                    <Typography fontSize={13} color="primary" fontWeight={600} lineHeight="normal">
                                                        {todos?.subscription.value === 1 ? "14 " + t("subscription.freeTrialDays") : t("subscription.freeTrialEnded") }
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Button
                                                        variant="contained"
                                                        className={classes.purpleButton}
                                                        disableElevation
                                                        onClick={handleCreditsModal}
                                                        sx={{ width: { lg: "100%" }, whiteSpace: "nowrap" }}
                                                    >
                                                        {t("navBar.freeEnded2")}
                                                    </Button>
                                                </Box>
                                            </Stack>
                                            <Stack direction="column" alignItems="center" justifyContent="end" maxWidth="44%">
                                                <Typography fontSize={24} fontWeight={600} color="#ff5e77" textAlign="end" width="100%">
                                                    {planInfo?.levels.find((level) => level.id === activePlan)?.pages} <Typography fontSize={16} component="span" color="#131f3e">{t("subscription.pages")}</Typography>
                                                </Typography>
                                                <Box sx={{backgroundColor: "#efebfb", py: 0.5, px: 0.6, borderRadius: "8px"}}>
                                                    <Typography textAlign="start" fontSize={10} lineHeight="normal" maxWidth="100%" letterSpacing="-0.2px">{t("subscription.freePlanMsg")}</Typography>
                                                </Box>
                                            </Stack>
                                        </Stack>
                                    )}
                                    {/* Plan activo */}
                                    {todos.subscription && todos.subscription.value === 3 && (
                                        <Stack direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent="space-between" sx={{width: "100%"}}>
                                            <Stack direction={{ xs: "column", md: "row" }} alignItems="center" spacing={{xs: 1, lg: 6}}>
                                                <Box>
                                                    <Typography variant="h1" gutterBottom fontSize={24}>
                                                        Plan {planInfo?.planTitle}
                                                    </Typography>
                                                    <Typography fontSize={14} color="primary" fontWeight={600} lineHeight="normal">
                                                        {planInfo?.extension === 0 ? t("subscription.noExt") : planInfo?.extension + " " + t("subscription.extDays") }
                                                    </Typography>
                                                </Box>
                                                <Box>
                                                    <Button
                                                        variant="contained"
                                                        className={classes.whiteButton}
                                                        disableElevation
                                                        onClick={handleCreditsModal}
                                                        sx={{ width: { lg: "100%" }, whiteSpace: "nowrap" }}
                                                    >
                                                        {t("subscription.changePlan")}
                                                    </Button>
                                                </Box>
                                            </Stack>
                                            <Stack direction="column" alignItems="center" justifyContent="end">
                                                <Typography fontSize={24} fontWeight={600} color="primary" textAlign="end">
                                                    {planInfo?.levels.find((level) => level.id === activePlan)?.pages} <Typography fontSize={16} component="span" color="#131f3e">{t("register.pagesMonthly")}</Typography>
                                                </Typography>
                                                <Typography fontSize={16} textAlign="end">
                                                    {t("plans.nextExpired")}{" "}
                                                    <span style={{ color: "#4A22D4" }}>
                                                        {formatDate(currentPlanInfo?.since_date, todos.dateFormat)}
                                                    </span>
                                                </Typography>
                                            </Stack>
                                        </Stack>
                                    )}
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Card sx={{ boxShadow: "none", borderRadius: "8px" }}>
                            {showSkeleton ? (
                                <SkeletonCardContent />
                            ) : (
                                <CardContent className={classes.sizeCard} sx={{display: "flex", alignItems: "center"}}>
                                    <Stack direction={{ xs: "column", md: "row" }} alignItems="center" justifyContent="space-between" sx={{width: "100%"}}>
                                        <Stack direction={{ xs: "column", md: "row" }} alignItems="center" spacing={{xs: 1, lg: 6}}>
                                            <Box>
                                                <Typography variant="h1" gutterBottom fontSize={24}>
                                                    {t("subscription.totalPag")}
                                                </Typography>
                                                <Stack direction="row" alignItems="center" spacing={1}>
                                                    <Typography fontSize={14}>
                                                        {(totalCredits === 0) || (planInfo?.planId > 1 && todos.subscription.value !== 1) ? t("subscription.noExpPages") : 
                                                        <Typography fontSize={14} textAlign="end">
                                                            {t("subscription.nextEx")}{" "}
                                                            <span style={{ color: "#4A22D4" }}>
                                                                {nextExp()}
                                                            </span>
                                                        </Typography>}
                                                    </Typography>
                                                    <LightTooltip
                                                        title={
                                                            <Typography fontSize={12}>
                                                                {t("plans.thisDateExp")}{" "}
                                                                <span style={{ color: "#0FF" }}>
                                                                    {activePlan?.count_plan ?? ""} {t("credits.credits")}
                                                                </span>{" "}
                                                                {t("plans.oldestCharge")}
                                                                <br />
                                                                {planInfo.extension > 0 && <span style={{ color: "#02f92a" }}>
                                                                    {`${t("plans.conversionExtension")} +${planInfo.extension} ${t("miscellaneous.days")}.`}
                                                                </span>}
                                                            </Typography>
                                                        }
                                                    >
                                                        <InfoIcon sx={{ fontSize: 14 }} color="primary" />
                                                    </LightTooltip>
                                                </Stack>
                                            </Box>
                                        </Stack>
                                        <Stack direction="row" alignItems="center" justifyContent="end" spacing={0.8}>
                                            <Typography fontSize={48} fontWeight={600} color="primary" textAlign="end">
                                                {totalCredits}
                                            </Typography>
                                            <Typography component="span" fontSize={20}>{t("subscription.pages")}</Typography>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            )}
                        </Card>
                    </Grid>
                </Grid>
            </Paper>
            <CreditsRecord
                setIsLoading={setIsLoading}
                franchise_id={franchise_id}
                company_id={company_id}
                credits={credits}
                creditsOG={creditsOG}
                setCredits={setCredits}
                setCreditsOG={setCreditsOG}
                showSkeleton={showSkeleton}
                setShowSkeleton={setShowSkeleton}
                usersFranq={usersFranq}
            />
        </>
    );
};

export default SubscriptionTab3;
