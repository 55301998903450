import {
    Badge,
    Box,
    Button,
    Grid,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
    Stack,
    ToggleButton,
    Typography,
} from "@mui/material";

import ToggleButtonGroup, { toggleButtonGroupClasses } from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import CheckIcon from "@mui/icons-material/Check";

import PlansCard from "./PlansCard";
import { useTranslation } from "react-i18next";

import Discount from "../../../assets/15off.svg";
import LightTooltip from "../../ui/LightTooltip";
import InfoIcon from "@mui/icons-material/Info";
import plansInfo from "../../Js/plans";
import FormDialog from "../../ui/FormDialog";
import CancelPlanForm from "../CancelPlanForm";
import { useState } from "react";
import { useSelector } from "react-redux";

import subscriptionService from "../../../services/subscription";
import moment from "moment/moment";
import { isCustomPlan } from "../../../utils/utils";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    [`& .${toggleButtonGroupClasses.grouped}`]: {
        margin: theme.spacing(0.5),
        border: 0,
        borderRadius: 10,
    },
    [`& .${toggleButtonGroupClasses.firstButton},& .${toggleButtonGroupClasses.lastButton}`]: {
        width: 120,
    },
}));

const PlanSubs = (props) => {
    const {
        setTitleModal,
        setOpenAlert,
        setAgreeBtnMsg,
        setDisagreeBtnMsg,
        setBodyMess,
        setCurrentPlan,
        paymentType,
        setPaymentType,
        setCurrentLevel,
        setPriceMonthly,
        setPriceMonthlyOG,
        origin,
        activePlan,
        currentPlan,
        setIsLoading,
        setShowCart,
        companyDetails,
        handleShowCart,
        maxFreeCompanies,
        currentFreeCompanies,
        subsType,
    } = props;

    const [t] = useTranslation("global");

    const todos = useSelector((state) => state.value);

    const [openAlertCancel, setOpenAlertCancel] = useState(false);
    const [reasons, setReasons] = useState(null);
    const [details, setDetails] = useState("");
    const [isError, setIsError] = useState(false);

    const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
            if (companyDetails?.last_invoice?.line_items[0]?.line_qty === 365) {
                // Si la compra anterior fue anual, solo puede hacer upgrade de manera anual también
                setPaymentType("annual");
            } else {
                setPaymentType(newAlignment);
            }
        }
    };

    const handleCloseDialog = () => {
        setOpenAlert(false);
        setOpenAlertCancel(false);
        setReasons(null);
        setDetails("");
        setIsError(false);
    };

    const cancelPlan = async () => {
        if (!reasons) {
            setIsError(true);
        } else {
            if (reasons === "6" && details.trim() === "") {
                setIsError(true);
            } else {
                const newVAl = {
                    subscription_plan: activePlan,
                    line_type: 7,
                    line_amount: 0.0,
                    line_unit_amount: 0.0,
                    line_qty: 1,
                    line_since_date: moment().format(),
                    line_end_date: moment().add(1, "M").format(),
                };

                const params = {
                    company_id: todos.companyID,
                    cart_lines: [newVAl],
                };

                setCurrentPlan({
                    value: activePlan,
                    custom: isCustomPlan(companyDetails.subscription.subscription_plan),
                    type: "cancel",
                    endDate: companyDetails.subscription.end_date,
                });

                setPriceMonthlyOG(0);
                setCurrentLevel(activePlan);

                setIsLoading(true);

                await subscriptionService
                    .updateCart(params)
                    .then()
                    .catch((error) => {
                        console.log(error);
                    });

                setIsLoading(false);

                setShowCart(true);
            }
        }
    };

    const handleOpenModal = () => {
        setOpenAlertCancel(true);
    };

    const planFeatures = [t("plans.customFormat"), t("plans.userProfile"), t("plans.unlimitedStorage")];

    const iconStyles = { color: "#4A22D4", cursor: "pointer", ml: 0.5, mb: "-2px" };

    const cancelPlanButtons = (
        <Grid container justifyContent="space-between" sx={{ width: 600 }}>
            <Grid item>
                <Button onClick={handleCloseDialog}>{t("team.cancel")}</Button>
            </Grid>
            <Grid item>
                <Button variant="contained" onClick={cancelPlan}>
                    {t("subscription.confirm")}
                </Button>
            </Grid>
        </Grid>
    );

    return (
        <>
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h1" fontSize={24}>
                            {origin === "updateSubscription"
                                ? t("plans.compPlans")
                                : origin === "editCompany" || origin === "addCompany"
                                ? ""
                                : t("plans.choosePlanFirst")}
                        </Typography>
                        {/* {origin === "updateSubscription" && (
                            <Typography>
                                {t("plans.dontKnow")}{" "}
                                <Typography sx={{ color: "#6544DB" }} fontWeight={600}>
                                    {t("plans.answerQu")}
                                </Typography>
                            </Typography>
                        )} */}
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack
                        direction="row"
                        spacing={2}
                        alignItems="center"
                        justifyContent={origin === "addCompany" ? "end" : "end"}
                    >
                        {/* {origin === "addCompany" && (
                            <Typography>
                                {t("plans.dontKnow")}{" "}
                                <Typography sx={{ color: "#6544DB" }} fontWeight={600}>
                                    {t("plans.answerQu")}
                                </Typography>
                            </Typography>
                        )} */}
                        <Stack direction="row" spacing={2} alignItems="center" justifyContent="end">
                            <Typography variant="h2">{t("plans.paymentMode")}</Typography>
                            <Paper
                                elevation={0}
                                sx={{
                                    display: "flex",
                                    border: `1px solid #6544DB`,
                                    flexWrap: "wrap",
                                    borderRadius: 3,
                                }}
                            >
                                <StyledToggleButtonGroup
                                    value={paymentType}
                                    exclusive
                                    onChange={handleAlignment}
                                    aria-label="paymentType"
                                >
                                    <ToggleButton
                                        value="monthly"
                                        aria-label="monthly"
                                        sx={{
                                            backgroundColor: paymentType === "monthly" ? "#E5E0F9 !important" : "white",
                                            "&:hover": {
                                                backgroundColor:
                                                    paymentType === "monthly" ? "#EAE6FA !important" : "lightgray",
                                            },
                                        }}
                                    >
                                        {t("navBar.monthly")}
                                    </ToggleButton>
                                    <Badge badgeContent={<img src={Discount} alt="Badge" />}>
                                        <ToggleButton
                                            value="annual"
                                            aria-label="annual"
                                            sx={{
                                                backgroundColor:
                                                    paymentType === "annual" ? "#E5E0F9 !important" : "white",
                                                "&:hover": {
                                                    backgroundColor:
                                                        paymentType === "annual" ? "#EAE6FA !important" : "lightgray",
                                                },
                                            }}
                                        >
                                            {t("navBar.annual")}
                                        </ToggleButton>
                                    </Badge>
                                </StyledToggleButtonGroup>
                            </Paper>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>
            <Grid container spacing={2} mt={1}>
                <Grid item xs={12} md>
                    <Typography variant="h1">{t("plans.allPlansInc")}</Typography>
                    <List>
                        {planFeatures.map((item, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemIcon sx={{ minWidth: 35 }}>
                                    <CheckIcon color="primary" fontSize="small" />
                                </ListItemIcon>
                                <ListItemText primary={item} />
                            </ListItem>
                        ))}
                    </List>
                    <Typography my={3} sx={{ textTransform: "capitalize" }}>
                        {t("plans.pageMonth")}
                    </Typography>
                    <Typography variant="h1" gutterBottom>
                        {t("plans.docConv")}
                    </Typography>
                    <Box pl={2}>
                        <Typography gutterBottom>
                            {t("converter.checks")}
                            <LightTooltip title={t("plans.checksTooltip")}>
                                <InfoIcon fontSize="inherit" sx={iconStyles} />
                            </LightTooltip>
                        </Typography>
                        <Typography gutterBottom>
                            {t("plans.factRec")}
                            <LightTooltip title={t("plans.invTooltip")}>
                                <InfoIcon fontSize="inherit" sx={iconStyles} />
                            </LightTooltip>
                        </Typography>
                        <Typography>
                            {t("converter.bankState")}
                            <LightTooltip title={t("plans.eeccTooltip")}>
                                <InfoIcon fontSize="inherit" sx={iconStyles} />
                            </LightTooltip>
                        </Typography>
                    </Box>
                    <Typography variant="h1" gutterBottom mt={1}>
                        {t("plans.customerSupp")}
                    </Typography>
                    <Box pl={2}>
                        <Typography gutterBottom>
                            Onboarding
                            <LightTooltip title={t("plans.onboardingTooltip")}>
                                <InfoIcon fontSize="inherit" sx={iconStyles} />
                            </LightTooltip>
                        </Typography>
                        <Typography gutterBottom>
                            {t("plans.training")}
                            <LightTooltip title={t("plans.trainingTooltip")}>
                                <InfoIcon fontSize="inherit" sx={iconStyles} />
                            </LightTooltip>
                        </Typography>
                        <Typography gutterBottom>
                            {t("plans.channels")}
                            <LightTooltip title={t("plans.channelsTooltip")}>
                                <InfoIcon fontSize="inherit" sx={iconStyles} />
                            </LightTooltip>
                        </Typography>
                        <Typography>
                            {t("plans.people")}
                            <LightTooltip title={t("plans.peopleTooltip")}>
                                <InfoIcon fontSize="inherit" sx={iconStyles} />
                            </LightTooltip>
                        </Typography>
                    </Box>
                    <Typography variant="h1" gutterBottom mt={1}>
                        {t("plans.addFeatures")}
                    </Typography>
                    <Box pl={2}>
                        <Typography gutterBottom>
                            {t("plans.users1")}
                            <LightTooltip title={t("plans.usersTooltip")}>
                                <InfoIcon fontSize="inherit" sx={iconStyles} />
                            </LightTooltip>
                        </Typography>
                        <Typography gutterBottom>
                            {t("plans.panelMonit")}
                            <LightTooltip title={t("plans.monitoringTooltip")}>
                                <InfoIcon fontSize="inherit" sx={iconStyles} />
                            </LightTooltip>
                        </Typography>
                        <Typography gutterBottom>
                            {t("plans.productUpd")}
                            <LightTooltip title={t("plans.productTooltip")}>
                                <InfoIcon fontSize="inherit" sx={iconStyles} />
                            </LightTooltip>
                        </Typography>
                        <Typography>
                            {t("plans.exten")}
                            <LightTooltip title={t("plans.rollOverTooltip")}>
                                <InfoIcon fontSize="inherit" sx={iconStyles} />
                            </LightTooltip>
                        </Typography>
                    </Box>
                </Grid>
                {plansInfo
                    .filter((item) => (subsType === 1 ? item.planId !== 0 : true))
                    .map((item) => (
                        <Grid item xs={12} md={subsType === 1 ? 3.2 : 2.5} key={item.planId}>
                            <PlansCard
                                plan={item}
                                levels={
                                    item.planId === 1 && subsType !== 1
                                        ? item.levels.filter((lev) => lev.id > 2)
                                        : item.levels
                                }
                                paymentType={paymentType}
                                setTitleModal={setTitleModal}
                                setOpenAlert={setOpenAlert}
                                setAgreeBtnMsg={setAgreeBtnMsg}
                                setDisagreeBtnMsg={setDisagreeBtnMsg}
                                setBodyMess={setBodyMess}
                                setCurrentPlan={setCurrentPlan}
                                setLevelGlobal={setCurrentLevel}
                                setPriceMonthlyGlobal={setPriceMonthly}
                                setPriceMonthlyOG={setPriceMonthlyOG}
                                origin={origin}
                                activePlan={activePlan}
                                currentPlan={currentPlan}
                                handleShowCart={handleShowCart}
                                maxFreeCompanies={maxFreeCompanies}
                                currentFreeCompanies={currentFreeCompanies}
                                subsType={subsType}
                                handleOpenModal={handleOpenModal}
                            />
                        </Grid>
                    ))}
            </Grid>
            <FormDialog
                open={openAlertCancel}
                handleClose={handleCloseDialog}
                maxWidth="md"
                title={
                    <>
                        <span style={{ color: "#4A22D4" }}>{todos?.userInfo?.user_name ?? ""}</span>, {t("plans.regretDes")}
                    </>
                }
                content={
                    <CancelPlanForm
                        reasons={reasons}
                        setReasons={setReasons}
                        details={details}
                        setDetails={setDetails}
                        isError={isError}
                        currentPlanInfo={companyDetails}
                    />
                }
                actions={cancelPlanButtons}
                custom={true}
            />
        </>
    );
};

export default PlanSubs;
