/** React imports */
import React from "react";
import { useTranslation } from "react-i18next";
import businessActivity from "../../Js/businessActivity";

/* Styles */
import classes from "./UserRegister.module.css";

/* MUI Imports */
import {
    Typography,
    Grid,
    TextField,
    InputLabel,
    Select,
    MenuItem,
    InputAdornment,
    Paper,
    styled,
    FilledInput,
    FormControl,
    Link,
    FormControlLabel,
    Checkbox,
} from "@mui/material";
import GridUI from "@mui/material/Grid";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import InfoIcon from "@mui/icons-material/Info";

/** Components imports */
import CustomFilledSelect from "../../ui/CustomFilledSelect";
import LightTooltip from "../../ui/LightTooltip";


const CustomTextField = styled(TextField)({
    "& label": {
        color: "#131F3E",
        fontWeight: 600,
    },
});

const UserRegisterTab2 = (props) => {
    const { userData, setUserData, handleChangeUserData, isError, errorEditMode, allCountries, language, subscriptionType, inheritData, setInheritData, invitation, checkTandC, setCheckTandC, termsLink } = props;

    const [t] = useTranslation("global");

    const handleChangeInherit = (event) => {
        setInheritData(event.target.checked);
        if (event.target.checked) {
            setUserData({
                ...userData,
                company_name: userData.practice_name,
                company_country_name: userData.country,
            });
        } else {
            setUserData({
                ...userData,
                company_name: "",
                company_country_name: "",
            });
        }
    };

    let bussinessActFinal = [];

    for (let i = 0; i < businessActivity.length; i++) {
        bussinessActFinal.push({
            id: businessActivity[i].id,
            name: t("dialog." + businessActivity[i].translation),
        });
    }

    const toolsList = [
        { id: 0, data: "Xero" },
        { id: 1, data: "Quickbooks desktop" },
        { id: 2, data: "Quickbooks online" },
        { id: 3, data: "Profit" },
        { id: 4, data: "Excel" },
        { id: 5, data: "Odoo" },
        { id: 6, data: "Sage 50cloud" },
        { id: 7, data: "KashFlow" },
        { id: 8, data: "FuseMetrix" },
        { id: 9, data: "SortMyBooks" },
        { id: 10, data: "FreeAgent" },
        { id: 11, data: "Accounts IQ" },
        { id: 12, data: "ClearBooks" },
        { id: 13, data: "ReckonOne" },
        { id: 14, data: "AccountRightLive" },
        { id: 15, data: "Exact" },
        { id: 16, data: "FreshBooks" },
        { id: 17, data: "Wave" },
        { id: 18, data: "Zoho Books" },
        { id: 19, data: "NetSuite" },
        { id: 20, data: "Kashoo" },
        { id: 21, data: "AccountEdge" },
        { id: 22, data: "Bill.com" },
        { id: 23, data: t("register.other") },
    ];

    const handleChangeCheck = (event) => {
        setCheckTandC(event.target.checked);
    };

    return (
        <>
            <Typography
                sx={{ py: 2.5 }}
                dangerouslySetInnerHTML={{
                    __html: t("register.createSubsMsg"),
                }}
            ></Typography>
            <Typography fontWeight={600} sx={{ mt: 3, fontSize: 16 }}>
                {t("register.subsInfo")}
            </Typography>
            <Grid container sx={{ mt: 0 }} spacing={2}>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("register.subsName")}
                        name="practice_name"
                        required={true}
                        onChange={(event) => handleChangeUserData(event)}
                        value={userData.practice_name ?? ""}
                        variant="filled"
                        InputProps={{
                            fontStyle: "normal",
                            color: "#131F3E",
                            endAdornment: (
                                <InputAdornment position="end">
                                    <LightTooltip title={t("register.tooltipPractice")}>
                                        <InfoIcon
                                            fontSize="inherit"
                                            sx={{ color: "#4A22D4", cursor: "pointer" }}
                                        />
                                    </LightTooltip>
                                </InputAdornment>
                            ),
                        }}
                        error={
                            (isError || errorEditMode) &&
                            (userData.practice_name === "" || !userData.practice_name)
                                ? true
                                : false
                        }
                    />
                </Grid>
                <GridUI item xs={12} md={6}>
                    <FormControl
                        fullWidth
                        variant="filled"
                        error={
                            (isError || errorEditMode) && (userData.country === "" || !userData.country)
                                ? true
                                : false
                        }
                        required
                    >
                        <InputLabel
                            id="type-simple-select-label"
                            sx={{
                                fontStyle: "normal",
                                fontWeight: "600",
                                color: "#131F3E",
                            }}
                        >
                            {t("register.country")}
                        </InputLabel>
                        <Select
                            IconComponent={(props) => {
                                if (props.className.includes("MuiSelect-iconOpen")) {
                                    return (
                                        <PlayArrowIcon
                                            sx={{
                                                position: "absolute",
                                                transform: "rotate(270deg)",
                                                color: "#131F3E",
                                                right: ".5rem",
                                                cursor: "pointer",
                                                zIndex: 0,
                                                pointerEvents: "none",
                                            }}
                                        />
                                    );
                                }
                                return (
                                    <PlayArrowIcon
                                        sx={{
                                            position: "absolute",
                                            transform: "rotate(90deg)",
                                            color: "#131F3E",
                                            right: ".5rem",
                                            cursor: "pointer",
                                            zIndex: 0,
                                            pointerEvents: "none",
                                        }}
                                    />
                                );
                            }}
                            name="country"
                            value={userData.country ?? ""}
                            onChange={(event) => handleChangeUserData(event)}
                            fullWidth
                            input={
                                <FilledInput
                                    label={t("register.country")}
                                    sx={{
                                        fontStyle: "normal",
                                        color: "#131F3E",
                                    }}
                                />
                            }
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        maxHeight: "300px",
                                    },
                                },
                            }}
                        >
                            {allCountries.map((option) => (
                                <MenuItem value={option.code} key={option.code}>
                                    {sessionStorage.getItem("lng") === "en" || language === "en"
                                        ? option.name_en
                                        : option.name_es}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <Grid item xs={12} md={6}>
                    <CustomFilledSelect
                        error={isError && !userData.subscription_type}
                        required
                        label={t("register.subsType")}
                        value={userData.subscription_type ?? ""}
                        onChange={(event) => handleChangeUserData(event)}
                        name="subscription_type"
                        values={subscriptionType}
                        idName="id"
                        valueName="name"
                    />
                </Grid>
            </Grid>
            <Typography fontWeight={600} sx={{ mt: 4, fontSize: 16 }}>
                {t("register.firstCompanyInfo")}
            </Typography>
            <Paper elevation={0}>
                <FormControlLabel
                    control={<Checkbox checked={inheritData} onChange={handleChangeInherit} size="small" />}
                    label={<Typography>{t("register.copySubsInfo")}</Typography>}
                />
            </Paper>
            <Grid container sx={{ mt: 0 }} spacing={2}>
                <Grid item xs={12} md={6}>
                    <CustomTextField
                        fullWidth
                        label={t("register.companyName")}
                        name="company_name"
                        required={true}
                        onChange={(event) => handleChangeUserData(event)}
                        value={userData.company_name ?? ""}
                        variant="filled"
                        error={
                            (isError || errorEditMode) &&
                            (userData.company_name === "" || !userData.company_name)
                                ? true
                                : false
                        }
                        InputProps={{
                            endAdornment: inheritData ? (
                                <InputAdornment position="end">
                                    <LightTooltip title={t("register.tooltipSubsComp")}>
                                        <InfoIcon
                                            fontSize="inherit"
                                            sx={{ color: "#4A22D4", cursor: "pointer" }}
                                        />
                                    </LightTooltip>
                                </InputAdornment>
                            ) : null,
                        }}
                    />
                </Grid>
                <GridUI item xs={12} md={6}>
                    <FormControl
                        fullWidth
                        variant="filled"
                        error={
                            (isError || errorEditMode) &&
                            (userData.company_country_name === "" || !userData.company_country_name)
                                ? true
                                : false
                        }
                        required
                    >
                        <InputLabel
                            id="type-simple-select-label"
                            sx={{
                                fontStyle: "normal",
                                fontWeight: "600",
                                color: "#131F3E",
                            }}
                        >
                            {t("register.country")}
                        </InputLabel>
                        <Select
                            IconComponent={(props) => {
                                if (props.className.includes("MuiSelect-iconOpen")) {
                                    return (
                                        <PlayArrowIcon
                                            sx={{
                                                position: "absolute",
                                                transform: "rotate(270deg)",
                                                color: "#131F3E",
                                                right: ".5rem",
                                                cursor: "pointer",
                                                zIndex: 0,
                                                pointerEvents: "none",
                                            }}
                                        />
                                    );
                                }
                                return (
                                    <PlayArrowIcon
                                        sx={{
                                            position: "absolute",
                                            transform: "rotate(90deg)",
                                            color: "#131F3E",
                                            right: ".5rem",
                                            cursor: "pointer",
                                            zIndex: 0,
                                            pointerEvents: "none",
                                        }}
                                    />
                                );
                            }}
                            name="company_country_name"
                            value={userData.company_country_name ?? ""}
                            onChange={(event) => handleChangeUserData(event)}
                            fullWidth
                            input={
                                <FilledInput
                                    label={t("register.country")}
                                    sx={{
                                        fontStyle: "normal",
                                        color: "#131F3E",
                                    }}
                                />
                            }
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        maxHeight: "300px",
                                    },
                                },
                            }}
                        >
                            {allCountries.map((option) => (
                                <MenuItem value={option.code} key={option.code}>
                                    {sessionStorage.getItem("lng") === "en" || language === "en"
                                        ? option.name_en
                                        : option.name_es}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </GridUI>
                <Grid item xs={12} md={6}>
                    <CustomFilledSelect
                        error={isError && !userData.business_activity}
                        required
                        label={t("dialog.ecActivity")}
                        value={userData.business_activity ?? ""}
                        onChange={(event) => handleChangeUserData(event)}
                        name="business_activity"
                        values={bussinessActFinal}
                        idName="id"
                        valueName="name"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <FormControl
                        fullWidth
                        variant="filled"
                        error={isError && userData.tools.length === 0 ? true : false}
                        required
                    >
                        <InputLabel
                            id="type-simple-select-label"
                            sx={{
                                fontStyle: "normal",
                                fontWeight: "600",
                                color: "#131F3E",
                            }}
                        >
                            {t("register.accountingSystems")}
                        </InputLabel>
                        <Select
                            IconComponent={(props) => {
                                if (props.className.includes("MuiSelect-iconOpen")) {
                                    return (
                                        <PlayArrowIcon
                                            sx={{
                                                position: "absolute",
                                                transform: "rotate(270deg)",
                                                color: "#131F3E",
                                                right: ".5rem",
                                                cursor: "pointer",
                                                zIndex: 0,
                                                pointerEvents: "none",
                                            }}
                                        />
                                    );
                                }
                                return (
                                    <PlayArrowIcon
                                        sx={{
                                            position: "absolute",
                                            transform: "rotate(90deg)",
                                            color: "#131F3E",
                                            right: ".5rem",
                                            cursor: "pointer",
                                            zIndex: 0,
                                            pointerEvents: "none",
                                        }}
                                    />
                                );
                            }}
                            name="tools"
                            multiple
                            value={userData.tools}
                            onChange={(event) => handleChangeUserData(event)}
                            fullWidth
                            renderValue={(selected) => selected.join(", ")}
                            input={
                                <FilledInput
                                    label={t("register.accountingSystems")}
                                    sx={{
                                        fontStyle: "normal",
                                        color: "#131F3E",
                                    }}
                                />
                            }
                            MenuProps={{
                                PaperProps: {
                                    sx: {
                                        mt: 0.7,
                                        borderRadius: 2,
                                        boxShadow: "10px 10px 15px 0px rgba(3,24,81,0.15)",
                                        maxHeight: "300px",
                                    },
                                },
                            }}
                        >
                            {toolsList?.map(({ id, data }) => (
                                <MenuItem value={data} key={`${id}${data}`}>
                                    <Checkbox checked={userData.tools.includes(data)} />
                                    {data}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                {invitation && (
                    <Grid item xs={12}>
                        <Paper elevation={0} className={classes.message}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checkTandC}
                                        onChange={handleChangeCheck}
                                        size="small"
                                    />
                                }
                                label={
                                    <Typography>
                                        {t("login.tryText")}{" "}
                                        <Link
                                            sx={{ fontWeight: 600, textDecoration: "none" }}
                                            href={termsLink}
                                            target="_blank"
                                        >
                                            {t("login.tryText3")}
                                        </Link>{" "}
                                    </Typography>
                                }
                            />
                        </Paper>
                    </Grid>
                )}
            </Grid>
        </>
    );
};

export default UserRegisterTab2;
