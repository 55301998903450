/** React imports */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

/** MUI imports */
import {
    Dialog,
    DialogContent,
    DialogTitle,
    Typography,
    Box,
    Divider,
    Button,
    Paper,
    Grid,
    Stack,
    IconButton,
} from "@mui/material";

/** Components */
import LightTooltip from "../ui/LightTooltip";

/** Icons */
import Visa from "../../assets/visa.svg";
import Mastercard from "../../assets/mastercard.svg";
import Amex from "../../assets/amex.svg";
import Discover from "../../assets/discover.svg";
import Diners from "../../assets/diners.svg";
import JCB from "../../assets/jcb.svg";
import UnionPay from "../../assets/unionpay.svg";

/** MUI Icons */
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";

/** Styles */
import classes from "./AddCreditPacksDialog.module.css";
import { styled } from "@mui/material/styles";

/** Services */
import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import payService from "../../services/payment_methods";
import userCompanyService from "../../services/user_company";
import subscriptionService from "../../services/subscription";
import SimpleDialog from "../ui/SimpleDialog";
import { addCredits, addSubscription } from "../../redux/userSlice";
import plansInfo from "../Js/plans";
import { formatAmount, getCompanyCredits, updateCartPlan } from "../../utils/utils";

import CheckIcon from "../../assets/CheckIcon.svg"

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 50,
    },
    "& .MuiDialogTitle-root": {
        fontSize: 22,
        fontWeight: 600,
        color: "#4a22d4",
        padding: "30px 45px 5px 45px",
    },
    "& .MuiDialogContent-root": {
        padding: theme.spacing(0),
    },
}));

const AddCreditPacksDialog = (props) => {
    const {
        open,
        onClose,
        getPaymentMethods,
        setShowAddPay,
        setShowChangePay,
        activePlan,
        paymentMethod,
        setIsLoading,
        companyDetails,
        allCompanies,
        paymentType
    } = props;

    const todos = useSelector((state) => state.value);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    /** Internationalization i18n */
    const [t] = useTranslation("global");

    /** Component States */
    const [cartItems, setCartItems] = useState([]);
    const [payStatus, setPayStatus] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const goToPlans = () => {
        onClose();
        navigate("/subscription", {
            state: {
                tab: 0,
            },
        });
    };

    const handleCloseModalCredits = () => {
        setOpenModal(false);
        resetCart();
        onClose();
    };

    const changePaymentMethod = (hasCard) => {
        onClose();
        if (hasCard) {
            setShowChangePay(true);
        } else {
            setShowAddPay(true);
        }
    };

    const deleteItem = async (item) => {
        let packsFilter = cartItems.filter(
            (pack) => pack.line_type !== item.line_type || pack.line_amount !== item.line_amount
        );

        const params = {
            franchise_id: todos.franchiseID,
            cart_lines: packsFilter,
        };

        setIsLoading(true);

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        setIsLoading(false);

        setCartItems(packsFilter);
    };

    const isCompanyFreeTrial = todos.subscription?.value === 1 ?? false;
    const isCompanyOnHold = todos.subscription?.value === 4 ?? false;
    const prevCompanyFreeTrial = todos.subscription?.last_value === 1 ?? false;

    const existsLastInvoicePlan = companyDetails?.last_invoice?.line_items[0]?.subscription_plan >= 0;
    const getLastInvoicePlan =
        existsLastInvoicePlan
            ? plansInfo.find((item) =>
                item.levels.some((item) => item.id === companyDetails.last_invoice.line_items[0].subscription_plan)
            )
            : null;

    const getLastInvoiceLevel =
        existsLastInvoicePlan
            ? plansInfo
                .flatMap((item) => item.levels)
                .find((item) => item.id === companyDetails.last_invoice.line_items[0].subscription_plan)
            : null;

    const handleAdd = async () => {
        let nextPlan = companyDetails.franchise_id.type === 1 ? 1 : 3;
        if (activePlan) {
            if (isCompanyFreeTrial || (isCompanyOnHold && prevCompanyFreeTrial)) { // Está en free trial
                nextPlan = activePlan
            } else {
                nextPlan = plansInfo.flatMap((item) => item.levels).find((item) => item.id === activePlan)?.custom === true ? activePlan : activePlan + 1
            }
        }

        let newVAl = await updateCartPlan(
            isCompanyFreeTrial || (isCompanyOnHold && prevCompanyFreeTrial) ? 1 : 4,
            nextPlan,
            "monthly",
            companyDetails,
            setIsLoading,
            allCompanies.length,
            "month"
        );

        setCartItems(newVAl);
    };

    const resetCart = async () => {
        setIsLoading(true);

        const params = {
            franchise_id: todos.franchiseID,
            company_id: todos.companyID,
            cart_lines: [],
        };

        await subscriptionService
            .updateCart(params)
            .then()
            .catch((error) => {
                console.log(error);
            });

        const credits = await getCompanyCredits(todos.companyID);
        dispatch(addCredits(credits));

        await userCompanyService
            .getBusinessDirectoryDetails({ company_id: todos.companyID })
            .then(async (data) => {
                dispatch(addSubscription({
                    value: data.id_status,
                    last_value: data.id_last_status,
                    plan: data.subscription.subscription_plan,
                }))
            })
            .catch((err) => {
                console.log(err);
            });

        setCartItems([]);

        setIsLoading(false);
    };

    const getFinalPages = () => {
        let pagesDisc = 0;

        const pagesMonthly = plansInfo.flatMap(item => item.levels).find(level => level.id === cartItems[0].subscription_plan).pages

        if ((isCompanyFreeTrial || (isCompanyOnHold && prevCompanyFreeTrial))) {
            return pagesMonthly
        } else {
            if (getLastInvoicePlan !== null) {
                const quantity = companyDetails.last_invoice.line_items[0].line_qty;
    
                let discPages = 0;
                if (quantity === 30) {
                    // Mensual
                    discPages = getLastInvoiceLevel.pages;
                } else if (quantity === 365) {
                    // Anual
                    discPages = getLastInvoiceLevel.pages;
                } else {
                    // Por día
                    discPages = (getLastInvoiceLevel.pages / 30) * quantity;
                }
                pagesDisc = discPages;
            }
    
            return pagesMonthly - pagesDisc;
        }
    };

    const creditsContent = (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                {payStatus ? (
                    <Box
                        component="img"
                        // sx={{
                        //     height: 80,
                        //     width: 80,
                        //     maxHeight: { xs: 80, md: 80 },
                        //     maxWidth: { xs: 80, md: 80 },
                        // }}
                        src={CheckIcon}
                    />
                ) : (
                    <CancelIcon sx={{ fontSize: 70, color: "#ED1A1A" }} />
                )}
            </Grid>
            <Grid item>
                <Typography fontSize={20} fontWeight={700}>
                    {payStatus ? t("dialog.successPay") : t("dialog.errorPay")}
                </Typography>
                {payStatus ? (
                    <Typography fontSize={16}>
                        {`${t("dialog.creditsNew")} `}
                        <b style={{ color: "#4a22d4" }}>{`${getFinalPages()} ${t("dialog.creditsText")
                        }`}</b>
                    </Typography>
                ) : (
                    <Typography fontSize={16}>{t("dialog.errorPayText")}</Typography>
                )}
            </Grid>
        </Grid>
    );
    
    const getDiscountByPlan = () => {
        return plansInfo.find((plan) => plan.levels.map((item2) => item2.id).includes(cartItems[0].subscription_plan))
            .discounts.find((item) => allCompanies.length >= item.min && allCompanies.length <= item.max).discount;
    };

    const getTotal = () => {
        if (cartItems.length > 0) {
            let totalWithoutDisc;
            let totalDisc = 0;
    
            const priceMonthlyOG = plansInfo.flatMap(item => item.levels).find(level => level.id === cartItems[0].subscription_plan).price
    
            if (paymentType === "annual") {
                totalWithoutDisc = priceMonthlyOG * 12;
            } else {
                totalWithoutDisc = priceMonthlyOG;
            }
    
            if (paymentType === "annual") {
                // Descuento del 15% por comprar plan anual
                totalDisc += totalWithoutDisc * 0.15;
            }
    
            if (allCompanies.length >= 11) {
                // Descuento variable por tener igual o más de 11 emrpesas
                totalDisc += totalWithoutDisc * getDiscountByPlan();
            }
    
            if (!getLastInvoicePlan !== null) {
                // Descuento de monto fijo por ya tener un plan activo
                totalDisc += companyDetails?.last_invoice?.line_items[0]?.line_amount ?? 0;
            }
    
            return totalWithoutDisc - totalDisc;
        }
        return 0;
    };

    const ItemInfo = ({ title, description, amount, isDiscount, format = true }) => (
        <Grid item xs={12}>
            {description && (
                <Typography variant="h2" m={0}>
                    {title}
                </Typography>
            )}
            <Stack direction="row" spacing={1} alignItems="center" mb={0.5} justifyContent="space-between" width="100%">
                {description ? (
                    <Typography fontWeight={600} fontSize={12}>
                        {description}
                    </Typography>
                ) : (
                    <Typography variant="h2" m={0}>
                        {title}
                    </Typography>
                )}
                <Typography fontSize={14} color={isDiscount ? "#ff5e77" : "#131f3e"}>
                    {isDiscount && "-"} US$ {format ? formatAmount(amount, todos.amountFormat) : amount}
                </Typography>
            </Stack>
        </Grid>
    );

    const handlePayment = async () => {
        if (paymentMethod) {
            let nextPlan = companyDetails.franchise_id.type === 1 ? 1 : 3;
            if (activePlan) {
                if (isCompanyFreeTrial || (isCompanyOnHold && prevCompanyFreeTrial)) { // Está en free trial
                    nextPlan = activePlan
                } else {
                    nextPlan = plansInfo.flatMap((item) => item.levels).find((item) => item.id === activePlan)?.custom === true ? activePlan : activePlan + 1
                }
            }

            const params = {
                total_amount: parseInt(getTotal().toFixed(2).toString().replace(".", "") ?? 0),
                franchise_id: todos.franchiseID,
                company_id: todos.companyID,
                customer_id: paymentMethod.customer_id,
                payment_id: paymentMethod.payment_id,
                payment_method_id: paymentMethod._id,
                origin: "addCreditsPackDialog",
                planId: nextPlan
            };

            setIsLoading(true);

            await payService
                .chargeSavedCard(params)
                .then(async () => {
                    setPayStatus(true);
                })
                .catch((err) => {
                    console.log(err.response.data);
                    setPayStatus(false);
                    // Se envía un correo de pago manual fallido
                    payService.sendEmailFailedPayment(params);
                });
            setOpenModal(true);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getPaymentMethods();
        handleAdd()
        getTotal()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [todos.subscription]);

    return (
        <BootstrapDialog
            open={open}
            onClose={handleCloseModalCredits}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth="md"
            fullWidth
        >
            <DialogTitle id="alert-dialog-title" sx={{mx: "45px", mb: 2}}>
                <Typography fontSize={20} fontWeight={600}>
                    {activePlan ? t("credits.noCreditsPlan") : t("navBar.noCredits3")}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box className={classes.spacing}>
                    <Paper
                        square={false}
                        sx={{ backgroundColor: activePlan && !(isCompanyFreeTrial || (isCompanyOnHold && prevCompanyFreeTrial)) ? "#FCE3063D" : "#00FFFF3D", mb: 4, mt: 1,  p: "20px 30px", borderRadius: "20px !important" }}
                    >
                        <Grid container spacing={2} justifyContent="space-between">
                            <Grid item xs={12} md={8}>
                                <Typography color="primary" fontSize={18} fontWeight={600}>
                                    {activePlan && !(isCompanyFreeTrial || (isCompanyOnHold && prevCompanyFreeTrial)) ? t("credits.upPosibilities") : t("credits.subsNow")}
                                </Typography>
                                <Typography fontSize={15} mt={1}>
                                    {activePlan && !(isCompanyFreeTrial || (isCompanyOnHold && prevCompanyFreeTrial))  ? t("credits.subsNowMsg2") : t("credits.subsNowMsg")}
                                </Typography>
                            </Grid>
                            <Grid item sx={{ textAlign: "center", display: "flex", alignItems: "center" }}>
                                <Button variant="contained" color="primary" onClick={goToPlans} sx={{ px: 7, fontWeight: 500 }}>
                                    {t("credits.subsNowBtn")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Box>
                <Box className={classes.resumeCard} sx={{ mb: 0, mt: 3 }}>
                    <Grid container justifyContent="space-between">
                        <Grid item xs={12} md={4} sx={{ p: "20px 0 20px 40px" }}>
                            <Typography fontStyle="normal" fontWeight={600} fontSize={24} color="#131F3E">
                                {t("credits.paymentMethods")}
                            </Typography>
                            {!paymentMethod?.card_number && <Button onClick={() => changePaymentMethod(paymentMethod?.card_number)}>
                                {"+ " + t("credits.add")}
                            </Button>}
                            {paymentMethod?.card_number && (
                                <Stack direction="row" alignItems="center" sx={{ mt: 2 }} spacing={2}>
                                    <Box
                                        component="img"
                                        src={
                                            paymentMethod.brand === "visa"
                                                ? Visa
                                                : paymentMethod.brand === "mastercard"
                                                ? Mastercard
                                                : paymentMethod.brand === "amex"
                                                ? Amex
                                                : paymentMethod.brand === "discover"
                                                ? Discover
                                                : paymentMethod.brand === "diners"
                                                ? Diners
                                                : paymentMethod.brand === "unionpay"
                                                ? UnionPay
                                                : JCB
                                        }
                                        sx={{ width: 45 }}
                                    />
                                    <Typography fontWeight={600}>
                                        {`${
                                            paymentMethod?.brand.charAt(0).toUpperCase() + paymentMethod?.brand.slice(1)
                                        } ${t("credits.endingIn")} *${paymentMethod?.card_number.split("-").pop()}`}
                                    </Typography>
                                    <Button onClick={() => changePaymentMethod(paymentMethod?.card_number)}>
                                        {t("credits.editOrChange")}
                                    </Button>
                                </Stack>
                            )}
                        </Grid>
                        <Grid item>
                            <Divider orientation="vertical" className={classes.divider} />
                        </Grid>
                        <Grid item xs={12} md={7} sx={{ p: { xs: "20px 40px 20px 30px", md: "20px 40px 20px 0px" } }}>
                            <Typography
                                fontStyle="normal"
                                fontWeight={600}
                                fontSize={24}
                                sx={{ pb: 2 }}
                                color="#131F3E"
                            >
                                {t("subscription.orderSummary")}
                            </Typography>
                            <Stack direction="row" spacing={2}>
                                <Box minWidth={500}>
                                    {cartItems.length > 0 && cartItems.map((item) => (
                                        <Grid
                                            container
                                            justifyContent="space-between"
                                            key={item.line_qty}
                                            spacing={2}
                                            sx={{ mb: 1 }}
                                            alignItems="end"
                                        >
                                            <Grid item>
                                                <Typography variant="h3">
                                                    Plan {plansInfo.find((plan) => plan.levels.map((item2) => item2.id).includes(item.subscription_plan))?.planTitle}{" "} <Typography component="span" fontWeight={500}>- {plansInfo.flatMap((plan) => plan.levels).find((level) => level.id === item.subscription_plan)?.pages} {t("register.pagesMonthly")}</Typography>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Stack direction="row" spacing={1} alignItems="end">
                                                    <Typography variant="h3">
                                                        $
                                                        {parseFloat(item.line_amount).toLocaleString(
                                                            todos.amountFormat,
                                                            {
                                                                minimumFractionDigits: 2,
                                                            }
                                                        )}
                                                        {item.subscription_plan && `/${t("plans.month")}`}
                                                    </Typography>
                                                    <IconButton onClick={() => deleteItem(item)} sx={{p: 0}}>
                                                        <DeleteIcon sx={{ fontSize: "18px", color: "#4A22D4" }} />
                                                    </IconButton>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    ))}
                                    
                                    {paymentType === "annual" && cartItems.length > 0 && (
                                        <>
                                            <Divider />
                                            <Grid container spacing={1} sx={{ my: 0.5 }}>
                                                <ItemInfo title={"Subtotal"} amount={plansInfo.flatMap(item => item.levels).find(level => level.id === cartItems[0].subscription_plan).price * 12} isDiscount={false} />
                                            </Grid>
                                            <Divider />
                                            <Grid container spacing={1} sx={{ my: 0.5 }}>
                                                <ItemInfo
                                                    title={t("plans.discAnnual")}
                                                    amount={plansInfo.flatMap(item => item.levels).find(level => level.id === cartItems[0].subscription_plan).price * 12 * 0.15}
                                                    isDiscount={true}
                                                />
                                                {allCompanies.length >= 11 && (
                                                    <ItemInfo
                                                        title={t("plans.discountWithAPlan")}
                                                        description={`${allCompanies.length} ${t("plans.companiesCreated")} (${getDiscountByPlan() * 100
                                                            } %)`}
                                                        amount={plansInfo.flatMap(item => item.levels).find(level => level.id === cartItems[0].subscription_plan).price * 12 * getDiscountByPlan()}
                                                        isDiscount={true}
                                                    />
                                                )}
                                                {getLastInvoicePlan !== null && (
                                                    <ItemInfo
                                                        title={t("management.actualAmountPlan")}
                                                        description={
                                                            <>
                                                                Plan {getLastInvoicePlan.planTitle}
                                                                {" - "}
                                                                <Typography component="span" fontWeight={400} fontSize={12}>
                                                                    {getLastInvoiceLevel.pages} {t("register.pagesMonthly")}
                                                                </Typography>
                                                            </>
                                                        }
                                                        amount={companyDetails.last_invoice.line_items[0].line_amount}
                                                        isDiscount={true}
                                                    />
                                                )}
                                            </Grid>
                                        </>
                                    )}
                                    {paymentType === "monthly" && cartItems.length > 0 && (
                                        <>
                                            <Divider />
                                            <Grid container spacing={1} sx={{ my: 0.5 }}>
                                                <ItemInfo
                                                    title={"Subtotal"}
                                                    amount={plansInfo.flatMap(item => item.levels).find(level => level.id === cartItems[0].subscription_plan).price}
                                                    isDiscount={false}
                                                />
                                            </Grid>
                                            {(allCompanies.length >= 11 || getLastInvoicePlan !== null) && (
                                                <>
                                                    <Divider />
                                                    <Grid container spacing={1} sx={{ my: 0.5 }}>
                                                        {allCompanies.length >= 11 && (
                                                            <ItemInfo
                                                                title={t("plans.discountWithAPlan")}
                                                                description={`${allCompanies.length} ${t("plans.companiesCreated")} (${getDiscountByPlan() * 100
                                                                    } %)`}
                                                                amount={plansInfo.flatMap(item => item.levels).find(level => level.id === cartItems[0].subscription_plan).price * getDiscountByPlan()}
                                                                isDiscount={true}
                                                            />
                                                        )}
                                                        {getLastInvoicePlan !== null && (
                                                            <ItemInfo
                                                                title={t("management.actualAmountPlan")}
                                                                description={
                                                                    <>
                                                                        Plan {getLastInvoicePlan.planTitle}
                                                                        {" - "}
                                                                        <Typography component="span" fontWeight={400} fontSize={12}>
                                                                            {getLastInvoiceLevel.pages} {t("register.pagesMonthly")}
                                                                        </Typography>
                                                                    </>
                                                                }
                                                                amount={companyDetails.last_invoice.line_items[0].line_amount}
                                                                isDiscount={true}
                                                            />
                                                        )}
                                                    </Grid>
                                                </>
                                            )}
                                        </>
                                    )}
                                    <Divider sx={{ pr: 2 }} />
                                    <Grid container justifyContent="space-between" spacing={1} sx={{ my: 1 }}>
                                        <Grid item>
                                            <Typography variant="h3">Total</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h3" sx={{ pr: 2 }}>
                                                $
                                                {parseFloat(getTotal()).toLocaleString(todos.amountFormat, {
                                                    minimumFractionDigits: 2,
                                                })}
                                            </Typography>
                                        </Grid>
                                        {cartItems.length > 0 && <Grid item xs={12}>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography>{t("subscription.purchaseRec")}</Typography>
                                                <Typography color="primary">
                                                    <b>{getFinalPages()}</b>{" "}{t("credits.newPages")} 
                                                </Typography>
                                            </Stack>
                                        </Grid>}
                                    </Grid>
                                </Box>
                                <Box>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={!paymentMethod?.card_number || cartItems.length === 0}
                                        className={classes.buttonPurple}
                                        onClick={handlePayment}
                                    >
                                        {t("credits.pay")}
                                    </Button>
                                    <LightTooltip title={t("credits.creditValidity")}>
                                        <Stack direction="row" spacing={1} sx={{ mt: 1 }} alignItems="center">
                                            <InfoIcon color="primary" sx={{ fontSize: 15 }} />
                                            <Typography
                                                fontStyle="normal"
                                                fontWeight={500}
                                                fontSize={12}
                                                color={"#4A22D4"}
                                            >
                                                {t("credits.terms")}
                                            </Typography>
                                        </Stack>
                                    </LightTooltip>
                                    <Typography sx={{ maxWidth: "200px", fontSize: 12, textAlign: "justify", mt: 1, lineHeight: "normal", color: "#131F3E" }}>
                                        {t("credits.conditionWarning")}
                                    </Typography>
                                </Box>
                            </Stack>
                        </Grid>
                    </Grid>
                </Box>
            </DialogContent>
            <SimpleDialog
                open={openModal}
                handleClose={handleCloseModalCredits}
                maxWidth="sm"
                content={creditsContent}
            />
        </BootstrapDialog>
    );
};

export default AddCreditPacksDialog;
