import {
    Box,
    Button,
    Checkbox,
    Chip,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    tableCellClasses,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import AddIcon from "@mui/icons-material/Add";
import CheckIcon from "@mui/icons-material/Check";

import { styled } from "@mui/material/styles";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import LightTooltip from "../../ui/LightTooltip";
import FiltersButtonDet from "./FiltersButtonDet";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        color: theme.palette.primary,
        fontWeight: 600,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        padding: "5px 15px",
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    backgroundColor: "white",
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
    "& td": {
        border: 0,
    },
    "& td:first-of-type": {
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "& td:last-child": {
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
}));

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 310,
            width: 200,
        },
    },
};

const checkIconDown = (props) => {
    if (props.className.includes("MuiSelect-iconOpen")) {
        return (
            <PlayArrowIcon
                sx={{
                    position: "absolute",
                    transform: "rotate(270deg)",
                    color: "#131F3E",
                    right: ".5rem",
                    cursor: "pointer",
                    zIndex: 0,
                    pointerEvents: "none",
                }}
            />
        );
    }
    return (
        <PlayArrowIcon
            sx={{
                position: "absolute",
                transform: "rotate(90deg)",
                color: "#131F3E",
                right: ".5rem",
                cursor: "pointer",
                zIndex: 0,
                pointerEvents: "none",
            }}
        />
    );
};

const defaultFilter = {
    filtersArray: [
        {
            type: "",
            value: "",
        },
    ],
};

const IcomeTableDet = (props) => {
    const { rows, accountsXero, xeroTaxRates, setRows, conversionData, rowsOG, setRowsOG } = props;
    const [selected, setSelected] = useState([]);
    const [editGlobal, setEditGlobal] = useState(false);
    const [edited, setEdited] = useState([]);
    const [filters, setFilters] = useState(defaultFilter);

    const todos = useSelector((state) => state.value);
    const [t] = useTranslation("global");

    const isEdited = (id) => edited.indexOf(id) !== -1;

    const columns = [
        { name: "description", title: t("accounts.description"), width: "15%" },
        { name: "type", title: t("incomes.type"), width: "8%" },
        { name: "quantity", title: t("converter.qty"), align: "right" },
        { name: "amount", title: `${t("credits.amount")} ${conversionData.formatted_currency}`, align: "right" },
        { name: "account", title: t("accounts.accountNum") },
        { name: "tax", title: t("accounts.tax") },
        { name: "total", title: `Total ${conversionData.formatted_currency}`, align: "right" },
        { name: "actions", title: <></>, width: "8%" },
    ];

    const generateUniqueId = () => Date.now().toString(36) + Math.random().toString(36).substring(2, 9);

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const handleEditRow = (id) => {
        setEditGlobal(!editGlobal);

        const checkedIndex = edited.indexOf(id);
        let newChecked = [];

        if (checkedIndex === -1) {
            newChecked = newChecked.concat(edited, id);
        } else if (checkedIndex === 0) {
            newChecked = newChecked.concat(edited.slice(1));
        } else if (checkedIndex === edited.length - 1) {
            newChecked = newChecked.concat(edited.slice(0, -1));
        } else if (checkedIndex > 0) {
            newChecked = newChecked.concat(edited.slice(0, checkedIndex), edited.slice(checkedIndex + 1));
        }

        setEdited(newChecked);
    };

    const handleDeleteRow = (id) => {
        setRows(rows.filter((elemento) => elemento._id !== id));
        setRowsOG(rowsOG.filter((elemento) => elemento._id !== id));
    };

    const handleChangeUP = (event, id) => {
        if (event.target.value > 0 || event.target.value === "") {
            setRows(
                rows.map((elemento) => {
                    if (elemento._id === id) {
                        let type = elemento.formatted_credit ? "formatted_credit" : "formatted_debit";
                        elemento[type] = parseFloat(event.target.value);
                    }
                    return elemento;
                })
            );
            setRowsOG(
                rowsOG.map((elemento) => {
                    if (elemento._id === id) {
                        let type = elemento.formatted_credit ? "formatted_credit" : "formatted_debit";
                        elemento[type] = parseFloat(event.target.value);
                    }
                    return elemento;
                })
            );
        }
    };

    const handleChangeAmount = (event, id) => {
        if (event.target.value > 0 || event.target.value === "") {
            setRows(
                rows.map((elemento) => {
                    if (elemento._id === id) {
                        elemento.formatted_quantity = parseInt(event.target.value);
                    }
                    return elemento;
                })
            );
            setRowsOG(
                rowsOG.map((elemento) => {
                    if (elemento._id === id) {
                        elemento.formatted_quantity = parseInt(event.target.value);
                    }
                    return elemento;
                })
            );
        }
    };

    const handleChangeDescription = (event, id) => {
        setRows(
            rows.map((elemento) => {
                if (elemento._id === id) {
                    elemento.description = event.target.value;
                }
                return elemento;
            })
        );
        setRowsOG(
            rowsOG.map((elemento) => {
                if (elemento._id === id) {
                    elemento.description = event.target.value;
                }
                return elemento;
            })
        );
    };

    const handleClickType = (newType, id) => {
        setRows(
            rows.map((elemento) => {
                if (elemento._id === id) {
                    elemento.line_type = newType;
                }
                return elemento;
            })
        );
        setRowsOG(
            rowsOG.map((elemento) => {
                if (elemento._id === id) {
                    elemento.line_type = newType;
                }
                return elemento;
            })
        );
    };

    const handleNewRow = () => {
        const newRowId = generateUniqueId();
        setRows([
            ...rows,
            {
                new_line: true,
                _id: newRowId,
                description: "",
                line_type: 1,
                formatted_quantity: "",
                formatted_credit: 0,
                formatted_debit: 0,
            },
        ]);
        setRowsOG([
            ...rowsOG,
            {
                new_line: true,
                _id: newRowId,
                description: "",
                line_type: 1,
                formatted_quantity: "",
                formatted_credit: 0,
                formatted_debit: 0,
            },
        ]);
    };

    const handleChangeSelectTax = (event, id) => {
        let newRows = [];
        let newRowsOG = [];

        if (selected.length === rows.length) {
            newRows = rows.map((aux) => ({
                ...aux,
                tax_type: event.target.value,
            }));
            newRowsOG = rowsOG.map((aux) => ({
                ...aux,
                tax_type: event.target.value,
            }));
        } else {
            newRows = rows.map((aux) => {
                if (id === aux._id) {
                    aux.tax_type = event.target.value;
                }
                return aux;
            });
            newRowsOG = rowsOG.map((aux) => {
                if (id === aux._id) {
                    aux.tax_type = event.target.value;
                }
                return aux;
            });
        }

        setRows(newRows);
        setRowsOG(newRowsOG);
    };

    const handleChangeSelectAcc = (event, id) => {
        let newRows = [];
        let newRowsOG = [];

        if (selected.length === rows.length) {
            newRows = rows.map((aux) => ({
                ...aux,
                account_code: event.target.value,
            }));
            newRowsOG = rowsOG.map((aux) => ({
                ...aux,
                account_code: event.target.value,
            }));
        } else {
            newRows = rows.map((aux) => {
                if (id === aux._id) {
                    aux.account_code = event.target.value;
                }
                return aux;
            });
            newRowsOG = rowsOG.map((aux) => {
                if (id === aux._id) {
                    aux.account_code = event.target.value;
                }
                return aux;
            });
        }
        setRows(newRows);
        setRowsOG(newRowsOG);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n._id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleClickCheck = (id) => {
        const selectedIndex = selected.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    return (
        <Box
            sx={{
                backgroundColor: "#F8F8F9",
                px: 3,
                py: 2,
                borderRadius: 4,
                mt: 2,
            }}
        >
            <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography variant="h2">{t("incomes.linesRepExt")}</Typography>
                <FiltersButtonDet
                    filters={filters}
                    setFilters={setFilters}
                    setRows={setRows}
                    ogData={rowsOG}
                    setSelected={setSelected}
                />
            </Stack>
            <TableContainer>
                <Table
                    sx={{
                        borderCollapse: "separate",
                        borderSpacing: "0 6px",
                        minWidth: 1300,
                    }}
                >
                    <TableHead>
                        <TableRow
                            sx={{
                                "& th": { border: "none" },
                            }}
                        >
                            <StyledTableCell>
                                <Checkbox
                                    color="primary"
                                    indeterminate={selected.length > 0 && selected.length < rows.length}
                                    checked={rows.length > 0 && selected.length === rows.length}
                                    onChange={handleSelectAllClick}
                                />
                            </StyledTableCell>
                            {columns.map((headCell) => (
                                <StyledTableCell
                                    key={headCell.name}
                                    align={headCell.align ?? "left"}
                                    style={{
                                        width: headCell.width,
                                    }}
                                >
                                    {headCell.title}
                                </StyledTableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            const isItemSelected = isSelected(row._id);
                            return (
                                <StyledTableRow key={row._id}>
                                    <StyledTableCell>
                                        <Checkbox
                                            color="primary"
                                            checked={isItemSelected}
                                            onClick={() => handleClickCheck(row._id)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {isEdited(row._id) ? (
                                            <TextField
                                                variant="filled"
                                                hiddenLabel
                                                value={row.description}
                                                name="description"
                                                onChange={(event) => handleChangeDescription(event, row._id)}
                                                size="small"
                                            />
                                        ) : (
                                            row.description
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <Chip
                                            label={row.line_type === 1 ? t("payment.ingress") : t("incomes.expense")}
                                            size="small"
                                            sx={{
                                                backgroundColor: row.line_type === 1 ? "#C2FFFF" : "#FFF1DB",
                                                fontWeight: 600,
                                                px: 2,
                                                width: 110,
                                            }}
                                            onClick={() => handleClickType(row.line_type === 1 ? 2 : 1, row._id)}
                                        />
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {isEdited(row._id) ? (
                                            <TextField
                                                id="input-with-icon-textfield"
                                                variant="filled"
                                                hiddenLabel
                                                value={row.formatted_quantity}
                                                name="formatted_quantity"
                                                onChange={(event) => handleChangeAmount(event, row._id)}
                                                size="small"
                                                type="number"
                                                inputProps={{ min: 1 }}
                                                error={row.new_line && !row.formatted_quantity}
                                            />
                                        ) : (
                                            row.formatted_quantity
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {isEdited(row._id) ? (
                                            <TextField
                                                id="input-with-icon-textfield"
                                                variant="filled"
                                                hiddenLabel
                                                value={row.formatted_credit ?? row.formatted_debit}
                                                name="unit_price"
                                                onChange={(event) => handleChangeUP(event, row._id)}
                                                size="small"
                                                type="number"
                                                sx={{
                                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                                        {
                                                            display: "none",
                                                        },
                                                }}
                                                error={
                                                    row.new_line && !(row.formatted_credit ?? row.formatted_debit)
                                                }
                                            />
                                        ) : (
                                            parseFloat(row.formatted_credit ?? row.formatted_debit).toLocaleString(
                                                todos.amountFormat,
                                                {
                                                    minimumFractionDigits: 2,
                                                }
                                            )
                                        )}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="demo-simple-select-label" sx={{ color: "#131f3e" }}>
                                                {t("accounts.accountNum")}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                name="account"
                                                value={row.account_code ?? ""}
                                                onChange={(event) => handleChangeSelectAcc(event, row._id)}
                                                IconComponent={(props) => checkIconDown(props)}
                                                size="small"
                                                MenuProps={MenuProps}
                                            >
                                                {accountsXero.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <FormControl variant="filled" fullWidth>
                                            <InputLabel id="demo-simple-select-label" sx={{ color: "#131f3e" }}>
                                                {t("accounts.tax")}
                                            </InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                name="tax_type"
                                                value={row.tax_type ?? ""}
                                                onChange={(event) => handleChangeSelectTax(event, row._id)}
                                                IconComponent={(props) => checkIconDown(props)}
                                                size="small"
                                                MenuProps={MenuProps}
                                            >
                                                {xeroTaxRates.map((item) => (
                                                    <MenuItem value={item.value} key={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </StyledTableCell>
                                    <StyledTableCell align="right">
                                        {parseFloat(
                                            row.formatted_quantity * (row.formatted_credit ?? row.formatted_debit)
                                        ).toLocaleString(todos.amountFormat, {
                                            minimumFractionDigits: 2,
                                        })}
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        {!isEdited(row._id) ? (
                                            <Stack direction="row" spacing={1} justifyContent="center">
                                                <LightTooltip title={t("team.edit")}>
                                                    <IconButton
                                                        size="small"
                                                        disabled={editGlobal && !isEdited(row._id)}
                                                        onClick={() => handleEditRow(row._id)}
                                                    >
                                                        <EditIcon
                                                            sx={{ color: edited.length === 0 ? "#4A22D4" : "" }}
                                                        />
                                                    </IconButton>
                                                </LightTooltip>
                                                {rows.length > 1 && (
                                                    <LightTooltip title={t("miscellaneous.delete")}>
                                                        <IconButton
                                                            size="small"
                                                            disabled={editGlobal && !isEdited(row._id)}
                                                            onClick={() => handleDeleteRow(row._id)}
                                                        >
                                                            <CloseIcon />
                                                        </IconButton>
                                                    </LightTooltip>
                                                )}
                                            </Stack>
                                        ) : (
                                            <Stack direction="row" spacing={1} justifyContent="center">
                                                <IconButton size="small" onClick={() => handleEditRow(row._id)}>
                                                    <CheckIcon color="success" />
                                                </IconButton>
                                            </Stack>
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="outlined" sx={{ mt: 1 }} disableElevation endIcon={<AddIcon />} onClick={handleNewRow}>
                {t("incomes.addRow")}
            </Button>
        </Box>
    );
};

export default IcomeTableDet;
